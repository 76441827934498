import React from 'react';
import PropTypes from 'prop-types';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FSCollection } from 'react-rainbow-firebase';
import ScheduleTabset from '../../../components/schedule-tabset';
import DailySchedule from './daily-schedule';
import WeeklySchedule from './weekly-schedule';
import { getFormattedDate } from '../../../helpers';
import {
    showWeekAppointments,
    showNextWeekAppointments,
    showPrevWeekAppointments,
    showThisWeekAppointments,
    showDayAppointments,
    generateReport,
} from '../../../redux/actions/appointments';
import { showConfirmationModal } from '../../../redux/actions/confirmation-modal';
import { showFormModal } from '../../../redux/actions/form-modal';
import { navigate } from '../../../redux/actions/app';
import {
    getMondayDateFromWeek,
    getThisSaturday,
} from './weekly-schedule/helpers';
import { APPOINTMENTS_REF } from '../../../constants';

const query = [
    {
        where: 'enable',
        eq: true,
    },
    { orderBy: 'date' },
];

function Schedule(props) {
    const {
        location,
        showWeekAppointments,
        showNextWeekAppointments,
        showPrevWeekAppointments,
        showThisWeekAppointments,
        selectedWeek,
        selectedDay,
        showDayAppointments,
        generateReport,
        showConfirmationModal,
        showFormModal,
    } = props;

    const getActiveTabName = () => {
        const pathArray = location.pathname.split('/', 4);
        return pathArray[pathArray.length - 1];
    };

    const getDailyCurrentPath = () => {
        const today = new Date();
        const formattedDate = getFormattedDate(today);
        return `/app/schedule/daily/${formattedDate}`;
    };

    const getWeeklyCurrentPath = () => `/app/schedule/weekly/${selectedWeek}`;

    const navigateToDailyPage = () => {
        const url = `/app/schedule/daily/${selectedDay}`;
        const currentPathname = location.pathname;
        navigate(currentPathname, url);
    };

    const navigateToWeeklyPage = () => {
        const url = `/app/schedule/weekly/${selectedWeek}`;
        const currentPathname = location.pathname;
        navigate(currentPathname, url);
    };

    const handleTabSelection = (event, name) => {
        if (name === 'daily') {
            return navigateToDailyPage();
        }
        return navigateToWeeklyPage();
    };

    const renderDailySchedule = () => (
        <DailySchedule
            generateReport={generateReport}
            selectedDay={selectedDay}
            showDayAppointments={showDayAppointments}
            showConfirmationModal={showConfirmationModal}
            showFormModal={showFormModal} />
    );

    const renderWeeklySchedule = () => {
        const thisMonday = getMondayDateFromWeek(selectedWeek);
        const formattedMonday = getFormattedDate(thisMonday);
        const formatedSaturday = getFormattedDate(getThisSaturday(thisMonday));
        return (
            <FSCollection
                showWeekAppointments={showWeekAppointments}
                showNextWeekAppointments={showNextWeekAppointments}
                showPrevWeekAppointments={showPrevWeekAppointments}
                showThisWeekAppointments={showThisWeekAppointments}
                showConfirmationModal={showConfirmationModal}
                showFormModal={showFormModal}
                selectedWeek={selectedWeek}
                component={WeeklySchedule}
                collectionRef={APPOINTMENTS_REF}
                query={query}
                startAt={formattedMonday}
                endAt={formatedSaturday}
            />
        );
    };

    return (
        <section className="scheduler-schedule_wrap-container">
            <ScheduleTabset
                className="rainbow-p-top_x-large"
                onSelectTab={handleTabSelection}
                activeTabName={getActiveTabName()} />

            <Switch>
                <Redirect from="/app/schedule" exact to={getDailyCurrentPath()} />
                <Redirect from="/app/schedule/daily" exact to={getDailyCurrentPath()} />
                <Redirect from="/app/schedule/weekly" exact to={getWeeklyCurrentPath()} />
                <Route
                    path="/app/schedule/daily"
                    render={renderDailySchedule} />

                <Route
                    path="/app/schedule/weekly"
                    render={renderWeeklySchedule} />

                <Route render={renderDailySchedule} />
            </Switch>
        </section>
    );
}

Schedule.propTypes = {
    location: PropTypes.object,
    selectedWeek: PropTypes.string,
    showWeekAppointments: PropTypes.func.isRequired,
    showNextWeekAppointments: PropTypes.func.isRequired,
    showPrevWeekAppointments: PropTypes.func.isRequired,
    showThisWeekAppointments: PropTypes.func.isRequired,
    showDayAppointments: PropTypes.func.isRequired,
    selectedDay: PropTypes.string.isRequired,
    generateReport: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
};

Schedule.defaultProps = {
    location: undefined,
    selectedWeek: undefined,
};

function stateToProps(state) {
    const {
        weeklyAppointments,
        dailyAppointments,
    } = state;
    const { selectedWeek } = weeklyAppointments;
    const { selectedDay } = dailyAppointments;
    return {
        selectedWeek,
        selectedDay,
    };
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        showWeekAppointments,
        showNextWeekAppointments,
        showPrevWeekAppointments,
        showThisWeekAppointments,
        showDayAppointments,
        generateReport,
        showConfirmationModal,
        showFormModal,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(Schedule);
