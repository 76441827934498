import { getFormattedDate } from '../../../helpers';
import history from '../../../history';
import { navigate } from '../app';

export const LOAD_SELECTED_DAY = 'LOAD_SELECTED_DAY';

export default function showDayAppointments(date) {
    const formattedDate = getFormattedDate(date);
    const url = `/app/schedule/daily/${formattedDate}`;
    const { pathname } = history.location;
    return (dispatch) => {
        navigate(pathname, url);
        dispatch({
            type: LOAD_SELECTED_DAY,
            formattedDate,
        });
    };
}
