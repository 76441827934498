/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import { TrashIcon } from '../icons';
import './styles.css';

const messages = defineMessages({
    appointmetsPluralTitle: {
        id: 'confirmation.message.plural.title.appointments',
        defaultValue: 'Are you sure you want to delete the',
    },
    patientsPluralTitle: {
        id: 'confirmation.message.plural.title.patients',
        defaultValue: 'Are you sure you want to delete the',
    },
    appointmentsPluralDescription: {
        id: 'confirmation.message.plural.description.appointments',
        defaultValue: 'will be deleted immediately. You can’t undo this action.',
    },
    patientsPluralDescription: {
        id: 'confirmation.message.plural.description.patients',
        defaultValue: 'will be deleted immediately. You can’t undo this action.',
    },
    selectedAppointments: {
        id: 'confirmation.message.selected.appointments',
        defaultValue: 'selected appointments',
    },
    selectedPatients: {
        id: 'confirmation.message.selected.patients',
        defaultValue: 'selected patients',
    },
    patients: {
        id: 'patients.lowercase',
        defaultValue: 'patients',
    },
    appointments: {
        id: 'appointments.lowercase',
        defaultValue: 'appointments',
    },
});

function ConfirmationMessage(props) {
    const {
        itemsAmount,
        collectionName,
        intl,
        message,
        title,
    } = props;
    const hasSingleAppointment = itemsAmount === 1 && collectionName === 'appointments';
    const hasSinglePatient = itemsAmount === 1 && collectionName === 'patients';
    const hasAppointments = itemsAmount > 1 && collectionName === 'appointments';
    const hasPatients = itemsAmount > 1 && collectionName === 'patients';

    const appointmetsPluralTitle = intl.formatMessage(messages.appointmetsPluralTitle);
    const patientsPluralTitle = intl.formatMessage(messages.patientsPluralTitle);
    const appointmentsPluralDescription = intl.formatMessage(messages.appointmentsPluralDescription);
    const patientsPluralDescription = intl.formatMessage(messages.patientsPluralDescription);
    const selectedAppointments = intl.formatMessage(messages.selectedAppointments);
    const selectedPatients = intl.formatMessage(messages.selectedPatients);
    const patients = intl.formatMessage(messages.patients);
    const appointments = intl.formatMessage(messages.appointments);

    const getTitle = () => {
        if (hasSingleAppointment) {
            return (
                <FormattedMessage
                    id="confirmation.message.singular.title.appointment"
                    defaultMessage="Are you sure you want to delete this appointment?" />
            );
        }
        if (hasSinglePatient) {
            return (
                <FormattedMessage
                    id="confirmation.message.singular.title.patient"
                    defaultMessage="Are you sure you want to delete this patient?" />
            );
        }
        if (hasAppointments) {
            return `${appointmetsPluralTitle} ${itemsAmount} ${selectedAppointments}?`;
        }
        if (hasPatients) {
            return `${patientsPluralTitle} ${itemsAmount} ${selectedPatients}?`;
        }
        return title;
    };

    const getDescription = () => {
        if (hasSingleAppointment) {
            return (
                <FormattedMessage
                    id="confirmation.message.singular.description.appointment"
                    defaultMessage="This appointment will be deleted immediately. You can’t undo this action." />
            );
        }
        if (hasSinglePatient) {
            return (
                <FormattedMessage
                    id="confirmation.message.singular.description.patient"
                    defaultMessage="This patient will be deleted immediately. You can’t undo this action." />
            );
        }
        if (hasAppointments) {
            return `${itemsAmount} ${appointments} ${appointmentsPluralDescription}`;
        }
        if (hasPatients) {
            return `${itemsAmount} ${patients} ${patientsPluralDescription}`;
        }
        return message;
    };

    return (
        <div className="scheduler-modal-confirmation_message-container">
            <TrashIcon className="scheduler-modal-confirmation_message-icon" />
            <div>
                <h1 className="scheduler-modal-confirmation_message-title">
                    {getTitle()}
                </h1>
                <p className="scheduler-modal-confirmation_message-description">
                    {getDescription()}
                </p>
            </div>
        </div>
    );
}

ConfirmationMessage.propTypes = {
    intl: PropTypes.object.isRequired,
    itemsAmount: PropTypes.number.isRequired,
    collectionName: PropTypes.string,
    message: PropTypes.node,
    title: PropTypes.node,
};

ConfirmationMessage.defaultProps = {
    collectionName: undefined,
    message: null,
    title: null,
};

export default injectIntl(ConfirmationMessage);
