
function get(item, fieldName) {
    const value = fieldName.split('.').reduce((seed, prop) => (seed ? seed[prop] : undefined), item);
    if (typeof value === 'string') {
        return value.trim();
    }
    if (!value) {
        return '';
    }
    return value;
}


export default function sort(data, { sortedBy, sortDirection = 'asc' }) {
    if (sortedBy) {
        data.sort((itemA, itemB) => {
            if (sortDirection === 'asc') {
                return get(itemA, sortedBy) > get(itemB, sortedBy) ? 1 : -1;
            }
            return get(itemB, sortedBy) < get(itemA, sortedBy) ? -1 : 1;
        });
        return [...data];
    }
    return data;
}
