import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

export default function NotFound() {
    return (
        <section className="scheduler-not-found_container">
            <div className="scheduler-not-found_text">
                <h1 className="scheduler-not-found_title">It looks like you’re lost…</h1>
                <Link data-cy="go-home-link" className="scheduler-not-found_link" to="/home">Go Home</Link>
            </div>
        </section>
    );
}
