import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    FormattedDate,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    Input,
    Spinner,
    withDebounce,
} from 'react-rainbow-components';
import { navigateTo } from '../../../../history';
import {
    getMondayAppointmets,
    getTuesdayAppointmets,
    getWedesdayAppointmets,
    getThursdayAppointmets,
    getFridayAppointmets,
    getSaturdayAppointmets,
    bindMethods,
    get12HourTime,
} from '../../../../helpers';
import { filter } from './helpers';
import AppointmentCard from '../../../../components/appointment-card';
import AppointmentForm from '../appointment-form';
import PatientName from '../../../../components/patient-name';
import { getCurrentUser } from '../../../../redux/services/firebase';

function navigateToAppoinment(id) {
    navigateTo(`/app/appointment/${id}`);
}

const messages = defineMessages({
    searchPlaceholder: {
        id: 'find.appointment.placeholder',
        defaultValue: 'Find appointment',
    },
    formModalTitle: {
        id: 'appointments.edit.appointment',
        defaultValue: 'Edit Appointment',
    },
});

const DebouncedInput = withDebounce(Input);

function AppointmentCards(props) {
    const {
        dailyAppointments,
        searchTerm,
        onDelete,
        onEdit,
    } = props;

    const dailyAppointmentsFiltered = filter(searchTerm, dailyAppointments);

    return dailyAppointmentsFiltered.map(({ id, data }, index) => {
        const {
            time,
            status,
            address,
            patient,
        } = data;
        const key = `appointment-${index}`;
        const formattedTime = get12HourTime(time);

        return (
            <div
                key={key}
                className="scheduler-weekly-schedule_appointment-card-button"
            >
                <AppointmentCard
                    patientName={<PatientName value={patient} />}
                    time={formattedTime}
                    status={status}
                    address={address}
                    onDelete={() => onDelete(id)}
                    onEdit={() => onEdit(data, id)}
                    onViewDetails={() => navigateToAppoinment(id)}
                />
            </div>
        );
    });
}

AppointmentCards.propTypes = {
    dailyAppointments: PropTypes.array,
};

AppointmentCards.defaultProps = {
    dailyAppointments: [],
};

class WeeklyContent extends Component {
    constructor(props) {
        super(props);
        bindMethods([
            'openConfirmationModal',
            'openEditFormModal',
            'mondayHandleOnChange',
            'tuesdayHandleOnChange',
            'wednesdayHandleOnChange',
            'thursdayHandleOnChange',
            'fridayHandleOnChange',
            'saturdayHandleOnChange',
        ], this);
        this.state = {
            mondaySearchTerm: '',
            tuesdaySearchTerm: '',
            wednesdaySearchTerm: '',
            thursdaySearchTerm: '',
            fridaySearchTerm: '',
            saturdaySearchTerm: '',
        };
    }

    mondayHandleOnChange(event) {
        this.setState({
            mondaySearchTerm: event.target.value,
        });
    }

    tuesdayHandleOnChange(event) {
        this.setState({
            tuesdaySearchTerm: event.target.value,
        });
    }

    wednesdayHandleOnChange(event) {
        this.setState({
            wednesdaySearchTerm: event.target.value,
        });
    }

    thursdayHandleOnChange(event) {
        this.setState({
            thursdaySearchTerm: event.target.value,
        });
    }

    fridayHandleOnChange(event) {
        this.setState({
            fridaySearchTerm: event.target.value,
        });
    }

    saturdayHandleOnChange(event) {
        this.setState({
            saturdaySearchTerm: event.target.value,
        });
    }

    openConfirmationModal(id) {
        const { showConfirmationModal, updateDoc } = this.props;
        showConfirmationModal({
            collectionName: 'appointments',
            itemsAmount: 1,
            onAccept: () => updateDoc(id, {
                userId: getCurrentUser().uid,
                enable: false,
            }),
        });
    }

    openEditFormModal(initialValues, appointmentId) {
        const { showFormModal, updateDoc, intl } = this.props;
        showFormModal({
            title: intl.formatMessage(messages.formModalTitle),
            formComponent: AppointmentForm,
            formId: 'appointment-form',
            onSubmit: editedData => updateDoc(appointmentId, editedData),
            initialValues,
        });
    }

    render() {
        const {
            mondaySearchTerm,
            tuesdaySearchTerm,
            wednesdaySearchTerm,
            thursdaySearchTerm,
            fridaySearchTerm,
            saturdaySearchTerm,
        } = this.state;
        const {
            data: appointments,
            isLoading,
            weekDays,
            intl,
        } = this.props;

        if (isLoading) {
            return (
                <div className="scheduler-daily-schedule_spinner-container">
                    <Spinner size="large" />
                </div>
            );
        }
        return (
            <section className="scheduler-weekly-schedule_container">
                <section className="scheduler-weekly-schedule_content">
                    <article className="scheduler-weekly-schedule_day-column">
                        <h2 className="scheduler-weekly-schedule_column-title">
                            <FormattedDate
                                value={weekDays.monday}
                                weekday="long"
                            />
                            {' • '}
                            <FormattedDate
                                value={weekDays.monday}
                                year="numeric"
                                month="2-digit"
                                day="2-digit" />
                        </h2>

                        <DebouncedInput
                            onChange={this.mondayHandleOnChange}
                            label="Find appointment"
                            hideLabel
                            className="scheduler-weekly-schedule_search"
                            type="search"
                            placeholder={intl.formatMessage(messages.searchPlaceholder)} />

                        <div className="scheduler-weekly-schedule_appointment-card-container">
                            <AppointmentCards
                                onEdit={this.openEditFormModal}
                                onDelete={this.openConfirmationModal}
                                searchTerm={mondaySearchTerm}
                                dailyAppointments={getMondayAppointmets(appointments)} />
                        </div>
                    </article>

                    <article className="scheduler-weekly-schedule_day-column">
                        <h2 className="scheduler-weekly-schedule_column-title">
                            <FormattedDate
                                value={weekDays.tuesday}
                                weekday="long"
                            />
                            {' • '}
                            <FormattedDate
                                value={weekDays.tuesday}
                                year="numeric"
                                month="2-digit"
                                day="2-digit" />
                        </h2>

                        <DebouncedInput
                            onChange={this.tuesdayHandleOnChange}
                            label="Find appointment"
                            hideLabel
                            className="scheduler-weekly-schedule_search"
                            type="search"
                            placeholder={intl.formatMessage(messages.searchPlaceholder)} />

                        <div className="scheduler-weekly-schedule_appointment-card-container">
                            <AppointmentCards
                                onEdit={this.openEditFormModal}
                                onDelete={this.openConfirmationModal}
                                searchTerm={tuesdaySearchTerm}
                                dailyAppointments={getTuesdayAppointmets(appointments)} />
                        </div>
                    </article>

                    <article className="scheduler-weekly-schedule_day-column">
                        <h2 className="scheduler-weekly-schedule_column-title">
                            <FormattedDate
                                value={weekDays.wednesday}
                                weekday="long"
                            />
                            {' • '}
                            <FormattedDate
                                value={weekDays.wednesday}
                                year="numeric"
                                month="2-digit"
                                day="2-digit" />
                        </h2>

                        <DebouncedInput
                            onChange={this.wednesdayHandleOnChange}
                            label="Find appointment"
                            hideLabel
                            className="scheduler-weekly-schedule_search"
                            type="search"
                            placeholder={intl.formatMessage(messages.searchPlaceholder)} />

                        <div className="scheduler-weekly-schedule_appointment-card-container">
                            <AppointmentCards
                                onEdit={this.openEditFormModal}
                                onDelete={this.openConfirmationModal}
                                searchTerm={wednesdaySearchTerm}
                                dailyAppointments={getWedesdayAppointmets(appointments)} />
                        </div>
                    </article>

                    <article className="scheduler-weekly-schedule_day-column">
                        <h2 className="scheduler-weekly-schedule_column-title">
                            <FormattedDate
                                value={weekDays.thursday}
                                weekday="long"
                            />
                            {' • '}
                            <FormattedDate
                                value={weekDays.thursday}
                                year="numeric"
                                month="2-digit"
                                day="2-digit" />
                        </h2>

                        <DebouncedInput
                            onChange={this.thursdayHandleOnChange}
                            label="Find appointment"
                            hideLabel
                            className="scheduler-weekly-schedule_search"
                            type="search"
                            placeholder={intl.formatMessage(messages.searchPlaceholder)} />

                        <div className="scheduler-weekly-schedule_appointment-card-container">
                            <AppointmentCards
                                onEdit={this.openEditFormModal}
                                onDelete={this.openConfirmationModal}
                                searchTerm={thursdaySearchTerm}
                                dailyAppointments={getThursdayAppointmets(appointments)} />
                        </div>
                    </article>

                    <article className="scheduler-weekly-schedule_day-column">
                        <h2 className="scheduler-weekly-schedule_column-title">
                            <FormattedDate
                                value={weekDays.friday}
                                weekday="long"
                            />
                            {' • '}
                            <FormattedDate
                                value={weekDays.friday}
                                year="numeric"
                                month="2-digit"
                                day="2-digit" />
                        </h2>

                        <DebouncedInput
                            onChange={this.fridayHandleOnChange}
                            label="Find appointment"
                            hideLabel
                            className="scheduler-weekly-schedule_search"
                            type="search"
                            placeholder={intl.formatMessage(messages.searchPlaceholder)} />

                        <div className="scheduler-weekly-schedule_appointment-card-container">
                            <AppointmentCards
                                onEdit={this.openEditFormModal}
                                onDelete={this.openConfirmationModal}
                                searchTerm={fridaySearchTerm}
                                dailyAppointments={getFridayAppointmets(appointments)} />
                        </div>
                    </article>

                    <article className="scheduler-weekly-schedule_day-column">
                        <h2 className="scheduler-weekly-schedule_column-title">
                            <FormattedDate
                                value={weekDays.saturday}
                                weekday="long"
                            />
                            {' • '}
                            <FormattedDate
                                value={weekDays.saturday}
                                year="numeric"
                                month="2-digit"
                                day="2-digit" />
                        </h2>

                        <DebouncedInput
                            onChange={this.saturdayHandleOnChange}
                            label="Find appointment"
                            hideLabel
                            className="scheduler-weekly-schedule_search"
                            type="search"
                            placeholder={intl.formatMessage(messages.searchPlaceholder)} />

                        <div className="scheduler-weekly-schedule_appointment-card-container">
                            <AppointmentCards
                                onEdit={this.openEditFormModal}
                                onDelete={this.openConfirmationModal}
                                searchTerm={saturdaySearchTerm}
                                dailyAppointments={getSaturdayAppointmets(appointments)} />
                        </div>
                    </article>
                </section>
            </section>
        );
    }
}

WeeklyContent.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    updateDoc: PropTypes.func.isRequired,
    weekDays: PropTypes.object.isRequired,
    showFormModal: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default (injectIntl(WeeklyContent));
