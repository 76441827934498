import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { Application } from 'react-rainbow-components';
import { startApplication } from './redux/actions/app';
import InitializingApp from './components/initializing-app';
import Routes from './routes';
import Message from './components/message';

class App extends Component {
    componentDidMount() {
        const { startApplication, isInitializing } = this.props;
        if (isInitializing) {
            startApplication();
        }
    }

    render() {
        const {
            isInitializing, isAppCrashed, isAuthenticated, intl,
        } = this.props;

        if (isInitializing) {
            return (
                <Application>
                    <InitializingApp />
                </Application>
            );
        }

        if (isAppCrashed) {
            return <span>Something was wrong, please refresh the browser</span>;
        }

        return (
            <Application locale={intl.locale}>
                <Message />
                <Routes isAuth={isAuthenticated} />
            </Application>
        );
    }
}

App.propTypes = {
    isInitializing: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    startApplication: PropTypes.func.isRequired,
    isAppCrashed: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};

function stateToProps(state) {
    const { app, authentication } = state;
    return {
        isAuthenticated: !!authentication.get('user'),
        isInitializing: app.get('isInitializing'),
        isAppCrashed: app.get('isAppCrashed'),
    };
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        startApplication,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(injectIntl(App));
