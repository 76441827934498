/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withCurrentUserClaims } from 'react-rainbow-firebase';
import {
    Button,
    Modal,
} from 'react-rainbow-components';
import { logoutUser } from '../../../redux/actions/authentication';
import { getCurrentUser } from '../../../redux/services/firebase';
import {
    showProfileModal,
    hideProfileModal,
    addMember,
    listAllMemebers,
    removeMember,
    updateMember,
} from '../../../redux/actions/profile';
import { showConfirmationModal } from '../../../redux/actions/confirmation-modal';
import { bindMethods, isAdmin } from '../../../helpers';
import { AddIcon } from '../../../components/icons';
import { navigateTo } from '../../../history';
import MemberForm from './member-form';
import AssignPermissionsForm from './assing-permissions-form';
import MembersCards from './members-cards';
import './styles.css';

class Permissions extends Component {
    constructor(props) {
        super(props);
        bindMethods([
            'openAddMemberModal',
            'openConfirmationModal',
            'assignPermissions',
            'disableMember',
            'enableMember',
        ], this);
    }

    async componentDidMount() {
        const currentUser = getCurrentUser();
        if (currentUser) {
            const idTokenResult = await currentUser.getIdTokenResult(true);
            if (!isAdmin(idTokenResult.claims)) {
                navigateTo('/');
            } else {
                const { listAllMemebers } = this.props;
                listAllMemebers();
            }
        } else {
            navigateTo('/');
        }
    }

    openAddMemberModal() {
        const { showProfileModal, addMember } = this.props;
        showProfileModal({
            modalProps: {
                title: 'Add member',
                size: 'small',
                className: 'scheduler-permissions_members-modal',
                children: MemberForm,
            },
            formProps: {
                onSubmit: addMember,
            },
        });
    }

    openConfirmationModal(uid) {
        const { showConfirmationModal, removeMember } = this.props;
        showConfirmationModal({
            itemsAmount: 1,
            message: 'Are you sure you want to delete this member',
            closeWhenAccept: false,
            onAccept: () => removeMember(uid),
        });
    }

    disableMember(uid) {
        const { showConfirmationModal, updateMember } = this.props;
        showConfirmationModal({
            itemsAmount: 1,
            message: 'Are you sure you want to disable this member',
            closeWhenAccept: true,
            onAccept: () => updateMember({
                uid,
                properties: {
                    disabled: true,
                },
            }),
        });
    }

    enableMember(uid) {
        const { showConfirmationModal, updateMember } = this.props;
        showConfirmationModal({
            itemsAmount: 1,
            message: 'Are you sure you want to enable this member',
            closeWhenAccept: true,
            onAccept: () => updateMember({
                uid,
                properties: {
                    disabled: false,
                },
            }),
        });
    }

    assignPermissions({ uid, customClaims: { roles } }) {
        const { showProfileModal, updateMember } = this.props;
        showProfileModal({
            modalProps: {
                title: 'Assign Permissions',
                size: 'small',
                className: 'scheduler-permissions_members-modal',
                children: AssignPermissionsForm,
            },
            formProps: {
                onSubmit: ({ roles }) => updateMember({
                    uid,
                    properties: {
                        roles,
                    },
                }),
                initialValues: {
                    roles,
                },
            },
        });
    }

    render() {
        const {
            formProps,
            modalProps,
            members,
        } = this.props;
        const { children: ModalChildren, ...restModalProps } = modalProps;

        return (
            <section>
                <div className="scheduler-permissions_container">
                    <header data-cy="header-page" className="scheduler-permissions_page-header">
                        <h1 className="scheduler-permissions_header-title"><FormattedMessage id="users.and.permissions" defaultMessage="Members and Permissions" /></h1>
                        <Button
                            variant="brand"
                            onClick={this.openAddMemberModal}>
                            <AddIcon className="scheduler-permissions_plus-icon" />
                            Add Member
                        </Button>
                    </header>
                    <div className="scheduler-permissions_content">
                        <article className="scheduler-permissions_members-container">
                            <MembersCards
                                members={members}
                                onRemove={this.openConfirmationModal}
                                onAssignPermissions={this.assignPermissions}
                                onDisable={this.disableMember}
                                onEnable={this.enableMember}
                            />
                        </article>
                    </div>
                </div>
                <Modal {...restModalProps}>
                    <ModalChildren {...formProps} />
                </Modal>
            </section>
        );
    }
}

Permissions.propTypes = {
    logoutUser: PropTypes.func,
    user: PropTypes.object,
    intl: PropTypes.object.isRequired,
    claims: PropTypes.object.isRequired,
    modalProps: PropTypes.object,
    formProps: PropTypes.object,
    members: PropTypes.array.isRequired,
    showProfileModal: PropTypes.func.isRequired,
    hideProfileModal: PropTypes.func.isRequired,
    addMember: PropTypes.func.isRequired,
    updateMember: PropTypes.func.isRequired,
    listAllMemebers: PropTypes.func.isRequired,
    removeMember: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
};

Permissions.defaultProps = {
    logoutUser: () => {},
    user: {},
    modalProps: {},
    formProps: {},
};


function stateToProps(state) {
    const { authentication, profile } = state;
    const { user } = authentication.toJS();
    return {
        user,
        ...profile,
    };
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        logoutUser,
        showProfileModal,
        hideProfileModal,
        addMember,
        updateMember,
        listAllMemebers,
        removeMember,
        showConfirmationModal,
    }, dispatch);
}

const ProfileWithClaims = withCurrentUserClaims(Permissions);

export default connect(stateToProps, dispatchToProps)((injectIntl(ProfileWithClaims)));
