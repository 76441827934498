import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SignIn from './sign-in';
import ForgotPassword from './forgot-password';
import ResetPasswordConfirmation from './reset-password-confirmation';
import TermsAndConditions from './terms-condition';
import PrivacyPolicy from './privacy-policy';
import NotFound from './not-found';
import './styles.css';

function getYear() {
    return new Date().getFullYear();
}

export default function PublicRoutes() {
    return (
        <div className="scheduler-public_container">
            <Switch>
                <Redirect from="/home" exact to="/home/signin" />
                <Route path="/home/signin" component={SignIn} />
                <Route path="/home/forgot-password" component={ForgotPassword} />
                <Route path="/home/reset-password-confirmation" component={ResetPasswordConfirmation} />
                <Route path="/home/terms" component={TermsAndConditions} />
                <Route path="/home/privacy" component={PrivacyPolicy} />
                <Redirect to="/home/signin" />
                <Route component={NotFound} />
            </Switch>
            <p className="scheduler-public_footer">
                {`© ${getYear()} nexxtway. All rights reserved.`}
            </p>
        </div>
    );
}
