import {
    getFormattedPhoneNumber,
    getFullPatientName,
    filterCollection,
} from '.';

function getRegularValue(value) {
    return value ? 'Regular' : 'Not regular';
}

function mapValuesToString(data) {
    const {
        firstName,
        lastName,
        phone,
        address,
        memberId,
        driver,
        regularPatient,
    } = data;
    const name = getFullPatientName(firstName, lastName);
    const phoneNumber = getFormattedPhoneNumber(phone);

    return `${name},${phoneNumber},${memberId},${address},${driver},${getRegularValue(regularPatient)}`;
}

export default function filter(query, collection) {
    return filterCollection(query, collection, mapValuesToString);
}
