import history from '../../../history';
import handleAuthenticationChange from './handle-auth-change';
import { onAuthStateChanged } from '../../services/firebase';
import hideMessage from './hide-message';
import GA from '../../../ga';

export const APP_CRASHED = 'APP_CRASHED';

export default function startApplication() {
    return (dispatch) => {
        window.addEventListener('error', (e) => {
            GA.exception({
                description: e.message,
                fatal: true,
            });
        });
        onAuthStateChanged((user) => {
            dispatch(handleAuthenticationChange(user));
        });
        history.listen(() => {
            dispatch(hideMessage());
        });
    };
}
