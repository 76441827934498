import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MultiSelect, Option } from 'react-rainbow-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { listAllMemebers } from '../../../redux/actions/profile';

const Members = ({ data }) => data.map((item) => {
    const { email } = item;
    return (
        <Option
            key={email}
            name={email}
            label={email}
        />
    );
});

const UserPicker = (props) => {
    const {
        className, onChange, value, listAllMemebers, members,
    } = props;

    useEffect(() => {
        listAllMemebers();
    }, []);

    return (
        <MultiSelect
            className={className}
            placeholder="Filter by creator"
            onChange={onChange}
            value={value}
            showCheckbox
            enableSearch
        >
            <Members data={members} />
        </MultiSelect>
    );
};

UserPicker.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.object,
    listAllMemebers: PropTypes.func.isRequired,
    members: PropTypes.array.isRequired,
};

UserPicker.defaultProps = {
    className: undefined,
    onChange: () => {},
    value: undefined,
};

function stateToProps(state) {
    const { profile } = state;
    return profile;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        listAllMemebers,
    }, dispatch);
}


export default connect(stateToProps, dispatchToProps)(UserPicker);
