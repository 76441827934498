/* eslint-disable max-len */
import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    Button,
    Input,
    Card,
    Select,
} from 'react-rainbow-components';
import { changeLocale } from '../../../i18n';
import { loginWithEmailAndPassword } from '../../../redux/actions/authentication';
import {
    EmailIcon,
    LockIcon,
} from '../../../components/icons';
import TermsConditionsAndPrivacyPolicy from '../../../components/terms-conditions-and-privacy-policy/idnex';
import validate from './validate';
import './styles.css';
import './media-queries.css';
import { CLIENT_LOCATION_NAME } from '../../../constants';

const translations = defineMessages({
    emailPlaceholder: {
        id: 'form.email.placeholder',
        defaultValue: 'Enter your email address',
    },
    passwordPlaceholder: {
        id: 'form.password.placeholder',
        defaultValue: 'Enter your password',
    },
});

const languages = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
];

class SignIn extends Component {
    getClassName() {
        const { className } = this.props;
        return classnames('scheduler-signin_container', className);
    }

    render() {
        const {
            handleSubmit,
            loginWithEmailAndPassword,
            isLoading,
            intl,
            changeLocale,
            locale,
        } = this.props;

        return (
            <form noValidate onSubmit={handleSubmit(user => loginWithEmailAndPassword(user))}>
                <section className={this.getClassName()}>
                    <Link className="scheduler-signin_logo" to="/home">
                        <img src="/assets/logo.svg" alt="scheduler logo" className="scheduler-signin_image" />
                    </Link>
                    <p className="scheduler-signin_header">
                        <FormattedMessage
                            id="form.sign.in"
                            defaultMessage="Sign in" />
                    </p>
                    <h1 className="scheduler-signin_location-name">
                        {CLIENT_LOCATION_NAME}
                    </h1>
                    <Card className="scheduler-signin_card">
                        <article className="scheduler-signin_inputs-container">
                            <Field
                                component={Input}
                                name="email"
                                label={<FormattedMessage id="form.email.label" defaultMessage="Email address" />}
                                required
                                placeholder={intl.formatMessage(translations.emailPlaceholder)}
                                type="email"
                                icon={<EmailIcon />} />
                            <Field
                                component={Input}
                                name="password"
                                label={<FormattedMessage id="form.password" defaultMessage="Password" />}
                                placeholder={intl.formatMessage(translations.passwordPlaceholder)}
                                type="password"
                                required
                                icon={<LockIcon />} />
                            <Button
                                variant="brand"
                                type="submit"
                                label={<FormattedMessage id="login.to.login" defaultMessage="Login" />}
                                isLoading={isLoading}
                            />
                            <Link data-cy="forgot-password-link" to="/home/forgot-password" className="scheduler-signin_link">
                                <FormattedMessage
                                    id="form.sing.in.forgot.password"
                                    defaultMessage="Forgot your password?" />
                            </Link>
                        </article>
                    </Card>
                    <TermsConditionsAndPrivacyPolicy />
                    <Select
                        label="select language"
                        hideLabel
                        className="scheduler-signin_language-selector"
                        options={languages}
                        value={locale}
                        onChange={event => changeLocale(event.target.value)} />
                </section>
            </form>
        );
    }
}

SignIn.propTypes = {
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    className: PropTypes.string,
    intl: PropTypes.object.isRequired,
    changeLocale: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
};

SignIn.defaultProps = {
    className: undefined,
};

function stateToProps(state) {
    const { i18n } = state;
    return {
        ...state.authentication.toJS(),
        locale: i18n.locale,
    };
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        loginWithEmailAndPassword,
        changeLocale,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(
    reduxForm({
        form: 'signin',
        touchOnBlur: false,
        validate,
    })(injectIntl(SignIn)),
);
