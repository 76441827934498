import { signOutUser } from '../../services/firebase';

export const USER_LOGOUT_DONE = 'USER_LOGOUT_DONE';

export default function logoutUser() {
    return async (dispatch) => {
        await signOutUser();
        dispatch({ type: USER_LOGOUT_DONE });
    };
}
