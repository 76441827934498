import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    Input,
    Select,
    DatePicker,
    Textarea,
    RenderIf,
} from 'react-rainbow-components';
import { Warning } from '@rainbow-modules/icons';
import { Field, MultiPhoneNumberInput, UniversalForm } from '@rainbow-modules/forms';
import { LocationMarkerIcon } from '../../../../components/icons';
import {
    formatPhoneNumbers, getFormattedDate, shouldShowLegacyPhone,
} from '../../../../helpers';
import { getCurrentUser } from '../../../../redux/services/firebase';
import validate from './validate';
import './styles.css';
import onAddPhoneNumber from '../../../../helpers/onAddPhoneNumber';

const today = new Date();
const thisYear = new Date().getFullYear();
const oneHundredFiftyYearsAgo = new Date(thisYear - 150, 0, 1);

const messages = defineMessages({
    firstNamePlaceholder: {
        id: 'patients.first.name.placeholder',
        defaultValue: 'Enter the First Name',
    },
    lastNamePlaceholder: {
        id: 'patients.last.name.placeholder',
        defaultValue: 'Enter the Last Name',
    },
    phoneNumberPlaceholder: {
        id: 'patients.phone.number.placeholder',
        defaultValue: 'Enter the Phone Number',
    },
    birthdatePlaceholder: {
        id: 'patients.birthdate.placeholder',
        defaultValue: 'Select the Birthdate',
    },
    memberIdPlaceholder: {
        id: 'patients.member.number.placeholder',
        defaultValue: 'Enter member #',
    },
    addressPlaceholder: {
        id: 'address.placeholder',
        defaultValue: 'Enter Address',
    },
});

const insuranceOptions = [
    { value: '', label: 'Medical Insurance', disabled: true },
    { value: 'Simply', label: 'Simply' },
    { value: 'CarePlus', label: 'CarePlus' },
    { value: 'CARE PLUS', label: 'CARE PLUS' },
    { value: 'Coventry', label: 'Coventry' },
    { value: 'Medica', label: 'Medica' },
    { value: 'Medicaid', label: 'Medicaid' },
    { value: 'HealthSun', label: 'HealthSun' },
    { value: 'Preferred', label: 'Preferred' },
    { value: 'Aetna', label: 'Aetna' },
    { value: 'Doctors', label: 'Doctors' },
    { value: 'other', label: 'Other' },
];

function ProspectForm(props) {
    const {
        className,
        initialValues,
        onSubmit,
        intl,
    } = props;
    const { phone, phoneNumbers } = initialValues;

    const submit = (values) => {
        const {
            dob,
            phoneNumbers,
            ...rest
        } = values;
        const { uid, displayName, email } = getCurrentUser();

        const data = {
            ...rest,
            userId: uid,
            phoneNumbers: formatPhoneNumbers(phoneNumbers),
            enable: true,
            createdAt: new Date(),
            createdBy: {
                displayName,
                email,
            },
        };

        if (dob) {
            data.dob = getFormattedDate(dob);
        }

        onSubmit(data);
    };

    const getContainerClassNames = () => classnames('scheduler-prospect_patient-form-container', className);
    const showLegacyPhone = shouldShowLegacyPhone(phone, formatPhoneNumbers(phoneNumbers));

    return (
        <UniversalForm
            className={getContainerClassNames()}
            id="prospect-form"
            validate={validate}
            noValidate
            initialValues={initialValues}
            onSubmit={submit}
        >
            <div className="rainbow-position-align_start rainbow-justify_spread rainbow-m-bottom_large">
                <Field
                    className="scheduler-prospect_patient-form-input"
                    required
                    label={<FormattedMessage id="patients.first.name" defaultMessage="First Name" />}
                    placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
                    name="firstName"
                    component={Input}
                />
                <Field
                    className="scheduler-prospect_patient-form-input"
                    required
                    label={<FormattedMessage id="patients.last.name" defaultMessage="Last Name" />}
                    name="lastName"
                    component={Input}
                    placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
                />
            </div>
            <RenderIf isTrue={showLegacyPhone}>
                <Field
                    className="rainbow-m-bottom_large"
                    labelAlignment="left"
                    label={(
                        <div className="scheduler-patients_patient-form-invalid-phone-label">
                            <Warning className="scheduler-patients_patient-form-warning-icon" />
                            <FormattedMessage id="patients.phone.number.invalid" defaultMessage="Invalid Phone Number" />
                        </div>
                    )}
                    bottomHelpText={(
                        <div className="scheduler-patients_patient-form-invalid-phone-help-text">
                            <FormattedMessage id="patients.phone.number.invalid.message" defaultMessage="We have detected that this number is invalid. Please enter the phone number correctly in the fields below." />
                        </div>
                    )}
                    name="phone"
                    type="tel"
                    component={Input}
                    placeholder={intl.formatMessage(messages.phoneNumberPlaceholder)}
                    readonly
                    disabled
                />
            </RenderIf>

            <Field
                className="rainbow-m-bottom_x-large"
                name="phoneNumbers"
                label="Phone numbers"
                component={MultiPhoneNumberInput}
                onAddPhoneNumber={onAddPhoneNumber} />
            <Field
                label={<FormattedMessage id="patients.dob" defaultMessage="Date of Birth" />}
                name="dob"
                component={DatePicker}
                minDate={oneHundredFiftyYearsAgo}
                maxDate={today}
                placeholder={intl.formatMessage(messages.birthdatePlaceholder)}
                className="rainbow-m-bottom_large"
            />
            <Field
                label="Medicare"
                placeholder={intl.formatMessage(messages.memberIdPlaceholder)}
                name="medicare"
                component={Input}
                className="rainbow-m-bottom_large"
            />
            <Field
                label="Medicaid"
                placeholder={intl.formatMessage(messages.memberIdPlaceholder)}
                name="medicaid"
                component={Input}
                className="rainbow-m-bottom_large"
            />
            <Field
                label={<FormattedMessage id="address" defaultMessage="Address" />}
                name="address"
                component={Input}
                placeholder={intl.formatMessage(messages.addressPlaceholder)}
                icon={<LocationMarkerIcon />}
                className="rainbow-m-bottom_large"
            />
            <Field
                label={<FormattedMessage id="medical.insurance" defaultMessage="Medical Insurance" />}
                name="medicalInsurance"
                component={Select}
                options={insuranceOptions}
                className="rainbow-m-bottom_large"
            />

            <Field label="Notes" placeholder="Enter note" name="notes" component={Textarea} className="rainbow-m-bottom_xx-large" />
        </UniversalForm>
    );
}

ProspectForm.propTypes = {
    className: PropTypes.string,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

ProspectForm.defaultProps = {
    className: undefined,
    initialValues: {},
    onSubmit: () => {},
};

export default injectIntl(ProspectForm);
