import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, CheckboxGroup } from 'react-rainbow-components';
import validate from './validate';
import { ROLE_OPTIONS } from '../../../../constants';
import '../member-form/styles.css';

function AssignPermissionsForm(props) {
    const {
        handleSubmit,
        onSubmit,
        isLoading,
    } = props;

    return (
        <form
            className="scheduler-profile-member-form_container"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <Field
                className="rainbow-m-bottom_x-large"
                component={CheckboxGroup}
                name="roles"
                label="Select the permissions you want to give the user"
                options={ROLE_OPTIONS}
            />
            <Button
                label="Save"
                variant="brand"
                type="submit"
                isLoading={isLoading}
            />
        </form>
    );
}

AssignPermissionsForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
};

AssignPermissionsForm.defaultProps = {
    handleSubmit: () => {},
    isLoading: false,
};

export default reduxForm({
    form: 'assign-permissions-form',
    touchOnBlur: false,
    validate,
})(AssignPermissionsForm);
