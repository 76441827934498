import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';

export default function DateTime(props) {
    const { value, className } = props;
    return (
        <span className={className}>
            <span className="rainbow-m-right_x-small">
                <FormattedDate
                    value={value}
                    timeZone="America/New_York" />
            </span>
            <FormattedTime
                value={value}
                timeZone="America/New_York" />
        </span>
    );
}

DateTime.propTypes = {
    className: PropTypes.string,
    value: PropTypes.instanceOf(Date).isRequired,
};

DateTime.defaultProps = {
    className: '',
};
