import React from 'react';
import PropTypes from 'prop-types';

export default function UserCell(props) {
    const { value: { email } } = props;

    return (
        <span title={email}>
            {email}
        </span>
    );
}

UserCell.propTypes = {
    value: PropTypes.shape({
        displayName: PropTypes.string,
        email: PropTypes.string,
    }),
};

UserCell.defaultProps = {
    value: {},
};
