import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-rainbow-components';
import './styles.css';

export default function Footer({ onCancel, formId }) {
    return (
        <div className="rainbow-flex rainbow-justify_end scheduler-global-modal_footer-container">
            <Button
                className="rainbow-m-right_medium"
                label={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
                onClick={onCancel}
            />
            <Button
                label={<FormattedMessage id="save" defaultMessage="Save" />}
                variant="brand"
                type="submit"
                form={formId}
            />
        </div>
    );
}

Footer.propTypes = {
    onCancel: PropTypes.func.isRequired,
    formId: PropTypes.string.isRequired,
};
