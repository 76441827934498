export const HIDE_CONFIRMATION_MODAL = 'HIDE_CONFIRMATION_MODAL';
export function hideConfirmationModal() {
    return {
        type: HIDE_CONFIRMATION_MODAL,
    };
}

export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
export function showConfirmationModal(props) {
    return {
        type: SHOW_CONFIRMATION_MODAL,
        props,
    };
}
