import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FSDoc } from 'react-rainbow-firebase';
import { Button } from 'react-rainbow-components';
import { AddIcon } from '../../../../../components/icons';

function AddButton({ doc, onClick }) {
    if (doc && doc.data && doc.data.enable) {
        return (
            <Button onClick={() => onClick(doc)}>
                <AddIcon className="rainbow-m-right_x-small" />
                <FormattedMessage id="add" defaultMessage="Add" />
            </Button>
        );
    }
    return null;
}

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    doc: PropTypes.object,
};

AddButton.defaultProps = {
    doc: null,
};

export default function AddAppointmentButton({ patientId, onClick }) {
    return (
        <FSDoc
            component={AddButton}
            docRef={`patients/${patientId}`}
            onClick={onClick}
        />
    );
}

AddAppointmentButton.propTypes = {
    patientId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
