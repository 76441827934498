import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    FormattedMessage,
    FormattedDate,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    ButtonIcon,
    Button,
} from 'react-rainbow-components';
import WeeklyContent from './weekly-content';
import AppointmentForm from '../appointment-form';
import {
    LeftArrowIcon,
    RightArrowIcon,
} from '../../../../components/icons';
import {
    getWeekDays,
    getMondayDateFromWeek,
    getThisSaturday,
} from './helpers';
import history from '../../../../history';
import { getWeekUrl, getNormalizedWeek } from '../../../../helpers';
import './styles.css';
import './media-queries.css';

const messages = defineMessages({
    formModalTitle: {
        id: 'appointments.new.appointment',
        defaultValue: 'New Appointment',
    },
});

class WeeklySchedule extends Component {
    constructor(props) {
        super(props);
        this.openNewAppointmentModal = this.openNewAppointmentModal.bind(this);
    }

    componentDidMount() {
        const { showWeekAppointments } = this.props;
        const date = history.location.pathname.split('weekly/')[1];
        const normalizedWeek = getNormalizedWeek(date);
        showWeekAppointments(normalizedWeek);
    }

    openNewAppointmentModal() {
        const { showFormModal, intl, addDoc } = this.props;
        showFormModal({
            title: intl.formatMessage(messages.formModalTitle),
            formComponent: AppointmentForm,
            formId: 'appointment-form',
            onSubmit: addDoc,
            initialValues: {
                date: new Date(),
                status: 'not confirmed',
            },
        });
    }

    render() {
        const {
            showNextWeekAppointments,
            showPrevWeekAppointments,
            showThisWeekAppointments,
            updateDoc,
            data,
            isLoading,
            selectedWeek,
            showFormModal,
            showConfirmationModal,
        } = this.props;

        const thisMonday = getMondayDateFromWeek(selectedWeek);
        const thisSaturday = getThisSaturday(thisMonday);
        const thisWeekUrl = getWeekUrl(new Date());
        const isThisWeek = selectedWeek === thisWeekUrl;
        const weekDays = getWeekDays(thisMonday);

        return (
            <section className="scheduler-weekly-schedule_wrap-container">
                <header className="scheduler-weekly-schedule_header">
                    <div className="scheduler-weekly-schedule_controlers">
                        <ButtonIcon
                            onClick={showPrevWeekAppointments}
                            variant="border-filled"
                            size="small"
                            icon={<LeftArrowIcon />} />
                        <h1 className="scheduler-weekly-schedule_header-title">
                            <FormattedMessage id="appointments.weekly.week" defaultMessage="Week" />
                            {' '}
                            <FormattedDate
                                value={thisMonday}
                                year="numeric"
                                month="2-digit"
                                day="2-digit" />
                            {' - '}
                            <FormattedDate
                                value={thisSaturday}
                                year="numeric"
                                month="2-digit"
                                day="2-digit" />
                        </h1>
                        <ButtonIcon
                            onClick={showNextWeekAppointments}
                            variant="border-filled"
                            size="small"
                            icon={<RightArrowIcon />} />
                        <Button
                            className="scheduler-weekly-schedule_header-button"
                            disabled={isThisWeek}
                            label={<FormattedMessage id="appointments.weekly.this.week" defaultMessage="This Week" />}
                            onClick={showThisWeekAppointments} />
                    </div>
                    <Button
                        label={<FormattedMessage id="appointments.new.appointment" defaultMessage="New Appointment" />}
                        variant="brand"
                        onClick={this.openNewAppointmentModal} />
                </header>
                <WeeklyContent
                    data={data}
                    isLoading={isLoading}
                    weekDays={weekDays}
                    updateDoc={updateDoc}
                    showFormModal={showFormModal}
                    showConfirmationModal={showConfirmationModal}
                />
            </section>
        );
    }
}

WeeklySchedule.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    addDoc: PropTypes.func.isRequired,
    updateDoc: PropTypes.func.isRequired,
    selectedWeek: PropTypes.string.isRequired,
    showWeekAppointments: PropTypes.func.isRequired,
    showNextWeekAppointments: PropTypes.func.isRequired,
    showPrevWeekAppointments: PropTypes.func.isRequired,
    showThisWeekAppointments: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default (injectIntl(WeeklySchedule));
