import React from 'react';
import PropTypes from 'prop-types';
import { FSCollection } from 'react-rainbow-firebase';
import { getFormattedDate } from '../../../../../helpers';
import FutureAppointments from './appointments-content';

export default class Appointments extends React.Component {
    constructor(props) {
        super(props);
        const { match: { params: { id } } } = props;
        this.state = {
            startAt: getFormattedDate(new Date()),
            endBefore: undefined,
            value: 'future',
        };
        this.query = [
            {
                where: 'patient.id',
                eq: id,
            },
            {
                where: 'enable',
                eq: true,
            },
            {
                orderBy: 'date',
                dir: 'asc',
            },
        ];
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { value } = event.target;
        if (value === 'future') {
            this.setState({
                startAt: getFormattedDate(new Date()),
                endBefore: undefined,
                value,
            });
        } else if (value === 'past') {
            this.setState({
                startAt: undefined,
                endBefore: getFormattedDate(new Date()),
                value,
            });
        }
    }

    render() {
        const { match: { params: { id } } } = this.props;
        const { startAt, endBefore, value } = this.state;

        return (
            <FSCollection
                component={FutureAppointments}
                collectionRef="appointments"
                query={this.query}
                startAt={startAt}
                endBefore={endBefore}
                cacheStrategy="subscribeOnce"
                patientId={id}
                onChange={this.handleChange}
                value={value}
            />
        );
    }
}

Appointments.propTypes = {
    match: PropTypes.object.isRequired,
};
