export default function listMemberPhoneNumbers(phoneNumbers) {
    if (Array.isArray(phoneNumbers) && phoneNumbers.length > 0) {
        return phoneNumbers.reduce((acc, phone) => {
            if (phone.label) {
                return `${acc}${phone.nationalFormat} (${phone.label})\n`;
            }
            return `${acc}${phone.nationalFormat}\n`;
        }, '').slice(0, -1);
    }
    return '';
}
