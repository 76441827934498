import React from 'react';
import PropTypes from 'prop-types';
import { FSDoc } from 'react-rainbow-firebase';
import { Button } from 'react-rainbow-components';
import { PDFIcon } from '../../../../../components/icons';
import { getFullPatientName } from '../../../../../helpers';

function ButtonComponent({ doc, onClick }) {
    if (doc && doc.data && doc.data.enable) {
        const {
            firstName,
            lastName,
        } = doc.data;
        return (
            <Button
                className="rainbow-m-left_small"
                title="pdf"
                onClick={() => onClick(getFullPatientName(firstName, lastName))}
            >
                <PDFIcon className="scheduler-patients-details-appointments_report-icon" />
                PDF
            </Button>
        );
    }
    return null;
}

ButtonComponent.propTypes = {
    onClick: PropTypes.func.isRequired,
    doc: PropTypes.object,
};

ButtonComponent.defaultProps = {
    doc: null,
};

export default function PdfButton({ patientId, onClick }) {
    return (
        <FSDoc
            component={ButtonComponent}
            docRef={`patients/${patientId}`}
            onClick={onClick}
        />
    );
}

PdfButton.propTypes = {
    patientId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
