import {
    SHOW_FORM_MODAL,
    HIDE_FORM_MODAL,
} from '../../actions/form-modal';

const initialState = {
    isOpen: false,
    title: '',
    onSubmit: () => {},
    formId: '',
    formComponent: () => {},
    initialValues: {},
};

function showModal(state, { props }) {
    return {
        ...state,
        ...props,
        isOpen: true,
    };
}

export default function formModal(state = initialState, action) {
    switch (action.type) {
        case SHOW_FORM_MODAL:
            return showModal(state, action);

        case HIDE_FORM_MODAL:
            return initialState;

        default:
            return state;
    }
}
