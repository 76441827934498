import { useMemo } from 'react';
import useRoles from './useRoles';

const useCanAccessWithRoles = (accessRoles) => {
    const [roles, isLoading] = useRoles();
    return useMemo(
        () => [accessRoles.some(role => roles.includes(role)), isLoading],
        [roles, isLoading],
    );
};

export default useCanAccessWithRoles;
