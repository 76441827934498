import { LOAD_SELECTED_DAY } from '../../actions/appointments/show-day-appointments';
import history from '../../../history';
import { getNormalizedDate } from '../../../helpers';

const date = history.location.pathname.split('daily/')[1];
const normalizedDate = getNormalizedDate(date);

const initialState = {
    selectedDay: normalizedDate,
};

function loadSelectedDay(state, action) {
    const { formattedDate } = action;
    return {
        ...state,
        selectedDay: formattedDate,
    };
}

export default function dailyAppointments(state = initialState, action) {
    switch (action.type) {
        case LOAD_SELECTED_DAY:
            return loadSelectedDay(state, action);

        default:
            return state;
    }
}
