/* eslint-disable no-console */
export default function bindMethods(methods, context) {
    const contextAux = context;
    if (Array.isArray(methods)) {
        methods.forEach((methodName) => {
            if (typeof context[methodName] === 'function') {
                contextAux[methodName] = context[methodName].bind(context);
            } else {
                console.warn(`The method name ${methodName} is not a function in the context passed`);
            }
        });
    } else {
        console.warn('The methods passed is not an array');
    }
}
