import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export default function formatPhoneNumbers(phoneNumbers) {
    if (!Array.isArray(phoneNumbers)) return [];

    const phoneUtil = PhoneNumberUtil.getInstance();
    const filteredNumbers = phoneNumbers.filter(([phoneNumber]) => !!phoneNumber.phone);
    return filteredNumbers.map(([phoneNumber, label]) => {
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber.phone, phoneNumber.isoCode);
        return {
            label,
            countryCode: `+${number.getCountryCode()}`,
            isoCode: phoneUtil.getRegionCodeForNumber(number).toLocaleLowerCase(),
            nationalNumber: number.getNationalNumber(),
            nationalFormat: phoneUtil.format(number, PhoneNumberFormat.NATIONAL),
            internationalFormat: phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL),
            e164Format: phoneUtil.format(number, PhoneNumberFormat.E164),
            rfc3966Format: phoneUtil.format(number, PhoneNumberFormat.RFC3966),
        };
    });
}
