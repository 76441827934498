import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
    Input,
    Button,
} from 'react-rainbow-components';
import validate from './validate';
import './styles.css';

function EditProfileForm(props) {
    const {
        handleSubmit,
        onSubmit,
        onCancel,
        name,
        placeholder,
        icon,
        label,
        isLoading,
        type,
    } = props;

    return (
        <form
            className="scheduler-profile_edit-profile-form_form-contaier"
            noValidate
            onSubmit={handleSubmit(onSubmit)}>
            <Field
                component={Input}
                name={name}
                label={label}
                required
                placeholder={placeholder}
                type={type}
                icon={icon} />
            <footer className="scheduler-profile_edit-profile-form_actions">
                <Button
                    className="scheduler-profile_edit-profile-form_bottom-actions"
                    label="Cancel"
                    onClick={onCancel} />
                <Button
                    isLoading={isLoading}
                    className="scheduler-profile_edit-profile-form_bottom-actions"
                    type="submit"
                    variant="brand"
                    label="Accept" />
            </footer>
        </form>
    );
}

EditProfileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    label: PropTypes.node.isRequired,
    isLoading: PropTypes.bool,
};

EditProfileForm.defaultProps = {
    isLoading: false,
};

export default reduxForm({
    form: 'edit-profile',
    touchOnBlur: false,
    validate,
})(EditProfileForm);
