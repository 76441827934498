import React from 'react';
import PropTypes from 'prop-types';

export default function Person(props) {
    const { className } = props;
    return (
        <svg className={className} fill="#061C3F" width="16px" height="16px" viewBox="0 0 16 16">
            <g transform="translate(-1200.000000, -135.000000)">
                <g transform="translate(1186.000000, 68.000000)">
                    <g transform="translate(14.000000, 67.000000)">
                        <path d="M1.19510993,14.8952491 C0.970616401,15.1870819 0.552051174,15.2416712 0.260218334,15.0171777 C-0.0316145059,14.7926841 -0.0862037988,14.3741189 0.138289736,14.0822861 C2.33384569,11.2281489 5.04341156,9.77777778 8.22225539,9.77777778 C11.4153691,9.77777778 13.9918477,11.243106 15.8899364,14.1217874 C16.0926139,14.4291725 16.0077317,14.8426599 15.7003467,15.0453374 C15.3929616,15.248015 14.9794742,15.1631328 14.7767966,14.8557477 C13.1193297,12.3419983 10.9550676,11.1111111 8.22225539,11.1111111 C5.47517329,11.1111111 3.14770213,12.3569554 1.19510993,14.8952491 Z M8.22225539,8.44444444 C5.89038645,8.44444444 4.00003317,6.55409117 4.00003317,4.22222222 C4.00003317,1.89035328 5.89038645,1.0658141e-13 8.22225539,1.0658141e-13 C10.5541243,1.0658141e-13 12.4444776,1.89035328 12.4444776,4.22222222 C12.4444776,6.55409117 10.5541243,8.44444444 8.22225539,8.44444444 Z M8.22225539,7.11111111 C9.81774467,7.11111111 11.1111443,5.8177115 11.1111443,4.22222222 C11.1111443,2.62673295 9.81774467,1.33333333 8.22225539,1.33333333 C6.62676611,1.33333333 5.3333665,2.62673295 5.3333665,4.22222222 C5.3333665,5.8177115 6.62676611,7.11111111 8.22225539,7.11111111 Z" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Person.propTypes = {
    className: PropTypes.string,
};

Person.defaultProps = {
    className: undefined,
};
