import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Card, Calendar } from 'react-rainbow-components';
import { FSCollection } from 'react-rainbow-firebase';
import DailyContent from './daily-content';
import history from '../../../../history';
import { getNormalizedDate } from '../../../../helpers';
import { APPOINTMENTS_REF } from '../../../../constants';
import showDayAppointments from '../../../../redux/actions/appointments/show-day-appointments';
import './styles.css';

function getQuery(selectedDay) {
    return [
        {
            where: 'date',
            eq: selectedDay,
        },
        {
            where: 'enable',
            eq: true,
        },
        {
            orderBy: 'time',
            dir: 'asc',
        },
    ];
}

class DailySchedule extends Component {
    constructor(props) {
        super(props);
        const date = history.location.pathname.split('daily/')[1];
        const normalizedDate = getNormalizedDate(date);
        props.showDayAppointments(normalizedDate);
        this.state = {
            query: getQuery(props.selectedDay),
        };
    }

    componentDidUpdate({ selectedDay: prevSelectedDay }) {
        const { selectedDay } = this.props;
        if (prevSelectedDay !== selectedDay) {
            this.updateQuery();
        }
    }

    updateQuery() {
        const { selectedDay } = this.props;
        this.setState({
            query: getQuery(selectedDay),
        });
    }

    render() {
        const {
            selectedDay,
            showDayAppointments,
            generateReport,
            showConfirmationModal,
            showFormModal,
        } = this.props;
        const { query } = this.state;

        return (
            <section className="scheduler-daily-schedule_wrap-container">
                <Card className="scheduler-daily-schedule_calendar-container">
                    <Calendar
                        value={selectedDay}
                        onChange={showDayAppointments} />
                </Card>
                <FSCollection
                    currentDate={selectedDay}
                    generateReport={generateReport}
                    showConfirmationModal={showConfirmationModal}
                    showFormModal={showFormModal}
                    component={DailyContent}
                    collectionRef={APPOINTMENTS_REF}
                    query={query}
                />
            </section>
        );
    }
}

DailySchedule.propTypes = {
    showDayAppointments: PropTypes.func.isRequired,
    selectedDay: PropTypes.string.isRequired,
    generateReport: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
};

export default connect(
    () => ({}),
    dispath => bindActionCreators({
        showDayAppointments,
    }, dispath),
)(DailySchedule);
