import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedPhoneNumber, getRenderedPhone } from '../../helpers';

export default function PhoneCell(props) {
    const { value } = props;

    const renderPhone = getRenderedPhone(value.phone, value.phoneNumbers);
    if (!renderPhone) {
        return null;
    }
    return (
        <span title={getFormattedPhoneNumber(renderPhone)}>
            {renderPhone}
        </span>
    );
}

PhoneCell.propTypes = {
    value: PropTypes.object,
};

PhoneCell.defaultProps = {
    value: undefined,
};
