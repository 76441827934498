import { getFormattedDate, isValidYear, isValidDate } from '.';

export default function getNormalizedDate(date) {
    if (date) {
        const year = parseInt(date.split('/')[0], 10);
        const currentYear = new Date().getFullYear();
        if (isValidDate(date) && isValidYear(year, currentYear)) {
            return getFormattedDate(new Date(date));
        }
    }
    return getFormattedDate(new Date());
}
