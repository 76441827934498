import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { Spinner } from 'react-rainbow-components';
import { Trash } from '@rainbow-modules/icons';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import ZoomedImage from 'react-rainbow-components/components/ZoomableImage/zoomedImage';
import TruncatedText from 'react-rainbow-components/components/Structural/truncatedText';
import { showConfirmationModal as showConfirmation } from '../../../../../../redux/actions/confirmation-modal';
import {
    StyledFileContainer,
    StyledContainerImage,
    StyledContainerSpinner,
    StyledImage,
    StyledDeleteButton,
    StyledCheckMark,
} from './styled';

function Image(props) {
    const {
        imageRef, onSelect, onError, allowDelete, onDelete, showConfirmationModal,
    } = props;
    const [src, setSrc] = useState();
    const [isOpen, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const deleteBtn = useRef(null);
    const ref = useRef();
    const imageRect = useRef({});

    useEffect(() => {
        (async () => {
            await imageRef
                .getDownloadURL()
                .then((url) => {
                    setSrc(url);
                })
                .catch((error) => {
                    onError(error);
                });
        })();
    }, [imageRef, onError]);

    const handleClick = (e) => {
        const isDeleteButtonClicked = deleteBtn.current.htmlElementRef.current.contains(e.target);
        if (isDeleteButtonClicked) {
            return null;
        }
        imageRect.current = ref.current.getBoundingClientRect();
        setOpen(true);
        return onSelect(imageRef);
    };

    const openModalDelete = () => showConfirmationModal({
        title: 'Are you sure you want to delete this file?',
        message: 'This file will be deleted immediately. You can’t undo this action.',
        onAccept: () => onDelete(imageRef),
    });

    return (
        <StyledFileContainer>
            <StyledContainerImage type="button" onClick={handleClick}>
                <RenderIf isTrue={!!src}>
                    <StyledImage
                        src={src}
                        alt={imageRef.name}
                        onLoad={() => setLoading(false)}
                        loading={loading}
                        ref={ref}
                    />
                    <RenderIf isTrue={isOpen}>
                        <ZoomedImage
                            src={src}
                            alt={imageRef.name}
                            close={() => setOpen(false)}
                            originalRect={imageRect.current}
                        />
                    </RenderIf>
                    <RenderIf isTrue={allowDelete}>
                        <StyledDeleteButton
                            variant="inverse"
                            size="medium"
                            icon={<Trash />}
                            onClick={openModalDelete}
                            ref={deleteBtn}
                        />
                    </RenderIf>
                    <StyledCheckMark />
                </RenderIf>
                <RenderIf isTrue={loading}>
                    <StyledContainerSpinner>
                        <Spinner type="arc" variant="brand" />
                    </StyledContainerSpinner>
                </RenderIf>
            </StyledContainerImage>
            <TruncatedText>{imageRef.name}</TruncatedText>
        </StyledFileContainer>
    );
}

Image.propTypes = {
    imageRef: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onError: PropTypes.func,
    onDelete: PropTypes.func,
    allowDelete: PropTypes.bool,
    showConfirmationModal: PropTypes.func.isRequired,
};

Image.defaultProps = {
    onSelect: () => {},
    onError: () => {},
    onDelete: () => {},
    allowDelete: false,
};

function stateToProps() {
    return {};
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        showConfirmationModal: showConfirmation,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(injectIntl(Image));
