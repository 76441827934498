import React from 'react';
import PropTypes from 'prop-types';

export default function PencilCircle(props) {
    const { className } = props;
    return (
        <svg className={className} width="32px" height="32px" viewBox="0 0 32 32">
            <g id="pencil-circle">
                <circle id="Oval" fill="#01B6F5" cx="16" cy="16" r="16" />
                <path d="M22.9360716,18.5682934 C23.17359,18.5682934 23.3660907,18.7609621 23.3660907,18.9984805 L23.3660907,22.8165798 C23.3647469,24.0034998 22.4029151,24.9653316 21.2159952,24.9666754 L9.15009557,24.9666754 C7.96317565,24.9653316 7.00134381,24.0034998 7,22.8165798 L7,11.6107185 C7.00134381,10.4237985 7.96317565,9.46196675 9.15009557,9.46062294 L12.9681949,9.46062294 C13.2057133,9.46062294 13.3982141,9.65312366 13.3982141,9.89064205 C13.3982141,10.1281604 13.2057133,10.3206612 12.9681949,10.3206612 L9.15009557,10.3206612 C8.43787641,10.321501 7.8608781,10.8984994 7.86003823,11.6107185 L7.86003823,22.8165798 C7.8608781,23.528631 8.43787641,24.1057973 9.15009557,24.1066372 L21.2159952,24.1066372 C21.9282143,24.1057973 22.5052126,23.528631 22.5060525,22.8165798 L22.5060525,18.9983125 C22.5060525,18.7607941 22.6985532,18.5682934 22.9360716,18.5682934 Z M13.898115,15.7515129 L21.0738669,8.57556892 L23.3880993,10.8898013 L16.2123473,18.0657452 L13.898115,15.7515129 Z M12.7272727,19.2365874 L13.4356524,16.6791255 L15.2847346,18.5282077 L12.7272727,19.2365874 Z M24.0279446,7.35922915 L24.604631,7.93591549 C25.0837951,8.4160395 25.0837951,9.19333748 24.604631,9.67346149 L24.0834248,10.1946677 L21.7691925,7.88043536 L22.2903987,7.35922915 C22.7705227,6.88025695 23.5478206,6.88025695 24.0279446,7.35922915 Z" id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" />
            </g>
        </svg>
    );
}

PencilCircle.propTypes = {
    className: PropTypes.string,
};

PencilCircle.defaultProps = {
    className: undefined,
};
