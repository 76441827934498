import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { RainbowFirebaseApp } from '@rainbow-modules/app';
import App from './app';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import I18n from './i18n';
import firebase from './firebase';

ReactDOM.render(
    <RainbowFirebaseApp app={firebase}>
        <Provider store={store}>
            <I18n>
                <App />
            </I18n>
        </Provider>
    </RainbowFirebaseApp>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
