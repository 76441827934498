export const HIDE_FORM_MODAL = 'HIDE_FORM_MODAL';
export function hideFormModal() {
    return {
        type: HIDE_FORM_MODAL,
    };
}

export const SHOW_FORM_MODAL = 'SHOW_FORM_MODAL';
export function showFormModal(props) {
    return {
        type: SHOW_FORM_MODAL,
        props,
    };
}
