import React from 'react';
import PropTypes from 'prop-types';
import {
    Tabset,
    Tab,
} from 'react-rainbow-components';
import {
    Route,
    Switch,
} from 'react-router-dom';
import BackButton from '../../../../components/back-button';
import { navigateTo } from '../../../../history';
import Details from './details';
import Files from './files';
import '../../patients/patient-details/styles.css';

function navigate(event, name) {
    return navigateTo(name);
}

export default function ProspectDetails(props) {
    const {
        location: { pathname },
        match: { params: { id } },
    } = props;
    const detailsPathname = `/app/prospect/${id}`;
    const filesPathname = `/app/prospect/${id}/files`;

    return (
        <article className="scheduler-patient-details_wrap-container">
            <BackButton className="scheduler-patient-details_back-button" onClick={() => navigateTo('/app/prospects')} />
            <Tabset
                activeTabName={pathname}
                onSelect={navigate}
            >
                <Tab label="DETAILS" name={detailsPathname} />
                <Tab label="FILES" name={filesPathname} />
            </Tabset>
            <article className="scheduler-patient-details_content-container">
                <Switch>
                    <Route exact path="/app/prospect/:id" component={Details} />
                    <Route path="/app/prospect/:id/files" component={Files} />
                </Switch>
            </article>
        </article>
    );
}

ProspectDetails.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
};

ProspectDetails.defaultProps = {
    location: {},
    match: {},
};
