import { useMemo } from 'react';
import useUserClaims from './useUserClaims';
import getRoles from '../helpers/get-roles';

const useRoles = () => {
    const [claims, isLoading] = useUserClaims();
    return useMemo(() => [getRoles(claims), isLoading], [claims, isLoading]);
};

export default useRoles;
