function validate(values) {
    const {
        date,
        time,
        patient,
        address,
    } = values;
    const errors = {};
    if (!date) {
        errors.date = 'Select appointment date is a required field';
    }
    if (!time) {
        errors.time = 'Select appointment time is a required field';
    }
    if (!patient) {
        errors.patient = 'Patient is a required field';
    }
    if (!address) {
        errors.address = 'Address is a required field';
    }
    return errors;
}

export default validate;
