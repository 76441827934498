import firebase from '../../../firebase';

export function createUser(userData) {
    const addUser = firebase.functions().httpsCallable('addUser');
    return addUser(userData);
}

export function deleteUser(uid) {
    const removeUser = firebase.functions().httpsCallable('removeUser');
    return removeUser({ uid });
}

export async function listAllUsers() {
    const listUsers = firebase.functions().httpsCallable('listUsers');
    const result = await listUsers();
    return result.data.users;
}

export function updateUser(userData) {
    const updateUser = firebase.functions().httpsCallable('updateUser');
    return updateUser(userData);
}
