import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    Column,
    MenuItem,
    Button,
    Input,
    TableWithBrowserPagination,
    withDebounce,
    RenderIf,
} from 'react-rainbow-components';
import {
    PencilIcon,
    AddIcon,
    SearchIcon,
    GoToIcon,
    PDFIcon,
} from '../../../components/icons';
import {
    bindMethods,
    getFormattedPhoneNumber,
    filter,
    filterByCreatedBy,
    filterByDate,
    sort,
    parsePhoneNumbers,
} from '../../../helpers';
import PhoneCell from '../../../components/phone-cell';
import DateTime from '../../../components/date-time';
import UserCell from '../../../components/user-cell';
import { showFormModal } from '../../../redux/actions/form-modal';
import { generateReport } from '../../../redux/actions/prospects';
import ProspectForm from './prospect-form';
import { navigateTo } from '../../../history';
import NameColumn from './nameColumn';
import UserPicker from './user-picker';
import DatePicker from './date-picker';
import './styles.css';

const messages = defineMessages({
    searchPlaceholder: {
        id: 'prospect.placeholder',
        defaultValue: 'Find Prospect',
    },
    formModal: {
        id: 'prospects.modal.new.prospect',
        defaultValue: 'New Prospect',
    },
    editFormModal: {
        id: 'prospects.modal.edit.prospect',
        defaultValue: 'Edit Prospect',
    },
});

function navigateToProspect(event, { id }) {
    navigateTo(`/app/prospect/${id}`);
}

function getCreatedByFilter(data) {
    return data.reduce((acc, value) => `${acc} ${value.name}`, '');
}

const DebouncedInput = withDebounce(Input);

class ProspectsTable extends Component {
    constructor(props) {
        super(props);
        bindMethods([
            'openFormModal',
            'openEditFormModal',
            'handleOnSort',
            'handleSearch',
            'handleDateFilter',
            'handleFilterByCreator',
            'generateReport',
        ], this);
        this.state = {
            searchTerm: '',
            dateFilter: null,
            createdByFilter: null,
        };
    }

    openFormModal() {
        const { showFormModal, intl, addDoc } = this.props;
        showFormModal({
            title: intl.formatMessage(messages.formModal),
            formComponent: ProspectForm,
            formId: 'prospect-form',
            onSubmit: addDoc,
        });
    }

    openEditFormModal(event, { id, data }) {
        const { showFormModal, intl, updateDoc } = this.props;
        showFormModal({
            title: intl.formatMessage(messages.editFormModal),
            formComponent: ProspectForm,
            formId: 'prospect-form',
            onSubmit: editedData => updateDoc(id, editedData),
            initialValues: {
                ...data,
                phone: getFormattedPhoneNumber(data.phone),
                phoneNumbers: parsePhoneNumbers(data.phoneNumbers),
            },
        });
    }

    handleSearch(event) {
        const searchTerm = event.target.value;
        this.setState({
            searchTerm,
        });
    }

    handleOnSort(event, sortedBy, sortDirection) {
        this.setState({
            sortedBy,
            sortDirection,
        });
    }

    handleDateFilter(value) {
        this.setState({
            dateFilter: value,
        });
    }

    handleFilterByCreator(value) {
        this.setState({
            createdByFilter: value,
        });
    }

    generateReport() {
        const { generateReport, data } = this.props;
        const {
            sortedBy,
            sortDirection,
            searchTerm,
            dateFilter,
            createdByFilter,
        } = this.state;
        const createdBy = Array.isArray(createdByFilter) ? getCreatedByFilter(createdByFilter) : '';
        const patients = sort(
            filterByDate(dateFilter, filterByCreatedBy(createdBy, filter(searchTerm, data))),
            { sortedBy, sortDirection },
        );
        generateReport(patients);
    }

    render() {
        const {
            isLoading,
            intl,
            data,
            hasAllAccess,
        } = this.props;
        const {
            sortedBy,
            sortDirection,
            searchTerm,
            dateFilter,
            createdByFilter,
        } = this.state;
        const createdBy = Array.isArray(createdByFilter) ? getCreatedByFilter(createdByFilter) : '';
        const patients = sort(
            filterByDate(dateFilter, filterByCreatedBy(createdBy, filter(searchTerm, data))),
            { sortedBy, sortDirection },
        );
        const displayedPatientsAmount = patients.length;

        return (
            <section className="scheduler-prospects_container">
                <header className="scheduler-prospects_table-header">
                    <div className="scheduler-prospects_table-header-row-spread">
                        <div>
                            <h1 className="scheduler-prospects_table-title">
                                <FormattedMessage id="prospects" defaultMessage="Prospects" />
                            </h1>
                            <h2 className="scheduler-prospects_table-subtitle">
                                <FormattedMessage id="prospects.all.prospects" defaultMessage="All Prospects" />
                                {' • '}
                                {displayedPatientsAmount}
                            </h2>
                        </div>
                        <div className="scheduler-prospects_table-header-actions">
                            <RenderIf isTrue={hasAllAccess}>
                                <Button
                                    className="rainbow-m-right_small rainbow-m-left_small"
                                    onClick={this.generateReport}>
                                    <PDFIcon className="scheduler-patients_report-icon" />
                                    PDF
                                </Button>
                            </RenderIf>
                            <Button onClick={this.openFormModal} variant="brand">
                                <AddIcon className="rainbow-m-right_x-small" />
                                <FormattedMessage id="add" defaultMessage="Add" />
                            </Button>
                        </div>
                    </div>
                    <div className="scheduler-prospects_table-header-row">
                        <DebouncedInput
                            id="prospects-table-input"
                            onChange={this.handleSearch}
                            label="Find prospect"
                            hideLabel
                            className="scheduler-prospects_search-input"
                            type="search"
                            placeholder={intl.formatMessage(messages.searchPlaceholder)}
                            icon={<SearchIcon />}
                        />
                        <RenderIf isTrue={hasAllAccess}>
                            <UserPicker className="rainbow-m-right_small scheduler-prospects_users-filter" onChange={this.handleFilterByCreator} value={createdByFilter} />
                        </RenderIf>
                        <DatePicker onChange={this.handleDateFilter} />
                    </div>
                </header>
                <TableWithBrowserPagination
                    id="prospects-table"
                    className="scheduler-prospects_table"
                    pageSize={40}
                    keyField="id"
                    sortDirection={sortDirection}
                    sortedBy={sortedBy}
                    onSort={this.handleOnSort}
                    data={patients}
                    isLoading={isLoading}
                >
                    <Column
                        header={<FormattedMessage id="patients.first.name" defaultMessage="First Name" />}
                        field="data.firstName"
                        sortable
                        component={NameColumn}
                    />
                    <Column
                        header={<FormattedMessage id="patients.last.name" defaultMessage="Last Name" />}
                        field="data.lastName"
                        sortable
                    />
                    <Column
                        header={<FormattedMessage id="patients.phone.number" defaultMessage="Phone Number" />}
                        field="data"
                        component={PhoneCell}
                    />
                    <Column
                        header={<FormattedMessage id="createdAt" defaultMessage="Created at" />}
                        field="data.createdAt"
                        component={({ value }) => <DateTime value={value.toDate()} />}
                    />
                    <Column
                        header={<FormattedMessage id="createdBy" defaultMessage="Created by" />}
                        field="data.createdBy"
                        component={UserCell}
                    />
                    <Column type="action">
                        <MenuItem
                            label="View"
                            icon={<GoToIcon className="scheduler-daily-schedule_table-action-icon" />}
                            onClick={navigateToProspect}
                        />
                        <MenuItem
                            label={<FormattedMessage id="edit" defaultMessage="Edit" />}
                            icon={<PencilIcon className="scheduler-prospects_table-action-icon" />}
                            onClick={this.openEditFormModal}
                        />
                    </Column>
                </TableWithBrowserPagination>
            </section>
        );
    }
}

ProspectsTable.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    addDoc: PropTypes.func.isRequired,
    updateDoc: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    hasAllAccess: PropTypes.bool.isRequired,
    generateReport: PropTypes.func.isRequired,
};

function stateToProps() {
    return {};
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        showFormModal,
        generateReport,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(injectIntl(ProspectsTable));
