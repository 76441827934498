import { getFormattedDate } from '../../../../../helpers';
import firstDayOnWeek from './first-day-on-week';

export default function getWeekDays(date) {
    const firstDay = firstDayOnWeek(date);
    const monday = firstDay + 1;
    const tuesday = firstDay + 2;
    const wednesday = firstDay + 3;
    const thursday = firstDay + 4;
    const friday = firstDay + 5;
    const saturday = firstDay + 6;
    const weekDays = {
        monday: getFormattedDate(new Date(date.getFullYear(), date.getMonth(), monday)),
        tuesday: getFormattedDate(new Date(date.getFullYear(), date.getMonth(), tuesday)),
        wednesday: getFormattedDate(new Date(date.getFullYear(), date.getMonth(), wednesday)),
        thursday: getFormattedDate(new Date(date.getFullYear(), date.getMonth(), thursday)),
        friday: getFormattedDate(new Date(date.getFullYear(), date.getMonth(), friday)),
        saturday: getFormattedDate(new Date(date.getFullYear(), date.getMonth(), saturday)),
    };
    return weekDays;
}
