import { createUser, updateProfile } from '../../services/firebase';
import showErrorMessage from '../app/show-error-message';
import updateUserData from '../authentication/update-user-data';

export const CREATE_ACCOUNT_LOADING = 'CREATE_ACCOUNT_LOADING';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';

export default function createAccount(user) {
    return async (dispatch) => {
        const {
            name: displayName,
            email,
            password,
        } = user;
        dispatch({ type: CREATE_ACCOUNT_LOADING });

        try {
            await createUser(email, password);
            await updateProfile({ displayName });
            dispatch(updateUserData({ displayName }));
            dispatch({ type: CREATE_ACCOUNT_SUCCESS });
        } catch (error) {
            dispatch(showErrorMessage(error));
        }
    };
}
