import authentication from './authentication';
import registration from './registration';
import forgot from './forgotPassword';
import weeklyAppointments from './weekly-appointments';
import dailyAppointments from './daily-appointment';
import profile from './profile';
import app from './app';
import confirmationModal from './confirmation-modal';
import formModal from './form-modal';

export default {
    app,
    authentication,
    registration,
    forgot,
    weeklyAppointments,
    dailyAppointments,
    profile,
    confirmationModal,
    formModal,
};
