import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Prismic, QueryAt } from 'react-prismic-cms';
import Content from '../../../components/prismic-content';
import BackButton from '../../../components/back-button';
import './styles.css';

function PrivacyPolicy() {
    return (
        <div className="scheduler-privacy-policy_container">
            <div className="scheduler-privacy-policy_header">
                <BackButton className="scheduler-privacy-policy_back-button" />
                <FormattedMessage
                    id="privacy"
                    defaultMessage="Privacy Policy" />
            </div>
            <Prismic repo="rainbow-doc">
                <QueryAt
                    className="scheduler-privacy-policy_content"
                    path="document.type"
                    value="privacy-policy"
                    content="privacy-policy"
                    component={Content}
                />
            </Prismic>
        </div>
    );
}

export default PrivacyPolicy;
