import { deleteUser } from '../../services/profile';
import { showSuccessMessage, showErrorMessage } from '../app';
import { hideConfirmationModal, showConfirmationModal } from '../confirmation-modal';
import { PROFILE_END_LOADING_FORM } from '.';

export const PROFILE_REMOVE_MEMBER = 'PROFILE_REMOVE_MEMBER';

function getError(error) {
    if (error.details) return error.details;
    if (error.code === 'permission-denied') return error.message;
    return 'Looks like there is a problem. Try again later.';
}

export default function removeMember(uid) {
    return async (dispatch) => {
        dispatch(showConfirmationModal({
            itemsAmount: 1,
            message: 'Are you sure you want to delete this member',
            closeWhenAccept: false,
            isLoading: true,
        }));

        try {
            await deleteUser(uid);
            dispatch(showSuccessMessage('The member has been successfully removed.'));
            dispatch({
                type: PROFILE_END_LOADING_FORM,
            });
            dispatch({
                type: PROFILE_REMOVE_MEMBER,
                uid,
            });
            dispatch(hideConfirmationModal());
        } catch (error) {
            dispatch(showErrorMessage(getError(error)));
            dispatch({
                type: PROFILE_END_LOADING_FORM,
            });
            dispatch(hideConfirmationModal());
        }
    };
}
