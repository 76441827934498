import { PhoneNumberUtil } from 'google-libphonenumber';

export default function shouldShowLegacyPhone(legacyPhone, phoneNumbers) {
    const phoneUtil = PhoneNumberUtil.getInstance();

    if (!legacyPhone) {
        return false;
    }

    return Array.isArray(phoneNumbers)
        ? phoneNumbers.every((item) => {
            try {
                const matchType = phoneUtil.isNumberMatch(legacyPhone, item.e164Format);
                return matchType === PhoneNumberUtil.MatchType.NO_MATCH
                || matchType === PhoneNumberUtil.MatchType.NOT_A_NUMBER;
            } catch (e) {
                return false;
            }
        }) : true;
}
