import hideProfileModal from './hide-profile-modal';

export const SHOW_PROFILE_MODAL = 'SHOW_PROFILE_MODAL';

export default function showProfileModal({ modalProps, formProps }) {
    return (dispatch) => {
        dispatch({
            type: SHOW_PROFILE_MODAL,
            modalProps: {
                ...modalProps,
                onRequestClose: () => dispatch(hideProfileModal()),
            },
            formProps: {
                ...formProps,
                onCancel: () => dispatch(hideProfileModal()),
            },
        });
    };
}
