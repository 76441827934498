import React from 'react';
import PropTypes from 'prop-types';

export default function PDF(props) {
    const { className } = props;
    return (
        <svg className={className} fill="#000000" width="384px" height="512px" viewBox="0 0 384 512">
            <g id="pdf-(3)" fill="currentColor" fillRule="nonzero">
                <path d="M380.875,109.792 L274.208,3.125 C272.208,1.125 269.5,0 266.666,0 L42.666,0 C19.135,0 0,19.135 0,42.667 L0,469.334 C0,492.865 19.135,512 42.667,512 L341.334,512 C364.865,512 384,492.865 384,469.333 L384,117.333 C384,114.5 382.875,111.792 380.875,109.792 Z M277.333,36.417 L347.583,106.667 L298.666,106.667 C286.906,106.667 277.333,97.094 277.333,85.334 L277.333,36.417 L277.333,36.417 Z M362.667,469.333 C362.667,481.093 353.094,490.666 341.334,490.666 L42.667,490.666 C30.907,490.666 21.334,481.093 21.334,469.333 L21.334,42.667 C21.334,30.907 30.907,21.334 42.667,21.334 L256,21.334 L256,85.334 C256,108.865 275.135,128 298.667,128 L362.667,128 L362.667,469.333 Z" id="Shape" />
                <path d="M246.385,300.469 C236.51,292.698 227.125,284.709 220.875,278.459 C212.75,270.334 205.51,262.459 199.219,254.959 C209.032,224.636 213.334,209.001 213.334,200.667 C213.334,165.261 200.542,158 181.334,158 C166.74,158 149.334,165.583 149.334,201.688 C149.334,217.605 158.053,236.928 175.334,259.386 C171.105,272.292 166.136,287.178 160.553,303.959 C157.865,312.011 154.949,319.469 151.865,326.365 C149.355,327.48 146.917,328.615 144.563,329.792 C136.084,334.032 128.032,337.844 120.563,341.386 C86.5,357.511 64,368.178 64,389.24 C64,404.532 80.615,414 96,414 C115.833,414 145.781,387.51 167.656,342.885 C190.364,333.927 218.594,327.291 240.875,323.135 C258.729,336.864 278.448,350 288,350 C314.448,350 320,334.708 320,321.885 C320,296.666 291.187,296.666 277.333,296.666 C273.031,296.667 261.49,297.938 246.385,300.469 Z M96,392.667 C89.906,392.667 85.781,389.792 85.333,389.24 C85.333,381.677 107.885,370.99 129.698,360.657 C131.083,360.001 132.49,359.344 133.917,358.667 C117.896,381.897 102.052,392.667 96,392.667 Z M170.667,201.688 C170.667,179.334 177.605,179.334 181.334,179.334 C188.876,179.334 192.001,179.334 192.001,200.667 C192.001,205.167 189.001,216.417 183.511,233.98 C175.135,221.084 170.667,210.032 170.667,201.688 Z M178.844,316.334 C179.511,314.48 180.157,312.605 180.782,310.709 C184.74,298.834 188.303,288.167 191.48,278.563 C195.907,283.438 200.678,288.428 205.793,293.542 C207.793,295.542 212.751,300.042 219.356,305.677 C206.208,308.542 192.219,312.094 178.844,316.334 Z M298.667,321.886 C298.667,326.678 298.667,328.667 288.771,328.73 C285.865,328.105 279.146,324.147 270.854,318.501 C273.864,318.168 276.083,318.001 277.333,318.001 C293.094,318.001 297.563,319.542 298.667,321.886 Z" id="Shape" />
            </g>
        </svg>
    );
}

PDF.propTypes = {
    className: PropTypes.string,
};

PDF.defaultProps = {
    className: undefined,
};
