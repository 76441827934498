import { getWeekNumber, getYearIso } from '../../../helpers';
import { showWeekAppointments } from '.';


export default function showThisWeekAppointments() {
    return (dispatch) => {
        const today = new Date();
        const thisYear = getYearIso(today);
        const thisWeek = getWeekNumber(today);
        const weekUrl = `${thisYear}/${thisWeek}`;
        return dispatch(showWeekAppointments(weekUrl));
    };
}
