import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-rainbow-components';

export default function Footer({ onCancel, onAccept, isLoading }) {
    return (
        <div className="rainbow-flex rainbow-justify_end">
            <Button
                className="rainbow-m-right_medium scheduler-confirmation-modal_button-cancel"
                label={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
                onClick={onCancel}
            />
            <Button
                label={<FormattedMessage id="delete" defaultMessage="Delete" />}
                variant="destructive"
                onClick={onAccept}
                isLoading={isLoading}
            />
        </div>
    );
}

Footer.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
