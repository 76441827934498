import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    Card,
    Input,
    ButtonGroup,
    RadioButtonGroup,
    Button,
    TableWithBrowserPagination,
    Column,
    MenuItem,
    withDebounce,
} from 'react-rainbow-components';
import {
    SearchIcon,
    PencilIcon,
    TrashIcon,
    GoToIcon,
} from '../../../../../components/icons';
import DateLargeCell from '../../../../../components/date-large-cell';
import TimeCell from '../../../../../components/time-cell';
import './styles.css';
import StatusSelect from '../../../../../components/status-select';
import AppointmentForm from '../../../schedule/appointment-form';
import { bindMethods, getDisableAppointments, sort } from '../../../../../helpers';
import { showFormModal } from '../../../../../redux/actions/form-modal';
import { showConfirmationModal } from '../../../../../redux/actions/confirmation-modal';
import { generatePatientAppointmentsReport } from '../../../../../redux/actions/appointments';
import { getCurrentUser } from '../../../../../redux/services/firebase';
import { navigateTo } from '../../../../../history';
import filter from './filter';
import TableHeader from './table-header';
import AddAppointmentButton from './add-appointment-button';
import PdfButton from './pdf-button';

function navigateToAppoinment(event, { id }) {
    navigateTo(`/app/appointment/${id}`);
}

const DebouncedInput = withDebounce(Input);

const options = [
    { value: 'future', label: 'Future' },
    { value: 'past', label: 'Past' },
];
class FutureAppointments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments: props.data,
            searchTerm: '',
            sortedBy: 'data.date',
            sortDirection: 'asc',
            selectedAppointments: [],
        };
        bindMethods([
            'openFormModal',
            'openEditFormModal',
            'openConfirmationModal',
            'openConfirmationModalFromRow',
            'handleSelection',
            'handleOnSort',
            'generateReport',
            'updateStatus',
            'handleSearch',
        ], this);
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (data !== prevProps.data) {
            this.updateAppointments();
        }
    }

    updateAppointments() {
        const { data, intl } = this.props;
        const { searchTerm, sortedBy, sortDirection } = this.state;
        this.setState({
            appointments: sort(
                filter(searchTerm, data, intl),
                { sortedBy, sortDirection },
            ),
        });
    }

    openFormModal(patient) {
        const {
            showFormModal,
            addDoc,
        } = this.props;

        showFormModal({
            title: 'New Appointment',
            formComponent: AppointmentForm,
            formId: 'appointment-form',
            onSubmit: addDoc,
            initialValues: {
                date: new Date(),
                status: 'not confirmed',
                patient,
            },
        });
    }

    openEditFormModal(event, { id, data }) {
        const {
            showFormModal,
            updateDoc,
        } = this.props;

        showFormModal({
            title: 'Edit Appointment',
            formComponent: AppointmentForm,
            formId: 'appointment-form',
            onSubmit: editedData => updateDoc(id, editedData),
            initialValues: data,
        });
    }

    openConfirmationModal() {
        const { updateDocs, showConfirmationModal } = this.props;
        const { selectedAppointments } = this.state;
        showConfirmationModal({
            collectionName: 'appointments',
            itemsAmount: selectedAppointments.length,
            onAccept: () => updateDocs(getDisableAppointments(selectedAppointments)),
        });
    }

    openConfirmationModalFromRow(event, { id }) {
        const { updateDoc, showConfirmationModal } = this.props;
        showConfirmationModal({
            collectionName: 'appointments',
            itemsAmount: 1,
            onAccept: () => updateDoc(id, {
                userId: getCurrentUser().uid,
                enable: false,
            }),
        });
    }

    handleSelection(selectedAppointments) {
        this.setState({
            selectedAppointments,
        });
    }

    handleSearch(event) {
        const searchTerm = event.target.value;
        const { data, intl } = this.props;
        this.setState({
            searchTerm,
            appointments: filter(searchTerm, data, intl),
        });
    }

    handleOnSort(event, sortedBy, sortDirection) {
        const { appointments } = this.state;
        this.setState({
            sortedBy,
            sortDirection,
            appointments: sort(appointments, { sortedBy, sortDirection }),
        });
    }

    generateReport(patientName) {
        const { generatePatientAppointmentsReport, intl } = this.props;
        const { appointments } = this.state;
        generatePatientAppointmentsReport(appointments, intl, patientName);
    }

    updateStatus(id, data) {
        const { updateDoc } = this.props;
        updateDoc(id, {
            ...data,
            userId: getCurrentUser().uid,
        });
    }

    render() {
        const {
            isLoading,
            patientId,
            onChange,
            value,
        } = this.props;
        const {
            selectedAppointments,
            sortedBy,
            sortDirection,
            appointments,
        } = this.state;
        const displayedAppointmentsAmount = appointments.length;
        const isDeleteButtonDisabled = selectedAppointments.length === 0;

        return (
            <article className="scheduler-patients-details-appointments_container">
                <RadioButtonGroup
                    className="scheduler-patients-details-appointments_radio-button"
                    options={options}
                    onChange={onChange}
                    value={value}
                    variant="brand"
                />
                <Card className="scheduler-patients-details-appointments_table-card">
                    <header className="scheduler-patients-details-appointments_table-header">
                        <div className="scheduler-patients-details-appointments_table-header-content">
                            <div>
                                <h1 className="scheduler-patients-details-appointments_table-title">
                                    <TableHeader patientId={patientId} />
                                </h1>
                                <h2 className="scheduler-patients-details-appointments_table-subtitle">
                                    Amount
                                    {' • '}
                                    {displayedAppointmentsAmount}
                                </h2>
                            </div>
                            <div className="scheduler-patients-details-appointments_actions-container">
                                <DebouncedInput
                                    onChange={this.handleSearch}
                                    label={<FormattedMessage id="appointments.daily.schedule.search" defaultMessage="Find appointment" />}
                                    hideLabel
                                    className="scheduler-patients-details-appointments_search-input"
                                    type="search"
                                    placeholder="Find Appointment"
                                    icon={<SearchIcon />} />

                                <ButtonGroup>
                                    <AddAppointmentButton
                                        patientId={patientId}
                                        onClick={this.openFormModal}
                                    />
                                    <Button
                                        className="scheduler-patients_header-delete-button"
                                        onClick={this.openConfirmationModal}
                                        disabled={isDeleteButtonDisabled}
                                    >
                                        <TrashIcon className="rainbow-m-right_x-small" />
                                        <FormattedMessage id="delete" defaultMessage="Delete" />
                                    </Button>
                                </ButtonGroup>
                                <PdfButton
                                    patientId={patientId}
                                    onClick={this.generateReport}
                                />
                            </div>
                        </div>
                    </header>
                    <TableWithBrowserPagination
                        className="scheduler-patients-details-appointments_table"
                        data={appointments}
                        isLoading={isLoading}
                        pageSize={30}
                        keyField="id"
                        sortDirection={sortDirection}
                        sortedBy={sortedBy}
                        onSort={this.handleOnSort}
                        onRowSelection={this.handleSelection}
                        showCheckboxColumn>

                        <Column
                            header={<FormattedMessage id="date" defaultMessage="Date" />}
                            field="data.date"
                            component={DateLargeCell}
                            sortable
                            width={200} />
                        <Column
                            header={<FormattedMessage id="time" defaultMessage="Time" />}
                            field="data.time"
                            component={TimeCell}
                            sortable
                            width={160} />
                        <Column
                            header={<FormattedMessage id="status" defaultMessage="Status" />}
                            field="data.status"
                            component={StatusSelect}
                            onEdit={this.updateStatus}
                            width={180} />
                        <Column
                            header={<FormattedMessage id="address" defaultMessage="Address" />}
                            field="data.address" />
                        <Column
                            header={<FormattedMessage id="appointments.daily.schedule.table.comment" defaultMessage="Comment" />}
                            field="data.comments" />
                        <Column type="action">
                            <MenuItem
                                label="View"
                                icon={<GoToIcon className="scheduler-patients-details-appointments_table-action-icon" />}
                                onClick={navigateToAppoinment} />

                            <MenuItem
                                label={<FormattedMessage id="edit" defaultMessage="Edit" />}
                                icon={<PencilIcon className="scheduler-patients-details-appointments_table-action-icon" />}
                                onClick={this.openEditFormModal} />

                            <MenuItem
                                label={<FormattedMessage id="delete" defaultMessage="Delete" />}
                                icon={<TrashIcon className="scheduler-patients-details-appointments_table-action-icon" />}
                                onClick={this.openConfirmationModalFromRow} />

                        </Column>
                    </TableWithBrowserPagination>
                </Card>
            </article>
        );
    }
}

FutureAppointments.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    addDoc: PropTypes.func.isRequired,
    updateDoc: PropTypes.func.isRequired,
    updateDocs: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
    generatePatientAppointmentsReport: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    patientId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

function stateToProps() {
    return {};
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        showConfirmationModal,
        showFormModal,
        generatePatientAppointmentsReport,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(injectIntl(FutureAppointments));
