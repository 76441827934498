import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

export default function PublicRoute(props) {
    const {
        component: Component,
        isAuth,
        ...rest
    } = props;

    function render(props) {
        const { location } = props;
        if (isAuth) {
            const to = {
                pathname: '/app',
                state: { from: location },
            };
            return <Redirect to={to} />;
        }
        return <Component {...props} />;
    }
    // eslint-disable-next-line react/jsx-no-bind
    return <Route {...rest} render={render} />;
}

PublicRoute.propTypes = {
    component: PropTypes.func,
    isAuth: PropTypes.bool,
    location: PropTypes.object,
};

PublicRoute.defaultProps = {
    component: () => {},
    isAuth: false,
    location: undefined,
};
