import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-rainbow-components';
import Actions from './actions';
import './styles.css';

export default function RecordCard(props) {
    const {
        className,
        title,
        children,
        onRemove,
        onEdit,
    } = props;

    return (
        <Card
            className={className}
            title={title}
            actions={<Actions onRemove={onRemove} onEdit={onEdit} />}
        >
            <div className="scheduler-record-card_card-content">
                {children}
            </div>
        </Card>
    );
}

RecordCard.propTypes = {
    className: PropTypes.string,
    title: PropTypes.node.isRequired,
    onRemove: PropTypes.func,
    onEdit: PropTypes.func,
    children: PropTypes.node,
};

RecordCard.defaultProps = {
    className: undefined,
    onRemove: undefined,
    onEdit: undefined,
    children: undefined,
};
