import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Avatar, Badge, RenderIf } from 'react-rainbow-components';
import { PersonIcon } from '../icons';
import './styles.css';

export default function MemberCard(props) {
    const {
        avatarSrc,
        name,
        roles,
        email,
        actions,
        disabled,
        className,
    } = props;
    const getContainerClassNames = () => classnames('scheduler-member-card_content', className);

    return (
        <article className={getContainerClassNames()}>
            <div className="scheduler-member-card_user-info">
                <Avatar
                    className="scheduler-member-card_photo"
                    src={avatarSrc}
                    icon={<PersonIcon className="scheduler-member-card_user-icon" />} />
                <div className="scheduler-member-card_info">
                    <h3 className="scheduler-member-card_primary-text">
                        {name}
                        <RenderIf isTrue={disabled}>
                            <Badge label="Disabled" variant="outline-brand" className="rainbow-m-left_x-small rainbow-m-right_x-small" />
                        </RenderIf>
                    </h3>
                    <h4 className="scheduler-member-card_secondary-text">
                        {email}
                    </h4>
                </div>
            </div>
            <div className="scheduler-member-card_permission-cell">
                {
                    [...roles].map(role => <Badge key={role} label={role} />)
                }
            </div>
            <div className="scheduler-member-card_actions">
                {actions}
            </div>
        </article>
    );
}

MemberCard.propTypes = {
    avatarSrc: PropTypes.string,
    name: PropTypes.string,
    roles: PropTypes.any,
    email: PropTypes.string,
    actions: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

MemberCard.defaultProps = {
    avatarSrc: undefined,
    name: undefined,
    roles: undefined,
    email: undefined,
    actions: null,
    disabled: false,
    className: undefined,
};
