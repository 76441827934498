export default function parsePhoneNumbers(phoneNumbers) {
    if (!Array.isArray(phoneNumbers)) {
        return [[{ isoCode: 'us', phone: '', countryCode: '+1' }, '']];
    }

    return phoneNumbers.map(({
        label, isoCode, countryCode, nationalNumber,
    }) => (
        [
            {
                isoCode, phone: `${nationalNumber}`, countryCode,
            },
            label,
        ]
    ));
}
