import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Textarea } from 'react-rainbow-components';
import { getCurrentUser } from '../../../../../redux/services/firebase';

const AddNote = ({ isOpen, onRequestClose, onSubmit }) => {
    const [value, setValue] = useState();
    const [error, setError] = useState();

    const close = () => {
        onRequestClose();
        setValue();
        setError();
    };

    const submit = () => {
        if (!value) {
            return setError('Looks like you forget to enter a note');
        }
        const { displayName, email } = getCurrentUser();
        onSubmit({
            text: value,
            createdAt: new Date(),
            createdBy: { displayName, email },
        });
        return close();
    };

    const handleChange = (event) => {
        setError();
        setValue(event.target.value);
    };

    return (
        <Modal
            isOpen={isOpen}
            title="New Note"
            size="medium"
            onRequestClose={close}
            footer={(
                <div className="rainbow-flex rainbow-justify_end">
                    <Button label="Cancel" className="rainbow-m-right_medium" onClick={close} />
                    <Button label="Save" variant="brand" onClick={submit} />
                </div>
            )}
        >
            <Textarea
                label="Note"
                placeholder="Enter note"
                value={value}
                onChange={handleChange}
                error={error}
                grow
                rows={5}
            />
        </Modal>
    );
};

AddNote.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default AddNote;
