import { getTotalWeekFromYear, getWeekNumber, isValidYear } from '.';

export default function getNormalizedWeek(date) {
    const week = parseInt(date.split('/')[1], 10);
    const year = parseInt(date.split('/')[0], 10);
    const currentWeek = getWeekNumber(new Date());
    const currentYear = new Date().getFullYear();
    const totalWeekFromCurrentYear = getTotalWeekFromYear(year);
    const isValidWeek = week > 0 && week <= totalWeekFromCurrentYear;
    if (isValidWeek && isValidYear(year, currentYear)) {
        return `${year}/${week}`;
    }
    return `${currentYear}/${currentWeek}`;
}
