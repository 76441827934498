import { showWeekAppointments } from '.';
import { getTotalWeekFromYear } from '../../../helpers';
import history from '../../../history';

export default function showPrevWeekAppointments() {
    return (dispatch) => {
        const currentWeekUrl = history.location.pathname.split('weekly/')[1];
        const year = parseInt(currentWeekUrl.split('/')[0], 10);
        const weekNumber = parseInt(currentWeekUrl.split('/')[1], 10);
        if (weekNumber === 1) {
            const totalWeek = getTotalWeekFromYear(year - 1);
            const weekUrl = `${year - 1}/${totalWeek}`;
            return dispatch(showWeekAppointments(weekUrl));
        }
        const weekUrl = `${year}/${weekNumber - 1}`;
        return dispatch(showWeekAppointments(weekUrl));
    };
}
