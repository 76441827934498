const getRoles = (claims) => {
    if (claims.admin === true) {
        return ['admin'];
    }
    if (claims && Array.isArray(claims.roles)) {
        return claims.roles;
    }
    return [];
};

export default getRoles;
