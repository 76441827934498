export default function validate(values) {
    const {
        roles,
    } = values;
    const errors = {};
    if (!Array.isArray(roles) || (Array.isArray(roles) && roles.length === 0)) {
        errors.roles = 'Looks like you forget to select a role.';
    }
    return errors;
}
