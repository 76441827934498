import { getCurrentUser } from '../redux/services/firebase';

export default function getRegularAppointments(selectedPatients, currentDate) {
    return selectedPatients.map(patient => ({
        date: currentDate,
        time: '08:00',
        patient,
        address: 'Wellness Center',
        status: 'confirmed',
        userId: getCurrentUser().uid,
        enable: true,
    }));
}
