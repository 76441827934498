import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Tab,
    Tabset,
} from 'react-rainbow-components';

export default function ScheduleTabset(props) {
    const {
        activeTabName,
        className,
        onSelectTab,
    } = props;

    return (
        <Tabset
            onSelect={onSelectTab}
            activeTabName={activeTabName}
            className={className}
        >
            <Tab
                label={<FormattedMessage id="appointments.daily.schedule" defaultMessage="DAILY SCHEDULE" />}
                name="daily"
                ariaControls="daily schedule"
                id="schedule-daily-tab"
            />
            <Tab
                label={<FormattedMessage id="appointments.weekly.schedule" defaultMessage="WEEKLY SCHEDULE" />}
                name="weekly"
                ariaControls="weekly schedule"
                id="schedule-weekly-tab"
            />
        </Tabset>
    );
}

ScheduleTabset.propTypes = {
    activeTabName: PropTypes.string,
    className: PropTypes.string,
    onSelectTab: PropTypes.func,
};

ScheduleTabset.defaultProps = {
    activeTabName: undefined,
    className: undefined,
    onSelectTab: () => {},
};
