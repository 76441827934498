import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Prismic, QueryAt } from 'react-prismic-cms';
import Content from '../../../components/prismic-content';
import BackButton from '../../../components/back-button';
import './styles.css';

function TermsAndConditions() {
    return (
        <div className="scheduler-terms-condition_container">
            <div className="scheduler-terms-condition_header">
                <BackButton className="scheduler-terms-condition_back-button" />
                <FormattedMessage
                    id="terms"
                    defaultMessage="Terms and Conditions" />
            </div>
            <Prismic repo="rainbow-doc">
                <QueryAt
                    className="scheduler-terms-condition_content"
                    path="document.type"
                    value="terms-and-conditions"
                    content="terms-and-conditions"
                    component={Content} />
            </Prismic>
        </div>
    );
}

export default TermsAndConditions;
