import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-rainbow-components';

export default function InitializingApp(props) {
    const {
        className,
    } = props;
    return (
        <div className={className}>
            <Spinner />
        </div>
    );
}

InitializingApp.propTypes = {
    className: PropTypes.string,
};

InitializingApp.defaultProps = {
    className: undefined,
};
