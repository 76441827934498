/* eslint-disable import/prefer-default-export */
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import { getFormattedPhoneNumber } from '../../../helpers';
import listMemberPhoneNumbers from '../../../helpers/listMemberPhoneNumbers';
import { showErrorMessage } from '../app';

function generatePDF(body) {
    const doc = new JsPDF({
        orientation: 'landscape',
        format: 'legal',
    });
    const totalPagesExp = '{total_pages_count_string}';
    doc.text('Prospects', 14, 22);
    doc.setFontSize(18);
    doc.setTextColor(100);

    doc.autoTable({
        head: [[
            'First Name',
            'Last Name',
            'Phone',
            'DOB',
            'Medicare',
            'Medicaid',
            'Address',
            'Medical Insurance',
            'Notes',
        ]],
        body,
        rowPageBreak: 'avoid',
        startY: 30,
        columnStyles: {
            3: { cellWidth: 30 },
            7: { cellWidth: 30 },
        },
        didDrawPage(data) {
            let str = `Page ${doc.internal.getNumberOfPages()}`;
            if (typeof doc.putTotalPages === 'function') {
                str = `${str} of ${totalPagesExp}`;
            }
            doc.setFontSize(10);
            const { pageSize } = doc.internal;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
    });

    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
    }

    window.open(doc.output('bloburl'), '_blank');
}

function getBody(data) {
    return data.map(({ data }) => {
        const {
            firstName,
            lastName,
            phone,
            phoneNumbers,
            dob,
            medicare,
            medicaid,
            address,
            medicalInsurance,
            notes,
        } = data;
        const renderPhone = listMemberPhoneNumbers(phoneNumbers) || getFormattedPhoneNumber(phone);

        return [
            firstName,
            lastName,
            renderPhone,
            dob,
            medicare,
            medicaid,
            address,
            medicalInsurance,
            notes,
        ];
    });
}

export function generateReport(data) {
    return (dispatch) => {
        if (data.length) {
            const body = getBody(data);
            return generatePDF(body);
        }
        return dispatch(showErrorMessage('There are not prospects to create the report'));
    };
}
