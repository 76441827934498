import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
    Input,
    Button,
    CheckboxGroup,
} from 'react-rainbow-components';
import {
    EmailIcon,
    LockIcon,
    UserIcon,
} from '../../../../components/icons';
import validate from './validate';
import { ROLE_OPTIONS } from '../../../../constants';
import './styles.css';

function MemberForm(props) {
    const {
        handleSubmit,
        onSubmit,
        isLoading,
    } = props;

    return (
        <form
            className="scheduler-profile-member-form_container"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <Field
                className="rainbow-m-bottom_large"
                component={Input}
                name="displayName"
                label="Member name"
                required
                type="text"
                placeholder="Enter the member name"
                icon={<UserIcon />} />

            <Field
                className="rainbow-m-bottom_large"
                component={Input}
                name="email"
                label="Member email"
                required
                type="email"
                autoComplete="off"
                placeholder="Enter the member email"
                icon={<EmailIcon />} />

            <Field
                className="rainbow-m-bottom_x-large"
                component={Input}
                name="password"
                label="Member password"
                required
                type="password"
                autoComplete="new-password"
                placeholder="Enter the member password"
                icon={<LockIcon />} />

            <Field
                className="rainbow-m-bottom_x-large"
                component={CheckboxGroup}
                name="roles"
                label="Select the permissions you want to give the user"
                options={ROLE_OPTIONS}
            />

            <Button
                label="Save"
                variant="brand"
                type="submit"
                isLoading={isLoading} />
        </form>
    );
}

MemberForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
};

MemberForm.defaultProps = {
    handleSubmit: () => {},
    isLoading: false,
};

export default reduxForm({
    form: 'add-member-form',
    touchOnBlur: false,
    validate,
})(MemberForm);
