import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export default function validateMultiPhoneNumbers(value, { required = false }) {
    if (required && (!value || !Array.isArray(value) || value.length === 0)) {
        return 'At least one phone number is required';
    }
    const errors = [];
    const numbers = [];
    value.forEach((item, index) => {
        try {
            const number = phoneUtil.parseAndKeepRawInput(item[0].phone, item[0].isoCode);
            if (!phoneUtil.isValidNumber(number)) {
                errors[index] = {
                    phone: 'Phone number is invalid.',
                };
            } else {
                const exists = numbers.some((n) => {
                    const matchType = phoneUtil.isNumberMatch(n, number);
                    return matchType === PhoneNumberUtil.MatchType.EXACT_MATCH
                    || matchType === PhoneNumberUtil.MatchType.NSN_MATCH;
                });
                if (exists) {
                    errors[index] = {
                        phone: 'Phone number already exists.',
                    };
                }
                numbers.push(number);
            }
        } catch (e) {
            errors[index] = {
                phone: e.message,
            };
        }
    });
    return errors.length > 0 ? errors : '';
}
