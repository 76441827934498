import 'jspdf-autotable';
import generatePDF from './generate-pdf';
import { get12HourTime, getFormattedPhoneNumber } from '../../../helpers';
import { showErrorMessage } from '../app';
import listMemberPhoneNumbers from '../../../helpers/listMemberPhoneNumbers';

function getMemberName(firstName, lastName) {
    if (firstName && lastName) {
        return `${lastName}, ${firstName}`;
    }
    if (!firstName && !lastName) {
        return '';
    }
    return firstName || lastName;
}

function getSortedDataByDriver(data) {
    return [...data].sort((a, b) => {
        const aDriver = a.data
            && a.data.patient
            && a.data.patient.data
            && a.data.patient.data.driver;
        const bDriver = b.data
            && b.data.patient
            && b.data.patient.data
            && b.data.patient.data.driver;
        const aPatientLastName = a.data
            && a.data.patient
            && a.data.patient.data
            && a.data.patient.data.lastName
            && a.data.patient.data.lastName.trim().toLowerCase();
        const bPatientLastName = b.data
            && b.data.patient
            && b.data.patient.data
            && b.data.patient.data.lastName
            && b.data.patient.data.lastName.trim().toLowerCase();
        const aPatientFirstName = a.data
            && a.data.patient
            && a.data.patient.data
            && a.data.patient.data.firstName
            && a.data.patient.data.firstName.trim().toLowerCase();
        const bPatientFirstName = b.data
            && b.data.patient
            && b.data.patient.data
            && b.data.patient.data.firstName
            && b.data.patient.data.firstName.trim().toLowerCase();
        if (String(aDriver) === String(bDriver)) {
            const aName = getMemberName(aPatientFirstName, aPatientLastName);
            const bName = getMemberName(bPatientFirstName, bPatientLastName);
            return aName > bName ? 1 : -1;
        }
        return String(aDriver) > String(bDriver) ? 1 : -1;
    });
}

function getMemberAddress(address) {
    return address || '';
}

function getMemberPhone(phone) {
    return phone ? `\n${getFormattedPhoneNumber(phone)}` : '';
}

function getBody(data) {
    const sortedData = getSortedDataByDriver(data);
    return sortedData.map((item) => {
        if (item.data) {
            const {
                patient,
                time,
                address: dropOffLocation,
                comments,
                status,
            } = item.data;

            let array = [];
            if (patient && patient.data) {
                const {
                    driver,
                    lastName,
                    firstName,
                    address,
                    phone,
                    phoneNumbers,
                } = patient.data;

                const patientInfo = Array.isArray(phoneNumbers) && phoneNumbers.length > 0
                    ? `${getMemberName(firstName, lastName)}\n${listMemberPhoneNumbers(phoneNumbers)}`
                    // this is for supporting the legacy phone prop
                    : `${getMemberName(firstName, lastName)}${getMemberPhone(phone)}`;
                array = [
                    get12HourTime(time) || undefined,
                    driver,
                    patientInfo,
                    getMemberAddress(address),
                    dropOffLocation,
                    comments,
                    status,
                ];
            } else {
                array = [
                    get12HourTime(time) || undefined,
                    undefined,
                    undefined,
                    undefined,
                    dropOffLocation,
                    comments,
                    status,
                ];
            }

            return array;
        }
        return [];
    });
}

export default function generateReport(data, formattedDate) {
    return (dispatch) => {
        if (data && data.length) {
            try {
                const body = getBody(data);
                return generatePDF(body, formattedDate);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                return dispatch(showErrorMessage('There was a problem generating the pdf content. Please contact with support.'));
            }
        }
        return dispatch(showErrorMessage('There are not appointments to create the report.'));
    };
}
