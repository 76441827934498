import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    ActivityTimeline,
    TimelineMarker,
} from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { FSCollection } from 'react-rainbow-firebase';
import {
    PencilCircleIcon,
    CalendarCircleIcon,
} from '../../../../../components/icons';
import FormattedRelative from '../../../../../components/formatted-relative';

function Markers({ items }) {
    return items.map(({ data }, index) => {
        const {
            createdAt,
            createdBy: { displayName, email },
            updatedFields,
        } = data;
        const key = `marker-${index}`;
        const isFirst = index === 0 && !updatedFields;

        if (isFirst) {
            return (
                <TimelineMarker
                    key={key}
                    icon={<CalendarCircleIcon />}
                    label="Created by:"
                    description={(
                        <span>
                            <RenderIf isTrue={!!displayName}>
                                {`${displayName} • `}
                            </RenderIf>
                            {email}
                        </span>
                    )}
                    datetime={
                        <FormattedRelative value={createdAt.toDate()} />
                    }
                />
            );
        }
        return (
            <TimelineMarker
                key={key}
                icon={<PencilCircleIcon />}
                label="Updated by:"
                description={(
                    <span>
                        <RenderIf isTrue={!!displayName}>
                            {`${displayName} • `}
                        </RenderIf>
                        {email}
                    </span>
                )}
                datetime={
                    <FormattedRelative value={createdAt.toDate()} />
                }
            />
        );
    });
}

function Timeline({ data }) {
    return (
        <Card
            title="Timeline"
            className="rainbow-m-bottom_medium"
        >
            <div className="scheduler-patient-details_card-content">
                <ActivityTimeline className="scheduler-patient-details_timeline-container">
                    <Markers items={data} />
                </ActivityTimeline>
            </div>
        </Card>
    );
}

Timeline.propTypes = {
    data: PropTypes.array.isRequired,
};

const query = [{ orderBy: 'createdAt' }];

export default function TimelineWrapper({ historyRef }) {
    return (
        <FSCollection
            collectionRef={historyRef}
            component={Timeline}
            query={query} />
    );
}

TimelineWrapper.propTypes = {
    historyRef: PropTypes.string.isRequired,
};
