import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Picklist,
    Option,
    DatePickerModal,
} from 'react-rainbow-components';
import getCurrentDay from './getCurrentDay';
import getCurrentWeek from './getCurrentWeek';
import getCurrentMonth from './getCurrentMonth';

const getFormattedDates = ({ name }) => {
    if (name === 'today') {
        return getCurrentDay();
    }
    if (name === 'this-week') {
        return getCurrentWeek();
    }
    if (name === 'this-month') {
        return getCurrentMonth();
    }
    return null;
};

const formatDates = (dates) => {
    if (dates) {
        const startDay = new Intl.DateTimeFormat().format(dates[0]);
        if (dates.length > 1) {
            const endDay = new Intl.DateTimeFormat().format(dates[1]);
            return `${startDay} - ${endDay}`;
        }
        return startDay;
    }
    return '';
};

const initialValue = {
    name: 'all-time',
    label: 'All Time',
};

const DatePicker = (props) => {
    const {
        onChange,
        className,
    } = props;
    const [value, setValue] = useState(initialValue);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [dateValue, setDateValue] = useState();

    const handleSelection = (newValue) => {
        const { name } = newValue;
        if (name === 'custom') {
            if (!dateValue || dateValue.length !== 2) {
                setDateValue();
            }
            setIsDatePickerOpen(true);
        } else {
            setDateValue();
            onChange(getFormattedDates(newValue));
            setValue(newValue);
        }
    };

    const handleCustomDateSelection = (newDates) => {
        setDateValue(newDates);
        if (newDates.length === 2) {
            onChange(newDates);
            setIsDatePickerOpen(false);
            setValue({
                name: 'custom',
                label: formatDates(newDates),
            });
        }
    };

    return (
        <>
            <Picklist
                className={className}
                name="dateRange"
                onChange={handleSelection}
                value={value}
            >
                <Option name="all-time" label="All Time" />
                <Option name="today" label="Today" />
                <Option name="this-week" label="This Week" />
                <Option name="this-month" label="This Month" />
                <Option name="custom" label="Custom" />
            </Picklist>
            <DatePickerModal
                isOpen={isDatePickerOpen}
                selectionType="range"
                variant="double"
                value={dateValue}
                onChange={handleCustomDateSelection}
                onRequestClose={() => setIsDatePickerOpen(false)}
            />
        </>
    );
};

DatePicker.propTypes = {
    onChange: PropTypes.func,
    className: PropTypes.string,
};

DatePicker.defaultProps = {
    onChange: () => {},
    className: undefined,
};

export default DatePicker;
