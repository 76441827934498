import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    Input,
    Textarea,
    Select,
    DatePicker,
    TimePicker,
} from 'react-rainbow-components';
import { FSLookup } from 'react-rainbow-firebase';
import { Field, reduxForm } from 'redux-form';
import { LocationMarkerIcon } from '../../../../components/icons';
import { getFullPatientName, getFormattedDate } from '../../../../helpers';
import { getCurrentUser } from '../../../../redux/services/firebase';
import validate from './validate';
import './styles.css';

const query = [{
    where: 'enable',
    eq: true,
}];

function getOptions(patient) {
    const { firstName, lastName } = patient.data;
    return {
        label: getFullPatientName(firstName, lastName),
    };
}

const messages = defineMessages({
    datePlaceholder: {
        id: 'select.date.placeholder',
        defaultValue: 'Select Date',
    },
    patientPlaceholder: {
        id: 'patient.placeholder',
        defaultValue: 'Enter Patient',
    },
    addressPlaceholder: {
        id: 'address.placeholder',
        defaultValue: 'Enter Address',
    },
    commentPlaceholder: {
        id: 'comment.placeholder',
        defaultValue: 'Enter Comment',
    },
    statusNotCofirmed: {
        id: 'status.not.confirmed',
        defaultValue: 'Not confirmed',
    },
    statusCofirmed: {
        id: 'status.confirmed',
        defaultValue: 'Confirmed',
    },
    statusCanceled: {
        id: 'status.canceled',
        defaultValue: 'Canceled',
    },
});

function AppointmentForm(props) {
    const {
        className,
        handleSubmit,
        onSubmit,
        intl,
    } = props;

    const options = [
        { value: 'not confirmed', label: intl.formatMessage(messages.statusNotCofirmed) },
        { value: 'confirmed', label: intl.formatMessage(messages.statusCofirmed) },
        { value: 'canceled', label: intl.formatMessage(messages.statusCanceled) },
        { value: 'own car', label: 'Own Car' },
        { value: 'no show', label: 'No Show' },
        { value: 'sts', label: 'STS' },
        { value: 'kept app', label: 'Kept App' },
    ];

    const submit = (values) => {
        const {
            date,
            patient: { data, id },
            ...rest
        } = values;
        onSubmit({
            ...rest,
            date: getFormattedDate(date),
            patient: { data, id },
            userId: getCurrentUser().uid,
            enable: true,
        });
    };

    const getContainerClassNames = () => classnames('scheduler-schedule_appointment-form-container', className);

    return (
        <form
            id="appointment-form"
            noValidate
            className={getContainerClassNames()}
            onSubmit={handleSubmit(submit)}
        >
            <div className="rainbow-position-align_start rainbow-justify_spread rainbow-m-bottom_large">
                <Field
                    className="scheduler-schedule_appointment-form-input"
                    label={<FormattedMessage id="select.date" defaultMessage="Select appointment date" />}
                    placeholder={intl.formatMessage(messages.datePlaceholder)}
                    formatStyle="large"
                    required
                    name="date"
                    component={DatePicker} />

                <Field
                    className="scheduler-schedule_appointment-form-input"
                    label={<FormattedMessage id="select.time" defaultMessage="Select appointment time" />}
                    required
                    name="time"
                    component={TimePicker} />

            </div>

            <Field
                className="rainbow-m-bottom_large"
                label={<FormattedMessage id="patient" defaultMessage="Patient" />}
                placeholder={intl.formatMessage(messages.patientPlaceholder)}
                required
                name="patient"
                component={FSLookup}
                collectionRef="patients"
                query={query}
                optionsMapFn={getOptions}
            />

            <div className="rainbow-position-align_start rainbow-justify_spread rainbow-m-bottom_large">
                <Field
                    className="scheduler-schedule_appointment-form-input"
                    label={<FormattedMessage id="address" defaultMessage="Address" />}
                    placeholder={intl.formatMessage(messages.addressPlaceholder)}
                    required
                    icon={<LocationMarkerIcon />}
                    name="address"
                    component={Input} />

                <Field
                    className="scheduler-schedule_appointment-form-input"
                    label={<FormattedMessage id="status" defaultMessage="Status" />}
                    options={options}
                    name="status"
                    component={Select} />
            </div>

            <Field
                label={<FormattedMessage id="comment" defaultMessage="Comment" />}
                placeholder={intl.formatMessage(messages.commentPlaceholder)}
                name="comments"
                component={Textarea} />
        </form>
    );
}

AppointmentForm.propTypes = {
    className: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

AppointmentForm.defaultProps = {
    className: undefined,
    onSubmit: () => {},
};

export default reduxForm({
    form: 'appointment-form',
    validate,
    touchOnBlur: false,
})(injectIntl(AppointmentForm));
