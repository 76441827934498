import React from 'react';
import PropTypes from 'prop-types';
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Input from 'react-rainbow-components/components/Input';
import Button from 'react-rainbow-components/components/Button';
import {
    EmailIcon,
    LockIcon,
} from '../../../../components/icons';
import validate from './validate';

const translations = defineMessages({
    emailPlaceholder: {
        id: 'form.email.placeholder',
        defaultValue: 'Enter your email address',
    },
    passwordPlaceholder: {
        id: 'form.password.placeholder',
        defaultValue: 'Enter your password',
    },
});

function ReauthenticateForm(props) {
    const {
        intl,
        handleSubmit,
        onSubmit,
        onCancel,
        isLoading,
    } = props;
    return (
        <div>
            <span className="scheduler-profile_reauthenticate-form_title">
                <FormattedMessage
                    id="profile.reauthenticate.message"
                    defaultMessage="This operation is sensitive and requires recent login, please, reauthenticate" />
            </span>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Field
                    component={Input}
                    name="email"
                    label={<FormattedMessage id="form.email.label" defaultMessage="Email address" />}
                    required
                    placeholder={intl.formatMessage(translations.emailPlaceholder)}
                    type="email"
                    icon={<EmailIcon />} />
                <Field
                    className="rainbow-m-top_medium"
                    component={Input}
                    name="password"
                    label={<FormattedMessage id="form.password.label" defaultMessage="Password" />}
                    placeholder={intl.formatMessage(translations.passwordPlaceholder)}
                    type="password"
                    required
                    icon={<LockIcon />} />
                <div className="rainbow-flex rainbow-justify_end rainbow-m-top_medium">
                    <Button
                        className="rainbow-m-right_large"
                        variant="base"
                        label="Cancel"
                        onClick={onCancel}
                    />
                    <Button
                        type="submit"
                        variant="brand"
                        isLoading={isLoading}
                        label={(
                            <FormattedMessage
                                id="profile.reauthenticate.action"
                                defaultMessage="Authenticate" />
                        )} />

                </div>
            </form>
        </div>
    );
}

ReauthenticateForm.propTypes = {
    intl: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default reduxForm({
    form: 'reauthenticate',
    touchOnBlur: false,
    validate,
})(injectIntl(ReauthenticateForm));
