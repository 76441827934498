import React from 'react';
import { FSCollection } from 'react-rainbow-firebase';
import PatientsTable from './patients-table';

const query = [{
    where: 'enable',
    eq: true,
}];

export default function Patients() {
    return (
        <FSCollection
            component={PatientsTable}
            collectionRef="patients"
            query={query}
            cacheStrategy="subscribeOnce"
        />
    );
}
