import React from 'react';
import PropTypes from 'prop-types';
import { FSDoc } from 'react-rainbow-firebase';
import { getFullPatientName } from '../../../../../helpers';

function Header({ doc, label }) {
    if (doc && doc.data && doc.data.enable) {
        const {
            firstName,
            lastName,
        } = doc.data;

        return `${getFullPatientName(firstName, lastName)} ${label}`;
    }
    return label;
}

Header.propTypes = {
    doc: PropTypes.object,
};

Header.defaultProps = {
    doc: null,
};

export default function TableHeader({ patientId, label }) {
    return (
        <FSDoc
            component={Header}
            docRef={`patients/${patientId}`}
            label={label}
        />
    );
}

TableHeader.propTypes = {
    patientId: PropTypes.string.isRequired,
    label: PropTypes.string,
};

TableHeader.defaultProps = {
    label: 'Appointments',
};
