import React from 'react';
import PropTypes from 'prop-types';

export default function Checkmark(props) {
    const { className } = props;
    return (
        <svg className={className} width="12px" height="10px" viewBox="0 0 12 10">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="badge-status/success" transform="translate(-19.000000, -6.000000)">
                    <g transform="translate(17.000000, 3.000000)">
                        <polygon id="Shape" points="0 0 16 0 16 16 0 16" />
                        <path d="M13.9221908,4.4795222 C13.6689893,3.82661774 13.1530478,3.92967597 12.5939666,4.04045164 C12.2600788,4.11003871 10.7750343,4.54473027 8.42533265,7.02237679 C7.45024655,8.05066122 6.80701057,8.87044459 6.38350497,9.4956443 C6.12479715,9.1780151 5.8284126,8.8381874 5.51715679,8.52680153 C4.55993355,7.56983842 3.4924549,6.91264166 3.447581,6.88502362 C2.99962237,6.61118441 2.41483085,6.75209281 2.14021123,7.19970458 C1.86593845,7.64731636 2.00641328,8.23306172 2.45367821,8.50794148 C2.46282642,8.51370789 3.3795114,9.08046313 4.17141658,9.8725851 C4.97901679,10.680272 5.71074325,11.7725505 5.71794042,11.7831295 C5.89609199,12.0509855 6.19520799,12.2072855 6.50988896,12.2072855 C6.56391107,12.2072855 6.61845346,12.202733 6.67273571,12.1933247 C7.04412688,12.1288103 7.34246247,11.8511124 7.43342426,11.4852275 C7.43572215,11.4764261 7.81305315,10.4335739 9.80632164,8.33204445 C11.4117669,6.63884581 12.4824105,6.10070581 12.8533681,5.95142962 C12.8569234,5.95082263 12.8594814,5.9505625 12.864294,5.9495653 C12.8625163,5.94999886 12.8763037,5.94362547 12.8986756,5.93356678 C12.9623662,5.90967738 12.9964877,5.90052918 12.9984387,5.90005226 C12.9801423,5.90460468 12.9706473,5.90573195 12.9706473,5.90573195 L12.9700403,5.90243686 C13.1416884,5.82756031 13.4630462,5.68929665 13.47007,5.68634842 C13.9539277,5.48755919 14.1112682,4.96710861 13.9221908,4.4795222 Z" fill="#1DE9B6" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Checkmark.propTypes = {
    className: PropTypes.string,
};

Checkmark.defaultProps = {
    className: undefined,
};
