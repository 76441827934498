import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

export default function RecordColumnSection(props) {
    const {
        className,
        children,
    } = props;

    const getContainerClassNames = () => classnames('scheduler-record-column-section_container', className);

    return (
        <section className={getContainerClassNames()}>
            {children}
        </section>
    );
}

RecordColumnSection.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

RecordColumnSection.defaultProps = {
    className: undefined,
    children: undefined,
};
