import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Button,
    ActivityTimeline,
    TimelineMarker,
} from 'react-rainbow-components';
import DateTime from '../../../../../components/date-time';
import {
    PencilCircleIcon,
} from '../../../../../components/icons';
import TableHeader from '../appointments/table-header';
import AddNote from './addNote';


const Notes = (props) => {
    const {
        patientId,
        data,
        addDoc,
    } = props;
    const [isOpenAddNote, setOpenAddNote] = useState(false);

    return (
        <article className="scheduler-patients-details-appointments_container">
            <Card className="scheduler-patients-details-appointments_table-card">
                <header className="scheduler-patients-details-appointments_table-header">
                    <div className="scheduler-patients-details-appointments_table-header-content">
                        <div>
                            <h1 className="scheduler-patients-details-appointments_table-title">
                                <TableHeader patientId={patientId} label="Notes" />
                            </h1>
                            <h2 className="scheduler-patients-details-appointments_table-subtitle">
                                Amount
                                {' • '}
                                {data.length}
                            </h2>
                        </div>
                        <div className="scheduler-patients-details-appointments_actions-container">
                            <Button label="Add Note" variant="brand" onClick={() => setOpenAddNote(true)} />
                        </div>
                    </div>
                </header>
                <div className="scheduler-patient-details_timeline-content-container">
                    <ActivityTimeline className="scheduler-patient-details_timeline-container">
                        {
                            data.map(({ data, id }) => (
                                <TimelineMarker
                                    key={id}
                                    icon={<PencilCircleIcon />}
                                    label={<div className="scheduler-patient-details_timeline-note-label">{`Created by: ${data.createdBy.displayName}`}</div>}
                                    description={data.createdBy.email}
                                    datetime={
                                        <DateTime value={data.createdAt.toDate()} />
                                    }
                                >
                                    <p className="scheduler-patient-details_timeline-note">{data.text}</p>
                                </TimelineMarker>
                            ))
                        }
                    </ActivityTimeline>
                </div>
            </Card>
            <AddNote
                isOpen={isOpenAddNote}
                onSubmit={addDoc}
                onRequestClose={() => setOpenAddNote(false)}
            />
        </article>
    );
};

Notes.propTypes = {
    patientId: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    addDoc: PropTypes.func.isRequired,
};

export default Notes;
