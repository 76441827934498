import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Table,
    Column,
    Button,
} from 'react-rainbow-components';
import PhoneCell from '../../../../components/phone-cell';
import DateCell from '../../../../components/date-cell';
import { bindMethods, getFullPatientName } from '../../../../helpers';

function PatientName({ row }) {
    const { firstName, lastName } = row.data;
    return getFullPatientName(firstName, lastName);
}

export default class RegularPatientsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: props.data.map(item => item.id),
            selectedPatients: props.data,
            data: props.data,
        };
        bindMethods([
            'handleRowSelection',
            'generateAppointments',
        ], this);
    }

    componentDidUpdate({ data: prevData }) {
        const { data } = this.props;
        const isDataLoaded = prevData.length === 0 && data.length > 0;
        if (isDataLoaded) {
            this.setData();
        }
    }

    setData() {
        const { data } = this.props;
        this.setState({
            selectedRows: data.map(item => item.id),
            selectedPatients: data,
            data,
        });
    }

    generateAppointments() {
        const { onGenerateAppointments } = this.props;
        const { selectedPatients } = this.state;
        onGenerateAppointments(selectedPatients);
    }

    handleRowSelection(selectedPatients) {
        this.setState({
            selectedPatients,
        });
    }

    render() {
        const { isLoading, onCloseModal } = this.props;
        const { data, selectedRows, selectedPatients } = this.state;
        const isGenerateAppointmentsButtonDisabled = selectedPatients.length === 0;

        return (
            <section className="scheduler-daily-schedule_regular-patients-container">
                <Table
                    className="scheduler-daily-schedule_regular-patients-table"
                    isLoading={isLoading}
                    data={data}
                    showCheckboxColumn
                    keyField="id"
                    onRowSelection={this.handleRowSelection}
                    selectedRows={selectedRows}>

                    <Column
                        header={<FormattedMessage id="patient" defaultMessage="Patient" />}
                        field="data.firstName"
                        component={PatientName} />
                    <Column
                        header={<FormattedMessage id="patients.member.id" defaultMessage="Member Id" />}
                        field="id" />
                    <Column
                        header={<FormattedMessage id="patients.phone.number" defaultMessage="Phone Number" />}
                        field="data.phone"
                        component={PhoneCell} />
                    <Column
                        header="DOB"
                        field="data.dob"
                        component={DateCell} />
                </Table>
                <footer className="rainbow-flex rainbow-justify_end scheduler-daily-schedule_regular-patients-table_footer">
                    <Button
                        className="rainbow-m-right_medium"
                        label={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
                        onClick={onCloseModal} />

                    <Button
                        id="generate-appointments-button"
                        className="rainbow-m-right_large"
                        title="Generate Appointments"
                        label={<FormattedMessage id="patients.regular.generate.appointments" defaultMessage="Generate Appointments" />}
                        disabled={isGenerateAppointmentsButtonDisabled}
                        variant="brand"
                        onClick={this.generateAppointments} />

                </footer>
            </section>
        );
    }
}

RegularPatientsTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    onGenerateAppointments: PropTypes.func,
    onCloseModal: PropTypes.func,
};

RegularPatientsTable.defaultProps = {
    onCloseModal: () => {},
    onGenerateAppointments: () => {},
};
