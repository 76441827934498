import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formValueSelector } from 'redux-form';
import showProfileModal from './show-profile-modal';
import showErrorMessage from '../app/show-error-message';
import reauthenticate from './reauthenticate';
import ReauthenticateForm from '../../../pages/auth/profile/reauthenticate-form';

export default function handleUpdateProfileError(error) {
    return (dispatch, getState) => {
        const { code } = error;
        if (code === 'auth/requires-recent-login') {
            const selector = formValueSelector('edit-profile');
            const currentValues = {
                email: selector(getState(), 'email'),
                password: selector(getState(), 'password'),
            };
            return dispatch(showProfileModal({
                modalProps: {
                    title: (
                        <span className="rainbow-modal_header">
                            <FormattedMessage id="profile.reauthenticate.title" defaultValue="Renew your credentials" />
                        </span>
                    ),
                    size: 'small',
                    children: ReauthenticateForm,
                },
                formProps: {
                    onSubmit: credentials => dispatch(reauthenticate(credentials, currentValues)),
                },
            }));
        }
        return dispatch(showErrorMessage(error));
    };
}
