import {
    get12HourTime,
    getFullPatientName,
    filterCollection,
} from '../../../../../helpers';

function mapValuesToString(data) {
    const {
        address, patient, status, time, comments,
    } = data;
    let string = '';

    if (patient) {
        const { firstName, lastName, driver } = patient.data;
        string = `${getFullPatientName(firstName, lastName)},${driver}`;
    }

    return `${string},${address},${status},${get12HourTime(time)},${comments}`;
}

export default function filter(query, collection) {
    return filterCollection(query, collection, mapValuesToString);
}
