import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    injectIntl,
    defineMessages,
} from 'react-intl';
import { Badge, ButtonIcon } from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import {
    AlertIcon,
    CheckmarkIcon,
    InprogressIcon,
    OwnCarIcon,
    NoShowIcon,
    StsIcon,
    PencilIcon,
} from '../icons';
import { bindMethods } from '../../helpers';
import './styles.css';

const messages = defineMessages({
    statusNotConfirmed: {
        id: 'status.not.confirmed',
        defaultValue: 'Not Confirmed',
    },
    statusConfirmed: {
        id: 'status.confirmed',
        defaultValue: 'Confirmed',
    },
    statusCanceled: {
        id: 'status.canceled',
        defaultValue: 'Canceled',
    },
    statusSts: {
        id: 'status.sts',
        defaultValue: 'Sts',
    },
    statusOwnCar: {
        id: 'status.own.car',
        defaultValue: 'Own Car',
    },
    statusNoShow: {
        id: 'status.no.show',
        defaultValue: 'No Show',
    },
    statusKeptApp: {
        id: 'status.no.kept.app',
        defaultValue: 'Kept App',
    },
});

const iconMap = {
    'not confirmed': <InprogressIcon className="shedule-status-badge_icon rainbow-m-right_xx-small" />,
    confirmed: <CheckmarkIcon className="shedule-status-badge_icon rainbow-m-right_xx-small" />,
    canceled: <AlertIcon className="shedule-status-badge_icon rainbow-m-right_x-small" />,
    'own car': <OwnCarIcon className="shedule-status-badge_icon-own-car rainbow-m-right_x-small" />,
    'no show': <NoShowIcon className="shedule-status-badge_icon-no-show rainbow-m-right_x-small" />,
    sts: <StsIcon className="shedule-status-badge_icon-sts rainbow-m-right_xx-small" />,
};

class StatusSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditPencil: false,
            isEditing: false,
        };
        this.selectRef = React.createRef();
        const { intl } = props;
        this.valueMap = {
            sts: intl.formatMessage(messages.statusSts),
            confirmed: intl.formatMessage(messages.statusConfirmed),
            canceled: intl.formatMessage(messages.statusCanceled),
            'not confirmed': intl.formatMessage(messages.statusNotConfirmed),
            'own car': intl.formatMessage(messages.statusOwnCar),
            'no show': intl.formatMessage(messages.statusNoShow),
            'kept app': intl.formatMessage(messages.statusKeptApp),
        };
        bindMethods([
            'showSelect',
            'showBadge',
            'handleChange',
            'handleMouseEnter',
            'handleMouseLeave',
        ], this);
    }

    getClassNames() {
        const { value } = this.props;
        return classnames({
            'shedule-status-badge_verified': value === 'confirmed',
            'shedule-status-badge_expired': value === 'canceled',
            'shedule-status-badge_processing': value === 'not confirmed',
            'shedule-status-badge_own-car': value === 'own car',
            'shedule-status-badge_no-show': value === 'no show',
            'shedule-status-badge_kept-app': value === 'kept app',
            'shedule-status-badge_sts': value === 'sts',
        });
    }

    showSelect() {
        this.setState({
            showEditPencil: false,
            isEditing: true,
        });
        setTimeout(() => this.selectRef.current.focus(), 0);
    }

    showBadge() {
        this.setState({
            showEditPencil: false,
            isEditing: false,
        });
    }

    handleChange(event) {
        const { value } = event.target;
        const { row, onEdit } = this.props;
        this.setState({
            showEditPencil: false,
            isEditing: false,
        });
        onEdit(row.id, {
            status: value,
        });
    }

    handleMouseEnter() {
        const { isEditing } = this.state;
        if (!isEditing) {
            this.setState({ showEditPencil: true });
        }
    }

    handleMouseLeave() {
        const { isEditing } = this.state;
        if (!isEditing) {
            this.setState({ showEditPencil: false });
        }
    }

    render() {
        const { value } = this.props;
        const {
            showEditPencil,
            isEditing,
        } = this.state;
        const icon = iconMap[value];
        const label = this.valueMap[value];

        return (
            <div
                className="shedule-status-badge_container"
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <RenderIf isTrue={!isEditing}>
                    <RenderIf isTrue={!!label}>
                        <Badge className={this.getClassNames()} title={label} variant="lightest">
                            {icon}
                            {label}
                        </Badge>
                    </RenderIf>
                    <RenderIf isTrue={showEditPencil}>
                        <ButtonIcon
                            className="shedule-status-badge_hover-button"
                            variant="border-filled"
                            icon={<PencilIcon className="shedule-status-badge_hover-button-icon" />}
                            size="small"
                            onClick={this.showSelect}
                        />
                    </RenderIf>
                </RenderIf>
                <RenderIf isTrue={isEditing}>
                    <div className="shedule-status-badge_select-container">
                        <select
                            className="shedule-status-badge_select"
                            onChange={this.handleChange}
                            onBlur={this.showBadge}
                            value={value}
                            ref={this.selectRef}
                        >
                            <option value="confirmed">Confirmed</option>
                            <option value="not confirmed">Not Confirmed </option>
                            <option value="canceled">Canceled</option>
                            <option value="own car">Own Car</option>
                            <option value="sts">STS</option>
                            <option value="no show">No Show</option>
                            <option value="kept app">Kept App</option>
                        </select>
                    </div>
                </RenderIf>
            </div>
        );
    }
}

StatusSelect.propTypes = {
    intl: PropTypes.object.isRequired,
    value: PropTypes.string,
    row: PropTypes.object,
    onEdit: PropTypes.func,
};

StatusSelect.defaultProps = {
    value: undefined,
    row: {},
    onEdit: () => {},
};

export default injectIntl(StatusSelect);
