import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-rainbow-components';
import { goBack } from '../../history';
import { BackIcon } from '../icons';
import './styles.css';

const BACK = <FormattedMessage id="back" defaultMessage="Back" />;

export default function BackButton(props) {
    const {
        className,
        onClick,
    } = props;

    const getContainerClassNames = () => classnames('scheduler-back-button', className);

    return (
        <Button
            id="go-back-button"
            variant="base"
            className={getContainerClassNames()}
            onClick={typeof onClick === 'function' ? onClick : goBack}>

            <BackIcon className="scheduler-back-button_icon" />
            {BACK}
        </Button>
    );
}

BackButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

BackButton.defaultProps = {
    className: undefined,
    onClick: undefined,
};
