import { getCurrentUser } from '../redux/services/firebase';

export default function getDisableAppointments(payload) {
    return payload.map(({ id }) => ({
        id,
        data: {
            userId: getCurrentUser().uid,
            enable: false,
        },
    }));
}
