import getFormattedPhoneNumber from './get-formatted-phone-number';

export default function getRenderedPhone(legacyPhone, phoneNumbers) {
    const isLegacy = !Array.isArray(phoneNumbers) || !phoneNumbers.length;
    const phoneNumber = isLegacy
        ? legacyPhone
        : phoneNumbers[0];

    if (!phoneNumber) {
        return '';
    }
    const renderedPhone = isLegacy
        ? getFormattedPhoneNumber(phoneNumber)
        : phoneNumber.nationalFormat;

    return renderedPhone;
}
