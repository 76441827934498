import React from 'react';
import PropTypes from 'prop-types';
import { ButtonMenu, MenuItem } from 'react-rainbow-components';
import {
    MoreIcon, TrashIcon, LockBorderIcon, DisableIcon, PersonIcon,
} from '../../../components/icons';
import MemberCard from '../../../components/member-card';

function getRoles(claims) {
    const roles = new Set();
    if (claims) {
        if (claims.admin === true) {
            roles.add('admin');
        }
        if (Array.isArray(claims.roles)) {
            claims.roles.forEach((role) => {
                roles.add(role);
            });
        }
    }
    return roles;
}

export default function MembersCards(props) {
    const {
        members, onRemove, onAssignPermissions, onDisable, onEnable,
    } = props;
    return members.map((user) => {
        const {
            photoURL,
            displayName,
            customClaims,
            email,
            uid,
            disabled,
        } = user;
        const roles = getRoles(customClaims);
        const isAdmin = roles.has('admin');
        const Icon = disabled ? PersonIcon : DisableIcon;
        return (
            <MemberCard
                key={uid}
                avatarSrc={photoURL}
                name={displayName}
                roles={getRoles(customClaims)}
                email={email}
                disabled={disabled}
                actions={(
                    <ButtonMenu
                        menuAlignment="right"
                        menuSize="x-small"
                        buttonVariant="base"
                        icon={<MoreIcon className="scheduler-profile_member-action-more-icon" />}
                        disabled={isAdmin}
                    >
                        <MenuItem
                            label="Assign Permissions"
                            icon={<LockBorderIcon className="scheduler-profile_member-action-icon" />}
                            onClick={() => onAssignPermissions(user)}
                        />
                        <MenuItem
                            label={disabled ? 'Enable Member' : 'Disable Member'}
                            icon={<Icon className="scheduler-profile_member-action-icon" />}
                            onClick={disabled ? () => onEnable(uid) : () => onDisable(uid)}
                        />
                        <MenuItem
                            label="Remove"
                            icon={<TrashIcon className="scheduler-profile_member-action-icon" />}
                            onClick={() => onRemove(uid)}
                        />
                    </ButtonMenu>
                )} />
        );
    });
}

MembersCards.propTypes = {
    members: PropTypes.array,
    onRemove: PropTypes.func,
    onAssignPermissions: PropTypes.func,
    onDisable: PropTypes.func,
    onEnable: PropTypes.func,
};

MembersCards.defaultProps = {
    members: [],
    onRemove: () => {},
    onAssignPermissions: () => {},
    onDisable: () => {},
    onEnable: () => {},
};
