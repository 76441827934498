import {
    SHOW_CONFIRMATION_MODAL,
    HIDE_CONFIRMATION_MODAL,
} from '../../actions/confirmation-modal';

const initialState = {
    isOpen: false,
    collectionName: '',
    itemsAmount: 1,
    onAccept: () => {},
};

function showModal(state, { props }) {
    return {
        ...state,
        ...props,
        isOpen: true,
    };
}

export default function confirmationModal(state = initialState, action) {
    switch (action.type) {
        case SHOW_CONFIRMATION_MODAL:
            return showModal(state, action);

        case HIDE_CONFIRMATION_MODAL:
            return initialState;

        default:
            return state;
    }
}
