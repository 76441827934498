/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withCurrentUserClaims } from 'react-rainbow-firebase';
import {
    Avatar,
    Card,
    Input,
    ButtonIcon,
    Button,
    Modal,
} from 'react-rainbow-components';
import { logoutUser } from '../../../redux/actions/authentication';
import {
    showProfileModal,
    hideProfileModal,
    updateProfileName,
    updateProfileEmail,
    updateProfilePassword,
} from '../../../redux/actions/profile';
import { bindMethods } from '../../../helpers';
import {
    PencilIcon,
    PersonIcon,
    UserIcon,
    EmailIcon,
    LockIcon,
} from '../../../components/icons';
import EditProfileForm from './edit-profile-form';
import './styles.css';

const translations = defineMessages({
    displayNamePlaceholder: {
        id: 'form.displayName.placeholder',
        defaultValue: 'Enter your name',
    },
    emailPlaceholder: {
        id: 'form.email.placeholder',
        defaultValue: 'Enter your email address',
    },
    passwordPlaceholder: {
        id: 'form.password.placeholder',
        defaultValue: 'Enter new password',
    },
});

class Profile extends Component {
    constructor(props) {
        super(props);
        bindMethods([
            'openEditNameModal',
            'openEditEmailModal',
            'openEditPasswordModal',
        ], this);
    }

    openEditNameModal() {
        const { intl, showProfileModal, updateProfileName } = this.props;
        showProfileModal({
            modalProps: {
                title: 'Enter new name',
                size: 'small',
                children: EditProfileForm,
                id: 'edit-profile-modal',
            },
            formProps: {
                label: <FormattedMessage id="form.displayName.label" defaultMessage="Name" />,
                name: 'displayName',
                type: 'text',
                placeholder: intl.formatMessage(translations.displayNamePlaceholder),
                icon: <UserIcon />,
                onSubmit: updateProfileName,
            },
        });
    }

    openEditEmailModal() {
        const { intl, showProfileModal, updateProfileEmail } = this.props;
        showProfileModal({
            modalProps: {
                title: 'Enter new email',
                size: 'small',
                children: EditProfileForm,
                id: 'edit-profile-modal',
            },
            formProps: {
                label: <FormattedMessage id="form.email.label" defaultMessage="Email address" />,
                name: 'email',
                type: 'email',
                placeholder: intl.formatMessage(translations.emailPlaceholder),
                icon: <EmailIcon />,
                onSubmit: updateProfileEmail,
            },
        });
    }

    openEditPasswordModal() {
        const { intl, showProfileModal, updateProfilePassword } = this.props;
        showProfileModal({
            modalProps: {
                title: 'Enter new password',
                size: 'small',
                children: EditProfileForm,
                id: 'edit-profile-modal',
            },
            formProps: {
                label: <FormattedMessage id="form.password.label" defaultMessage="Password" />,
                name: 'password',
                type: 'password',
                placeholder: intl.formatMessage(translations.passwordPlaceholder),
                icon: <LockIcon />,
                onSubmit: updateProfilePassword,
            },
        });
    }

    render() {
        const {
            user,
            logoutUser,
            formProps,
            modalProps,
        } = this.props;
        const { children: ModalChildren, ...restModalProps } = modalProps;

        return (
            <section>
                <div className="scheduler-profile_container">
                    <header data-cy="header-page" className="scheduler-profile_page-header">
                        <h1 className="scheduler-profile_header-title"><FormattedMessage id="profile" defaultMessage="Profile" /></h1>
                        <Button
                            variant="destructive"
                            label={<FormattedMessage id="logout" defaultMessage="Logout" />}
                            onClick={logoutUser} />
                    </header>
                    <div className="scheduler-profile_content">
                        <Card
                            className="scheduler-profile_card-container"
                            title="Personal Information">
                            <article className="scheduler-profile_card-personal-info-container">
                                <div className="scheduler-profile_card-personal-info">
                                    <div data-cy="user-name-container" className="rainbow-flex rainbow-justify_spread rainbow-m-bottom_medium">
                                        <Input
                                            className="scheduler-profile_personal-info-input"
                                            value={user.displayName}
                                            name="displayName"
                                            placeholder="Enter your name"
                                            label={<FormattedMessage id="form.name" defaultMessage="Name" />}
                                            readOnly
                                            icon={<UserIcon />} />
                                        <ButtonIcon
                                            className="scheduler-profile_personal-info-button"
                                            variant="border-filled"
                                            onClick={this.openEditNameModal}
                                            icon={<PencilIcon />} />
                                    </div>
                                    <div data-cy="user-email-container" className="rainbow-flex rainbow-justify_spread rainbow-m-bottom_medium">
                                        <Input
                                            className="scheduler-profile_personal-info-input"
                                            value={user.getEmail()}
                                            name="email"
                                            label={<FormattedMessage id="form.email" defaultMessage="Email" />}
                                            readOnly
                                            icon={<EmailIcon />} />
                                        <ButtonIcon
                                            className="scheduler-profile_personal-info-button"
                                            variant="border-filled"
                                            onClick={this.openEditEmailModal}
                                            icon={<PencilIcon />} />
                                    </div>
                                    <div data-cy="password-container" className="rainbow-flex rainbow-justify_spread rainbow-m-bottom_medium">
                                        <Input
                                            value="Change your password"
                                            className="scheduler-profile_personal-info-input"
                                            name="password"
                                            label={<FormattedMessage id="form.password" defaultMessage="Password" />}
                                            readOnly
                                            icon={<LockIcon />} />
                                        <ButtonIcon
                                            className="scheduler-profile_personal-info-button"
                                            variant="border-filled"
                                            onClick={this.openEditPasswordModal}
                                            icon={<PencilIcon />} />
                                    </div>
                                </div>
                                <div>
                                    <h5 className="scheduler-profile_avatar-label">
                                        <FormattedMessage id="profile.photo" defaultMessage="Profile Photo" />
                                    </h5>
                                    <Avatar
                                        className="scheduler-profile_avatar"
                                        src={user.photoURL}
                                        icon={<PersonIcon className="scheduler-profile_avatar-icon" />}
                                        assistiveText={user.displayName}
                                        title={user.displayName} />
                                </div>
                            </article>
                        </Card>
                    </div>
                </div>
                <Modal {...restModalProps}>
                    <ModalChildren {...formProps} />
                </Modal>
            </section>
        );
    }
}

Profile.propTypes = {
    logoutUser: PropTypes.func,
    user: PropTypes.object,
    updateProfileName: PropTypes.func,
    updateProfileEmail: PropTypes.func,
    updateProfilePassword: PropTypes.func,
    intl: PropTypes.object.isRequired,
    claims: PropTypes.object.isRequired,
    modalProps: PropTypes.object,
    formProps: PropTypes.object,
    members: PropTypes.array.isRequired,
    showProfileModal: PropTypes.func.isRequired,
    hideProfileModal: PropTypes.func.isRequired,
};

Profile.defaultProps = {
    logoutUser: () => {},
    user: {},
    updateProfileName: () => {},
    updateProfileEmail: () => {},
    updateProfilePassword: () => {},
    modalProps: {},
    formProps: {},
};


function stateToProps(state) {
    const { authentication, profile } = state;
    const { user } = authentication.toJS();
    return {
        user,
        ...profile,
    };
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        logoutUser,
        updateProfileName,
        updateProfileEmail,
        updateProfilePassword,
        showProfileModal,
        hideProfileModal,
    }, dispatch);
}

const ProfileWithClaims = withCurrentUserClaims(Profile);

export default connect(stateToProps, dispatchToProps)((injectIntl(ProfileWithClaims)));
