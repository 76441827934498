import React from 'react';
import PropTypes from 'prop-types';

export default function Add(props) {
    const { className } = props;
    return (
        <svg className={className} fill="currentColor" width="18px" height="18px" viewBox="0 0 18 18">
            <g fillRule="nonzero">
                <path d="M17.296875,8.296875 L9.703125,8.296875 L9.703125,0.703125 C9.703125,0.314789063 9.38833594,0 9,0 C8.61166406,0 8.296875,0.314789063 8.296875,0.703125 L8.296875,8.296875 L0.703125,8.296875 C0.314789063,8.296875 0,8.61166406 0,9 C0,9.38833594 0.314789063,9.703125 0.703125,9.703125 L8.296875,9.703125 L8.296875,17.296875 C8.296875,17.6852109 8.61166406,18 9,18 C9.38833594,18 9.703125,17.6852109 9.703125,17.296875 L9.703125,9.703125 L17.296875,9.703125 C17.6852109,9.703125 18,9.38833594 18,9 C18,8.61166406 17.6852109,8.296875 17.296875,8.296875 Z" />
            </g>
        </svg>
    );
}

Add.propTypes = {
    className: PropTypes.string,
};

Add.defaultProps = {
    className: undefined,
};
