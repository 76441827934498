import React, { useMemo } from 'react';
import { FSCollection } from 'react-rainbow-firebase';
import useCanAccessWithRoles from '../../../hooks/useCanAccessWithRoles';
import { getCurrentUser } from '../../../redux/services/firebase';
import InitializingApp from '../../../components/initializing-app';
import ProspectsTable from './prospects-table';

const query = [{
    where: 'enable',
    eq: true,
}];

export default function Prospects() {
    const [hasAllAccess, isLoading] = useCanAccessWithRoles(['admin', 'operator', 'prospects-operator-supervisor']);
    const finalQuery = useMemo(() => {
        const { email } = getCurrentUser();
        if (hasAllAccess) {
            return query;
        }
        return [...query, {
            where: 'createdBy.email',
            eq: email,
        }];
    }, [hasAllAccess]);

    if (isLoading) {
        return <InitializingApp />;
    }

    return (
        <FSCollection
            component={ProspectsTable}
            collectionRef="prospects"
            query={finalQuery}
            cacheStrategy="subscribeOnce"
            hasAllAccess={hasAllAccess}
        />
    );
}
