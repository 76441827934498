import escapeRegExp from './escape-RegExp';

function getWords(query) {
    return query
        .split(/\s+/g)
        .map(word => word.trim())
        .filter(word => !!word);
}

export default function filterCollection(query, collection, mapValuesToString, intl) {
    if (query) {
        return collection.filter(({ data }) => {
            const stringToMatch = mapValuesToString(data, intl);

            const words = getWords(query);
            return words.every((word) => {
                const regex = new RegExp(escapeRegExp(word), 'i');
                return regex.test(stringToMatch);
            });
        });
    }
    return collection;
}
