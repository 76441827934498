import JsPDF from 'jspdf';

export default function generatePDF(body, formattedDate) {
    const doc = new JsPDF({
        orientation: 'landscape',
        format: 'legal',
    });
    const totalPagesExp = '{total_pages_count_string}';
    doc.text(`${formattedDate} Appointments`, 14, 22);
    doc.setFontSize(18);
    doc.setTextColor(100);

    doc.autoTable({
        head: [['Time', 'Driver', 'Patient', 'Patient Address', 'Drop Off Location', 'Comments', 'Status']],
        body,
        rowPageBreak: 'avoid',
        startY: 30,
        columnStyles: {
            0: { cellWidth: 20 },
            1: { cellWidth: 20 },
            6: { cellWidth: 20 },
        },
        didDrawPage(data) {
            let str = `Page ${doc.internal.getNumberOfPages()}`;
            if (typeof doc.putTotalPages === 'function') {
                str = `${str} of ${totalPagesExp}`;
            }
            doc.setFontSize(10);
            const { pageSize } = doc.internal;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
    });

    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
    }

    window.open(doc.output('bloburl'), '_blank');
}
