import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    Input,
    Select,
    DatePicker,
    RenderIf,
} from 'react-rainbow-components';
import { Warning } from '@rainbow-modules/icons';
import { UniversalForm, Field, MultiPhoneNumberInput } from '@rainbow-modules/forms';
import { LocationMarkerIcon } from '../../../../components/icons';
import { getFormattedDate, shouldShowLegacyPhone } from '../../../../helpers';
import { getCurrentUser } from '../../../../redux/services/firebase';
import validate from './validate';
import './styles.css';
import formatPhoneNumbers from '../../../../helpers/formatPhoneNumbers';
import onAddPhoneNumber from '../../../../helpers/onAddPhoneNumber';

const today = new Date();
const thisYear = new Date().getFullYear();
const oneHundredFiftyYearsAgo = new Date(thisYear - 150, 0, 1);

const messages = defineMessages({
    firstNamePlaceholder: {
        id: 'patients.first.name.placeholder',
        defaultValue: 'Enter the First Name',
    },
    lastNamePlaceholder: {
        id: 'patients.last.name.placeholder',
        defaultValue: 'Enter the Last Name',
    },
    phoneNumberPlaceholder: {
        id: 'patients.phone.number.placeholder',
        defaultValue: 'Enter the Phone Number',
    },
    birthdatePlaceholder: {
        id: 'patients.birthdate.placeholder',
        defaultValue: 'Select the Birthdate',
    },
    addressPlaceholder: {
        id: 'address.placeholder',
        defaultValue: 'Enter Address',
    },
    selectSex: {
        id: 'patients.select.sex',
        defaultValue: 'Select Sex',
    },
    sexFemaleOption: {
        id: 'patients.select.sex.female',
        defaultValue: 'Female',
    },
    sexMaleOption: {
        id: 'patients.select.sex.male',
        defaultValue: 'Male',
    },
    selectDriver: {
        id: 'patients.select.driver',
        defaultValue: 'Select Driver',
    },
    memberIdPlaceholder: {
        id: 'patients.member.number.placeholder',
        defaultValue: 'Enter member #',
    },
});

const insuranceOptions = [
    { value: '', label: 'Medical Insurance', disabled: true },
    { value: 'Simply', label: 'Simply' },
    { value: 'CarePlus', label: 'CarePlus' },
    { value: 'CARE PLUS', label: 'CARE PLUS' },
    { value: 'Coventry', label: 'Coventry' },
    { value: 'Medica', label: 'Medica' },
    { value: 'Medicaid', label: 'Medicaid' },
    { value: 'HealthSun', label: 'HealthSun' },
    { value: 'Preferred', label: 'Preferred' },
    { value: 'Aetna', label: 'Aetna' },
    { value: 'Doctors', label: 'Doctors' },
    { value: 'other', label: 'Other' },
];

function PatientForm(props) {
    const {
        className,
        initialValues,
        onSubmit,
        intl,
    } = props;
    const { phone, phoneNumbers } = initialValues;

    const options = [
        { value: '', label: intl.formatMessage(messages.selectSex), disabled: true },
        { value: 'female', label: intl.formatMessage(messages.sexFemaleOption) },
        { value: 'male', label: intl.formatMessage(messages.sexMaleOption) },
    ];

    const driverOptions = [
        { value: '', label: intl.formatMessage(messages.selectDriver), disabled: true },
        { value: '100', label: '100' },
        { value: '101', label: '101' },
        { value: '102', label: '102' },
        { value: '103', label: '103' },
        { value: '104', label: '104' },
        { value: '105', label: '105' },
        { value: '106', label: '106' },
        { value: 'sts', label: 'STS' },
    ];

    const submit = (values) => {
        const {
            dob,
            phoneNumbers,
            ...rest
        } = values;

        onSubmit({
            ...rest,
            dob: getFormattedDate(dob),
            userId: getCurrentUser().uid,
            phoneNumbers: formatPhoneNumbers(phoneNumbers),
            enable: true,
        });
    };

    const getContainerClassNames = () => classnames('scheduler-patients_patient-form-container', className);
    const showLegacyPhone = shouldShowLegacyPhone(phone, formatPhoneNumbers(phoneNumbers));

    return (
        <UniversalForm
            className={getContainerClassNames()}
            id="patient-form"
            validate={validate}
            noValidate
            initialValues={initialValues}
            onSubmit={submit}
        >
            <div className="rainbow-position-align_start rainbow-justify_spread rainbow-m-bottom_large">
                <Field
                    className="scheduler-patients_patient-form-input"
                    required
                    label={<FormattedMessage id="patients.first.name" defaultMessage="First Name" />}
                    placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
                    name="firstName"
                    component={Input} />
                <Field
                    className="scheduler-patients_patient-form-input"
                    required
                    label={<FormattedMessage id="patients.last.name" defaultMessage="Last Name" />}
                    name="lastName"
                    component={Input}
                    placeholder={intl.formatMessage(messages.lastNamePlaceholder)} />
            </div>

            <div className="rainbow-position-align_start rainbow-justify_spread rainbow-m-bottom_large">
                <Field
                    className="scheduler-patients_patient-form-input"
                    required
                    label={<FormattedMessage id="patients.dob" defaultMessage="Date of Birth" />}
                    name="dob"
                    component={DatePicker}
                    minDate={oneHundredFiftyYearsAgo}
                    maxDate={today}
                    placeholder={intl.formatMessage(messages.birthdatePlaceholder)} />

                <Field
                    className="scheduler-patients_patient-form-input"
                    label={<FormattedMessage id="gender" defaultMessage="Gender" />}
                    name="sex"
                    component={Select}
                    options={options} />
            </div>

            <Field
                required
                label={<FormattedMessage id="address" defaultMessage="Address" />}
                name="address"
                component={Input}
                placeholder={intl.formatMessage(messages.addressPlaceholder)}
                icon={<LocationMarkerIcon />}
                className="rainbow-m-bottom_large" />

            <div className="rainbow-position-align_start rainbow-justify_spread rainbow-m-bottom_large">
                <Field
                    className="scheduler-patients_patient-form-input"
                    label={<FormattedMessage id="medical.insurance" defaultMessage="Medical Insurance" />}
                    name="medicalInsurance"
                    component={Select}
                    options={insuranceOptions} />
                <Field
                    className="scheduler-patients_patient-form-input"
                    label={<FormattedMessage id="patients.member.number" defaultMessage="Member #" />}
                    placeholder={intl.formatMessage(messages.memberIdPlaceholder)}
                    name="memberId"
                    component={Input}
                />

            </div>

            <RenderIf isTrue={showLegacyPhone}>
                <Field
                    className="rainbow-m-bottom_large"
                    labelAlignment="left"
                    label={(
                        <div className="scheduler-patients_patient-form-invalid-phone-label">
                            <Warning className="scheduler-patients_patient-form-warning-icon" />
                            <FormattedMessage id="patients.phone.number.invalid" defaultMessage="Invalid Phone Number" />
                        </div>
                    )}
                    bottomHelpText={(
                        <div className="scheduler-patients_patient-form-invalid-phone-help-text">
                            <FormattedMessage id="patients.phone.number.invalid.message" defaultMessage="We have detected that this number is invalid. Please enter the phone number correctly in the fields below." />
                        </div>
                    )}
                    name="phone"
                    type="tel"
                    component={Input}
                    placeholder={intl.formatMessage(messages.phoneNumberPlaceholder)}
                    readonly
                    disabled
                />
            </RenderIf>

            <Field
                className="rainbow-m-bottom_x-large"
                name="phoneNumbers"
                label="Phone numbers"
                component={MultiPhoneNumberInput}
                onAddPhoneNumber={onAddPhoneNumber} />

            <Field
                className="rainbow-m-bottom_large rainbow-m-top_medium"
                label={<FormattedMessage id="driver" defaultMessage="Driver" />}
                name="driver"
                component={Select}
                options={driverOptions} />

            <Field
                className="rainbow-m-bottom_medium"
                name="regularPatient"
                component={Input}
                type="checkbox"
                label={<FormattedMessage id="patients.regular.patient" defaultMessage="Regular Patient" />} />

        </UniversalForm>
    );
}

PatientForm.propTypes = {
    className: PropTypes.string,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

PatientForm.defaultProps = {
    className: undefined,
    onSubmit: () => {},
    initialValues: {},
};

export default injectIntl(PatientForm);
