import React from 'react';
import PropTypes from 'prop-types';

export default function Alert(props) {
    const { className } = props;
    return (
        <svg className={className} width="3px" height="12px" viewBox="0 0 3 12">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-21.000000, -5.000000)">
                    <g transform="translate(15.000000, 3.000000)">
                        <polygon id="Shape" points="0 0 16 0 16 16 0 16" />
                        <path d="M6.98701516,8.50557381 L6.75635686,5.0543207 C6.7131127,4.38170987 6.69183275,3.8988396 6.69183275,3.60598361 C6.69183275,3.20734366 6.79631665,2.89628681 7.00494233,2.67301833 C7.21384171,2.44899718 7.48931793,2.33760242 7.83013935,2.33760242 C8.24335368,2.33760242 8.51985626,2.48047236 8.65889443,2.76621222 C8.7979326,3.05222578 8.86779381,3.46441374 8.86779381,4.00181817 C8.86779381,4.31903321 8.85089301,4.64138007 8.8176388,4.967285 L8.50760831,8.51966921 C8.47408041,8.94259979 8.40209805,9.26672569 8.29125069,9.49279956 C8.18067702,9.71832605 7.9978473,9.83102086 7.74358261,9.83102086 C7.48398083,9.83102086 7.30395651,9.72222623 7.20337279,9.50333691 C7.10224168,9.28513182 7.03005405,8.95231604 6.98701516,8.50557381 Z M7.78682677,13.2466933 C7.49369708,13.2466933 7.23765336,13.1521994 7.01951671,12.9625272 C6.80090108,12.7730604 6.69183275,12.5075741 6.69183275,12.1665474 C6.69183275,11.8685596 6.79631665,11.6150476 7.00494233,11.4058745 C7.21384171,11.1969751 7.46988543,11.0924912 7.77252609,11.0924912 C8.07537203,11.0924912 8.33298951,11.1967014 8.54702071,11.4058745 C8.76077821,11.6147739 8.86779381,11.8684911 8.86779381,12.1665474 C8.86779381,12.502716 8.75975185,12.7666969 8.54312052,12.9589692 C8.32703659,13.1506256 8.07509833,13.2466933 7.78682677,13.2466933 Z" fill="#fe4849" fillRule="nonzero" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Alert.propTypes = {
    className: PropTypes.string,
};

Alert.defaultProps = {
    className: undefined,
};
