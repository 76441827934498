import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    injectIntl,
    defineMessages,
} from 'react-intl';
import { Badge } from 'react-rainbow-components';
import {
    AlertIcon,
    CheckmarkIcon,
    InprogressIcon,
    OwnCarIcon,
    NoShowIcon,
    StsIcon,
} from '../icons';
import './styles.css';

const messages = defineMessages({
    statusNotConfirmed: {
        id: 'status.not.confirmed',
        defaultValue: 'Not Confirmed',
    },
    statusConfirmed: {
        id: 'status.confirmed',
        defaultValue: 'Confirmed',
    },
    statusCanceled: {
        id: 'status.canceled',
        defaultValue: 'Canceled',
    },
    statusSts: {
        id: 'status.sts',
        defaultValue: 'Sts',
    },
    statusOwnCar: {
        id: 'status.own.car',
        defaultValue: 'Own Car',
    },
    statusNoShow: {
        id: 'status.no.show',
        defaultValue: 'No Show',
    },
    statusKeptApp: {
        id: 'status.no.kept.app',
        defaultValue: 'Kept App',
    },
});

const iconMap = {
    'not confirmed': <InprogressIcon className="shedule-status-badge_icon rainbow-m-right_xx-small" />,
    confirmed: <CheckmarkIcon className="shedule-status-badge_icon rainbow-m-right_xx-small" />,
    canceled: <AlertIcon className="shedule-status-badge_icon rainbow-m-right_x-small" />,
    'own car': <OwnCarIcon className="shedule-status-badge_icon-own-car rainbow-m-right_x-small" />,
    'no show': <NoShowIcon className="shedule-status-badge_icon-no-show rainbow-m-right_x-small" />,
    sts: <StsIcon className="shedule-status-badge_icon-sts rainbow-m-right_xx-small" />,
};

function StatusBadge(props) {
    const {
        value,
        intl,
    } = props;

    const getClassNames = () => classnames({
        'shedule-status-badge_verified': value === 'confirmed',
        'shedule-status-badge_expired': value === 'canceled',
        'shedule-status-badge_processing': value === 'not confirmed',
        'shedule-status-badge_own-car': value === 'own car',
        'shedule-status-badge_no-show': value === 'no show',
        'shedule-status-badge_kept-app': value === 'kept app',
        'shedule-status-badge_sts': value === 'sts',
    });

    const valueMap = {
        sts: intl.formatMessage(messages.statusSts),
        confirmed: intl.formatMessage(messages.statusConfirmed),
        canceled: intl.formatMessage(messages.statusCanceled),
        'not confirmed': intl.formatMessage(messages.statusNotConfirmed),
        'own car': intl.formatMessage(messages.statusOwnCar),
        'no show': intl.formatMessage(messages.statusNoShow),
        'kept app': intl.formatMessage(messages.statusKeptApp),
    };

    const icon = iconMap[value];
    const label = valueMap[value];

    return (
        <Badge className={getClassNames()} title={label} variant="lightest">
            {icon}
            {label}
        </Badge>
    );
}

StatusBadge.propTypes = {
    value: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

StatusBadge.defaultProps = {
    value: undefined,
};

export default (injectIntl(StatusBadge));
