import React from 'react';
import { FormattedMessage } from 'react-intl';
import getCurrentUser from '../../../../redux/services/firebase/get-current-user';

const USERNAME_ERROR = (
    <FormattedMessage
        id="form.error.username.required"
        defaultMessage="Looks like you forget your username." />
);

const SAME_USERNAME_ERROR = (
    <FormattedMessage
        id="form.error.different.username.required"
        defaultMessage="Looks like this username is the same already you have." />
);

const EMAIL_ERROR = (
    <FormattedMessage
        id="form.error.email.required"
        defaultMessage="Looks like you forget your email." />
);

const SAME_EMAIL_ERROR = (
    <FormattedMessage
        id="form.error.different.email.required"
        defaultMessage="Looks like this email address is the same already you have." />
);

const PASSWORD_ERROR = (
    <FormattedMessage
        id="form.error.password.required"
        defaultMessage="Looks like you forget your password." />
);

export default function validate(values) {
    const { displayName: currentDisplayName } = getCurrentUser();
    const { email: currentEmail } = getCurrentUser();
    const { displayName, email, password } = values;
    const errors = {};
    if (!displayName) {
        errors.displayName = USERNAME_ERROR;
    }
    if (displayName === currentDisplayName) {
        errors.displayName = SAME_USERNAME_ERROR;
    }
    if (!email) {
        errors.email = EMAIL_ERROR;
    }
    if (email === currentEmail) {
        errors.email = SAME_EMAIL_ERROR;
    }
    if (!password) {
        errors.password = PASSWORD_ERROR;
    }
    return errors;
}
