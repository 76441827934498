import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from './image-gallery';
import '../styles.css';

const Files = ({ match: { params: { id } } }) => (
    <article className="scheduler-prospects_details-container">
        <ImageGallery
            path={`/prospects/${id}/files`}
            allowUpload
            allowDelete
            maxResults={20}
        />
    </article>
);

Files.propTypes = {
    match: PropTypes.object.isRequired,
};

export default Files;
