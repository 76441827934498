import React from 'react';
import PropTypes from 'prop-types';
import {
    ButtonGroup,
    ButtonIcon,
} from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import {
    PencilIcon,
    TrashIcon,
} from '../icons';

export default function Actions({ onRemove, onEdit }) {
    return (
        <ButtonGroup>
            <RenderIf isTrue={!!onEdit}>
                <ButtonIcon
                    variant="border"
                    icon={<PencilIcon className="scheduler-record-card-actions_button-icon" />}
                    onClick={onEdit}
                />
            </RenderIf>
            <RenderIf isTrue={!!onRemove}>
                <ButtonIcon
                    variant="border"
                    icon={<TrashIcon className="scheduler-record-card-actions_button-icon" />}
                    onClick={onRemove}
                />
            </RenderIf>
        </ButtonGroup>
    );
}

Actions.propTypes = {
    onRemove: PropTypes.func,
    onEdit: PropTypes.func,
};

Actions.defaultProps = {
    onRemove: undefined,
    onEdit: undefined,
};
