import { updateUser } from '../../services/profile';
import { showSuccessMessage, showErrorMessage } from '../app';
import hideProfileModal from './hide-profile-modal';
import {
    PROFILE_START_LOADING_FORM,
    PROFILE_END_LOADING_FORM,
} from '.';

export const PROFILE_UPDATE_MEMBER = 'PROFILE_UPDATE_MEMBER';

function getError(error) {
    if (error.details) return error.details;
    if (error.code === 'permission-denied') return error.message;
    return 'Looks like there is a problem. Try again later.';
}

export default function updateMember(data) {
    return async (dispatch) => {
        dispatch({
            type: PROFILE_START_LOADING_FORM,
        });
        try {
            const user = await updateUser(data);
            dispatch(showSuccessMessage('The member has been updated successfully.'));
            dispatch({
                type: PROFILE_END_LOADING_FORM,
            });
            dispatch(hideProfileModal());
            dispatch({
                type: PROFILE_UPDATE_MEMBER,
                member: user.data,
            });
        } catch (error) {
            dispatch(showErrorMessage(getError(error)));
            dispatch({
                type: PROFILE_END_LOADING_FORM,
            });
        }
    };
}
