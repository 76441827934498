export const GMT_0400_TIME_ZONE = 'GMT-0400';
export const APPOINTMENTS_REF = 'appointments';
export const CLIENT_LOCATION_NAME = 'Primary Care';

export const ROLE_OPTIONS = [
    { value: 'admin', label: 'Admin', description: 'It\'s god-like in the system.' },
    { value: 'operator', label: 'Operator', description: 'It can handle most of what an Admin can accomplish, with the exception of managing users and permissions.' },
    { value: 'prospects-operator', label: 'Prospect Operator(PO)', description: 'It\'s limited to modifying the Prospects category.' },
    { value: 'prospects-operator-supervisor', label: 'Prospect Operator Supervisor', description: 'Same as PO plus it can see what other POs are doing.' },
];
