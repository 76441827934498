import React from 'react';
import PropTypes from 'prop-types';
import { FormattedRelativeTime } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';


const FormattedRelative = ({ value: val }) => {
    const { value, unit } = selectUnit(val);
    return <FormattedRelativeTime value={value} unit={unit} />;
};

FormattedRelative.propTypes = {
    value: PropTypes.object.isRequired,
};

export default FormattedRelative;
