import React from 'react';
import PropTypes from 'prop-types';
import { FSCollection } from 'react-rainbow-firebase';
import NotesComponent from './notes';

const query = [
    {
        orderBy: 'createdAt',
        dir: 'desc',
    },
];

const Notes = ({ match: { params: { id } } }) => (
    <FSCollection
        component={NotesComponent}
        collectionRef={`patients/${id}/notes`}
        query={query}
        cacheStrategy="subscribeOnce"
        patientId={id}
    />
);

Notes.propTypes = {
    match: PropTypes.object.isRequired,
};

export default Notes;
