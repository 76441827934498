import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { RenderIf } from 'react-rainbow-components';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import useCanAccessWithRoles from '../../hooks/useCanAccessWithRoles';
import TopBar from '../../components/top-bar';
import ConfirmationModal from '../../components/confirmation-modal';
import FormModal from '../../components/form-modal';
import InitializingApp from '../../components/initializing-app';
import Schedule from './schedule';
import Patients from './patients';
import Prospects from './prospects';
import Profile from './profile';
import Appointment from './schedule/appointment-details';
import PatientDetails from './patients/patient-details';
import Permissions from './usersPermissions';
import ProspectDetails from './prospects/prospect-details';
import './styles.css';

const { REACT_APP_RETALKABLE_BASE_URL, REACT_APP_RETALKABLE_APP_ID } = process.env;

const useIdToken = () => {
    const [token, setToken] = useState();
    const user = useCurrentUser();

    useEffect(() => {
        (async () => {
            const idToken = await user.getIdToken();
            setToken(idToken);
        })();
    }, []);

    return token;
};

export default function AuthRoutes(props) {
    const {
        location,
    } = props;
    const [hasAllAccess, isLoading] = useCanAccessWithRoles(['admin', 'operator']);
    const idToken = useIdToken();

    useEffect(() => () => {
        const element = document.getElementById('retalkable-app-container');
        if (element) {
            element.remove();
        }
    }, []);

    function resolveCurrentPathname() {
        return location.pathname.split('/')[2];
    }

    if (isLoading) {
        return <InitializingApp />;
    }

    if (!isLoading && !hasAllAccess) {
        return (
            <div className="scheduler-auth_wrap-container">
                <TopBar
                    location={location}
                    pathname={resolveCurrentPathname()}
                    canSeeOnlyProspects={!hasAllAccess}
                />
                <div className="scheduler-auth_content-container">
                    <Switch>
                        <Route path="/app/prospects" component={Prospects} />
                        <Route path="/app/prospect/:id" component={ProspectDetails} />
                        <Route path="/app/profile" component={Profile} />
                        <Redirect to="/app/prospects" />
                    </Switch>
                </div>
                <ConfirmationModal />
                <FormModal />
            </div>
        );
    }

    return (
        <div className="scheduler-auth_wrap-container">
            <RenderIf isTrue={idToken && !window.Cypress}>
                <Helmet>
                    <script
                        src={`${REACT_APP_RETALKABLE_BASE_URL}/widget?appId=${REACT_APP_RETALKABLE_APP_ID}&token=${idToken}`}
                        crossOrigin="anonymous"
                        async
                    />
                </Helmet>
            </RenderIf>
            <TopBar location={location} pathname={resolveCurrentPathname()} />
            <div className="scheduler-auth_content-container">
                <Switch>
                    <Redirect from="/app" exact to="/app/schedule" />
                    <Route path="/app/schedule" component={Schedule} />
                    <Route path="/app/patients" component={Patients} />
                    <Route path="/app/prospects" component={Prospects} />
                    <Route path="/app/profile" component={Profile} />
                    <Route path="/app/permissions" component={Permissions} />
                    <Route path="/app/appointment/:id" component={Appointment} />
                    <Route path="/app/patient/:id" component={PatientDetails} />
                    <Route path="/app/prospect/:id" component={ProspectDetails} />
                    <Redirect to="/app/schedule" />
                </Switch>
            </div>
            <ConfirmationModal />
            <FormModal />
        </div>
    );
}

AuthRoutes.propTypes = {
    location: PropTypes.object,
};

AuthRoutes.defaultProps = {
    location: {},
};
