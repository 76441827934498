import React from 'react';
import PropTypes from 'prop-types';

export default function Inprogress(props) {
    const { className } = props;
    return (
        <svg className={className} fill="#A0A0BC" width="12px" height="12px" viewBox="0 0 12 12">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-2.000000, -2.000000)">
                    <polygon points="0 0 16 0 16 16 0 16" />
                    <path d="M7.99994524,2 C11.3083641,2 13.9998905,4.69158111 13.9998357,7.99994524 C13.9998357,11.3083641 11.3083094,14 7.99989049,14 C4.69152635,14 2,11.3083641 2,7.99994524 C2,4.69158111 4.69158111,2 7.99994524,2 Z M7.99994524,13.1786448 C10.8554141,13.1786448 13.1785352,10.8555236 13.1785352,7.99994524 C13.1785352,5.14447639 10.8554141,2.82135524 7.99994524,2.82135524 C5.14447639,2.82135524 2.82135524,5.14447639 2.82140999,7.99994524 C2.82140999,10.8554689 5.14447639,13.1786448 7.99994524,13.1786448 Z M8.50005932,7.92288387 L11.0348528,7.92288387 C11.5061157,7.92288387 11.8881497,8.30491787 11.8881497,8.7761807 C11.8881497,9.24744352 11.5061157,9.62947753 11.0348528,9.62947753 L7.64676249,9.62947753 C7.17549967,9.62947753 6.79346566,9.24744352 6.79346566,8.7761807 L6.79346566,5.01163587 C6.79346566,4.54037304 7.17549967,4.15833904 7.64676249,4.15833904 C8.11802532,4.15833904 8.50005932,4.54037304 8.50005932,5.01163587 L8.50005932,7.92288387 Z" fill="currentColor" />
                </g>
            </g>
        </svg>
    );
}

Inprogress.propTypes = {
    className: PropTypes.string,
};

Inprogress.defaultProps = {
    className: undefined,
};
