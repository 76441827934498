import {
    updateProfileEmail,
    updateProfilePassword,
    PROFILE_START_LOADING_FORM,
    PROFILE_END_LOADING_FORM,
} from '.';
import reauthenticateUser from './reauthenticate-user';
import showErrorMessage from '../app/show-error-message';
import hideProfileModal from './hide-profile-modal';
import getCurrentUser from '../../services/firebase/get-current-user';

export default function reauthenticate(credentials, currentValues) {
    const { email, password } = currentValues;
    const { email: currentEmail } = getCurrentUser();
    const shouldUpdateEmail = email && email !== currentEmail;
    const shouldUpdatePassword = password !== undefined;
    return async (dispatch) => {
        try {
            dispatch({ type: PROFILE_START_LOADING_FORM });
            await reauthenticateUser(credentials);
            dispatch({ type: PROFILE_END_LOADING_FORM });
            dispatch(hideProfileModal());
            if (shouldUpdateEmail) {
                dispatch(updateProfileEmail(currentValues));
            } else if (shouldUpdatePassword) {
                dispatch(updateProfilePassword(currentValues));
            }
        } catch (error) {
            dispatch({ type: PROFILE_END_LOADING_FORM });
            dispatch(showErrorMessage(error));
        }
    };
}
