import {
    get12HourTime,
    filterCollection,
} from '../../../../../helpers';

function mapValuesToString(data, intl) {
    const {
        address, status, time, comments, date,
    } = data;
    const formattedDate = intl.formatDate(date, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });

    return `${formattedDate},${get12HourTime(time)},${status},${address},${comments}`;
}

export default function filter(query, collection, intl) {
    return filterCollection(query, collection, mapValuesToString, intl);
}
