import PropTypes from 'prop-types';
import { getFullPatientName } from '../../helpers';

export default function PatientName({ value }) {
    if (value) {
        const { firstName, lastName } = value.data;
        return getFullPatientName(firstName, lastName);
    }
    return null;
}

PatientName.propTypes = {
    value: PropTypes.object,
};

PatientName.defaultProps = {
    value: undefined,
};
