import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
    Card,
    ButtonIcon,
} from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import {
    TrashIcon,
    PencilIcon,
    GoToIcon,
} from '../icons';
import StatusBadge from '../status-badge';
import './styles.css';

export default class AppointmentCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        };
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.setState({ isVisible: true });
    }

    handleMouseLeave() {
        this.setState({ isVisible: false });
    }

    render() {
        const {
            time,
            status,
            patientName,
            address,
            onDelete,
            onEdit,
            onViewDetails,
            className,
        } = this.props;
        const { isVisible } = this.state;

        const getContainerClassNames = () => classnames('scheduler-appointment-card_container', className);

        return (
            <Card className={getContainerClassNames()}>
                <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <header className="scheduler-appointment-card_header">
                        <h1 className="scheduler-appointment-card_time">{time}</h1>
                        <StatusBadge value={status} />
                    </header>
                    <h2 className="scheduler-appointment-card_label">
                        <FormattedMessage id="patient" defaultMessage="Patient" />
                    </h2>
                    <p className="scheduler-appointment-card_text">
                        {patientName}
                    </p>
                    <h2 className="scheduler-appointment-card_label">
                        <FormattedMessage id="address" defaultMessage="Address" />
                    </h2>
                    <p className="scheduler-appointment-card_text">
                        {address}
                    </p>
                    <RenderIf isTrue={!!isVisible}>
                        <div className="scheduler-appointment-card_hover-content">
                            <div className="scheduler-appointment-card_hover-button-container">
                                <ButtonIcon
                                    className="rainbow-m-left_xx-small"
                                    variant="border-filled"
                                    icon={<TrashIcon className="scheduler-appointment-card_hover-content-button-icon" />}
                                    size="small"
                                    onClick={onDelete} />

                                <ButtonIcon
                                    className="rainbow-m-left_xx-small"
                                    variant="border-filled"
                                    icon={<PencilIcon className="scheduler-appointment-card_hover-content-button-icon" />}
                                    size="small"
                                    onClick={onEdit} />

                                <ButtonIcon
                                    className="rainbow-m-left_xx-small"
                                    variant="border-filled"
                                    icon={<GoToIcon className="scheduler-appointment-card_hover-content-button-icon" />}
                                    size="small"
                                    onClick={onViewDetails} />
                            </div>
                        </div>
                    </RenderIf>
                </div>
            </Card>
        );
    }
}

AppointmentCard.propTypes = {
    time: PropTypes.string,
    status: PropTypes.oneOf([
        'confirmed',
        'not confirmed',
        'canceled',
        'own car',
        'no show',
        'sts',
        'kept app',
    ]),
    patientName: PropTypes.object,
    address: PropTypes.string,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onViewDetails: PropTypes.func,
    className: PropTypes.string,
};

AppointmentCard.defaultProps = {
    time: undefined,
    status: 'not-confirmed',
    patientName: undefined,
    address: undefined,
    onDelete: () => {},
    onEdit: () => {},
    onViewDetails: () => {},
    className: undefined,
};
