import React from 'react';
import PropTypes from 'prop-types';

export default function LockBorder(props) {
    const { className } = props;
    return (
        <svg className={className} fill="#061C3F" width="13px" height="17px" viewBox="0 0 13 17">
            <g id="Profile-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="profile-page" transform="translate(-1194.000000, -162.000000)" fill="#061C3F" fillRule="nonzero">
                    <g id="Group-10" transform="translate(1179.000000, 63.000000)">
                        <g id="Group-6" transform="translate(1.000000, 58.000000)">
                            <path d="M20.375,41 C22.718875,41 24.625,42.906125 24.625,45.25 L24.625,47.375 L25.15625,47.375 C26.0345833,47.375 26.75,48.0897083 26.75,48.96875 L26.75,56.40625 C26.75,57.2852917 26.0345833,58 25.15625,58 L15.59375,58 C14.7154167,58 14,57.2852917 14,56.40625 L14,48.96875 C14,48.0897083 14.7154167,47.375 15.59375,47.375 L16.125,47.375 L16.125,45.25 C16.125,42.906125 18.031125,41 20.375,41 Z M25.15625,48.4375 L15.59375,48.4375 C15.3012083,48.4375 15.0625,48.6755 15.0625,48.96875 L15.0625,56.40625 C15.0625,56.6995 15.3012083,56.9375 15.59375,56.9375 L25.15625,56.9375 C25.4487917,56.9375 25.6875,56.6995 25.6875,56.40625 L25.6875,48.96875 C25.6875,48.6755 25.4487917,48.4375 25.15625,48.4375 Z M20.375,50.2083333 C21.1562917,50.2083333 21.7916667,50.8437083 21.7916667,51.625 C21.7916667,52.2185636 21.4249436,52.7279076 20.9061213,52.9384082 L20.90625,54.6354167 C20.90625,54.9286667 20.66825,55.1666667 20.375,55.1666667 C20.08175,55.1666667 19.84375,54.9286667 19.84375,54.6354167 L19.8438787,52.9384082 C19.3250564,52.7279076 18.9583333,52.2185636 18.9583333,51.625 C18.9583333,50.8437083 19.5937083,50.2083333 20.375,50.2083333 Z M20.375,51.2708333 C20.1802083,51.2708333 20.0208333,51.4295 20.0208333,51.625 C20.0208333,51.8205 20.1802083,51.9791667 20.375,51.9791667 C20.5697917,51.9791667 20.7291667,51.8205 20.7291667,51.625 C20.7291667,51.4295 20.5697917,51.2708333 20.375,51.2708333 Z M20.375,42.0625 C18.617625,42.0625 17.1875,43.492625 17.1875,45.25 L17.187,47.375 L23.562,47.375 L23.5625,45.25 C23.5625,43.492625 22.132375,42.0625 20.375,42.0625 Z" id="lock" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

LockBorder.propTypes = {
    className: PropTypes.string,
};

LockBorder.defaultProps = {
    className: undefined,
};
