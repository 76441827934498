import React from 'react';
import PropTypes from 'prop-types';

export default function NoShow(props) {
    const { className } = props;
    return (
        <svg className={className} fill="#000000" width="14px" height="11px" viewBox="0 0 14 11">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="currentColor" fillRule="nonzero">
                    <path d="M6.98665497,1.1645653 C3.39973489,1.1645653 0.203477583,4.89295906 0.0689902534,5.05189864 C-0.023005848,5.16018713 -0.023005848,5.31912671 0.0689902534,5.4279883 C0.149333333,5.52287719 1.32421442,6.88696296 3.01840546,7.97334113 L4.84301754,6.14872904 C4.72424951,5.86927485 4.65787914,5.56244834 4.65787914,5.23992982 C4.65787914,3.95561404 5.70233918,2.911154 6.98665497,2.911154 C7.30920078,2.911154 7.616,2.97752437 7.89545419,3.0962924 L9.39577388,1.59597271 C8.66452632,1.3345575 7.86169591,1.1645653 6.98665497,1.1645653 Z" id="Path" />
                    <path d="M13.9211852,5.0740039 C13.846655,4.9662885 12.6106706,3.22668616 10.5328031,2.10537622 L12.1414113,0.496768031 C12.2549396,0.383239766 12.2549396,0.198674464 12.1414113,0.0851461988 C12.027883,-0.0283820663 11.8433177,-0.0283820663 11.7297895,0.0851461988 L1.83247173,9.98251852 C1.71894347,10.0960468 1.71894347,10.2806121 1.83247173,10.3941404 C1.88893567,10.4512047 1.96346589,10.4797232 2.0379961,10.4797232 C2.11252632,10.4797232 2.18702924,10.4512047 2.24409357,10.3947135 L4.06927875,8.56952827 C4.96877193,9.00849513 5.96083431,9.31532164 6.98665497,9.31532164 C10.573575,9.31532164 13.7698324,5.58692788 13.9043197,5.4279883 C13.988729,5.32725926 13.9962885,5.1822924 13.9211852,5.0740039 Z M6.98665497,7.56873294 C6.44927875,7.56873294 5.96083431,7.37835478 5.56610526,7.07212865 L8.81882651,3.81940741 C9.12505263,4.21413645 9.3154308,4.70260819 9.3154308,5.23995712 C9.3154308,6.5242729 8.27097076,7.56873294 6.98665497,7.56873294 Z" id="Shape" />
                </g>
            </g>
        </svg>
    );
}

NoShow.propTypes = {
    className: PropTypes.string,
};

NoShow.defaultProps = {
    className: undefined,
};
