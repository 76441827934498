import React from 'react';
import PropTypes from 'prop-types';
import { get12HourTime } from '../../helpers';

export default function TimeCell({ value }) {
    const formattedTime = get12HourTime(value);

    return (
        <p>
            {formattedTime}
        </p>
    );
}

TimeCell.propTypes = {
    value: PropTypes.string,
};

TimeCell.defaultProps = {
    value: undefined,
};
