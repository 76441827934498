import React from 'react';
import PropTypes from 'prop-types';

export default function GoTo(props) {
    const { className } = props;
    return (
        <svg className={className} fill="#A0A0BC" width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Daily-Schedule-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="arrow-icon" transform="translate(-7.000000, -7.000000)">
                    <path d="M11.4361364,8.18795455 C11.7388636,7.88522727 12.2154545,7.88522727 12.5181818,8.18795455 C12.8106818,8.48045455 12.8106818,8.96727273 12.5181818,9.25909091 L7.03159091,14.7456818 L25.1365909,14.7456818 C25.5586364,14.7456818 25.905,15.0811364 25.905,15.5031818 C25.905,15.9252273 25.5586364,16.2715909 25.1365909,16.2715909 L7.03159091,16.2715909 L12.5181818,21.7479545 C12.8106818,22.0506818 12.8106818,22.5381818 12.5181818,22.83 C12.2154545,23.1327273 11.7388636,23.1327273 11.4361364,22.83 L4.65068182,16.0445455 C4.35818182,15.7520455 4.35818182,15.2652273 4.65068182,14.9734091 L11.4361364,8.18795455 Z" id="Shape" fill="currentColor" fillRule="nonzero" transform="translate(15.168153, 15.508977) rotate(-226.000000) translate(-15.168153, -15.508977) " />
                    <rect id="Rectangle-2" x="0" y="0" width="30" height="30" />
                </g>
            </g>
        </svg>
    );
}

GoTo.propTypes = {
    className: PropTypes.string,
};

GoTo.defaultProps = {
    className: undefined,
};
