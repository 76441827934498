import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    Button,
    Input,
    Card,
} from 'react-rainbow-components';
import {
    EmailIcon,
} from '../../../components/icons';
import { resetForm, sendEmailResetPassword } from '../../../redux/actions/forgotPassword';
import validate from './validate';
import BackButton from '../../../components/back-button';
import './styles.css';
import './media-queries.css';

const translations = defineMessages({
    emailPlaceholder: {
        id: 'form.email.placeholder',
        defaultValue: 'Enter your email address',
    },
});

class ForgotPassword extends Component {
    componentDidMount() {
        const { resetForm } = this.props;
        resetForm();
    }

    getClassName() {
        const { className } = this.props;
        return classnames('scheduler-forgot-password_container', className);
    }

    render() {
        const {
            handleSubmit,
            sendEmailResetPassword,
            isLoading,
            intl,
        } = this.props;

        return (
            <form onSubmit={handleSubmit(email => sendEmailResetPassword(email))} noValidate>
                <section className={this.getClassName()}>
                    <BackButton className="scheduler-forgot-password_back-button" />
                    <Link data-cy="logo" to="/home/signin">
                        <img src="/assets/logo.svg" alt="rainbow logo" className="scheduler-forgot-password_image" />
                    </Link>
                    <p className="scheduler-forgot-password_header">
                        <FormattedMessage id="forgotpassword.title" defaultMessage="Reset password" />
                    </p>
                    <Card className="scheduler-forgot-password_card">
                        <article className="scheduler-forgot-password_content">
                            <p className="scheduler-forgot-password_message">
                                <FormattedMessage
                                    id="forgotpassword.header.text"
                                    defaultMessage="A security code will be sent to your email address." />
                            </p>
                            <Field
                                component={Input}
                                name="email"
                                label={<FormattedMessage id="form.email.label" defaultMessage="Email address" />}
                                required
                                placeholder={intl.formatMessage(translations.emailPlaceholder)}
                                icon={<EmailIcon />} />
                            <Button
                                variant="brand"
                                type="submit"
                                label={<FormattedMessage id="forgotpassword.button" defaultMessage="Send email" />}
                                isLoading={isLoading}
                            />
                        </article>
                    </Card>
                </section>
            </form>
        );
    }
}

ForgotPassword.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    sendEmailResetPassword: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    className: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

ForgotPassword.defaultProps = {
    handleSubmit: () => {},
    className: undefined,
};

function stateToProps(state) {
    const { forgot } = state;
    return forgot.toJS();
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        sendEmailResetPassword,
        resetForm,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(
    reduxForm({
        form: 'forgot-password',
        touchOnBlur: false,
        validate,
    })(injectIntl(ForgotPassword)),
);
