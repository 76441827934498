import React from 'react';
import PropTypes from 'prop-types';
import { navigateTo } from '../../../history';

const NameColumn = ({ value, row }) => (
    <div className="scheduler-patients_name-button-container">
        <button
            type="button"
            onClick={() => navigateTo(`/app/patient/${row.id}`)}
            className="scheduler-patients_name-button">
            {value}

        </button>
    </div>
);

NameColumn.propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
};

export default NameColumn;
