import React from 'react';
import { FormattedDate } from 'react-intl';
import PropTypes from 'prop-types';

export default function DateCell({ value }) {
    if (value) {
        return (
            <FormattedDate
                value={value}
                year="numeric"
                month="2-digit"
                day="2-digit" />
        );
    }
    return '';
}

DateCell.propTypes = {
    value: PropTypes.object.isRequired,
};
