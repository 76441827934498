import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select } from 'react-rainbow-components';
import { changeLocale } from '../../i18n';
import './styles.css';

const languageOptions = [
    {
        value: 'en',
        label: 'English',
    },
    {
        value: 'es',
        label: 'Español',
    },
];

function LanguageSelector(props) {
    const {
        locale,
        changeLocale,
    } = props;

    const handleLanguageChange = (event) => {
        changeLocale(event.target.value);
    };

    return (
        <Select
            id="language-selector"
            label="Language Selector"
            hideLabel
            options={languageOptions}
            value={locale}
            onChange={handleLanguageChange}
            className="schedule_language-selector" />
    );
}

LanguageSelector.propTypes = {
    locale: PropTypes.string,
    changeLocale: PropTypes.func,
};

LanguageSelector.defaultProps = {
    locale: 'en',
    changeLocale: () => {},
};

function stateToProps(state) {
    const { i18n } = state;
    return {
        locale: i18n.locale,
    };
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        changeLocale,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(LanguageSelector);
