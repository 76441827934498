import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    FormattedMessage,
    FormattedDate,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    Card,
    Input,
    Button,
    Column,
    ButtonGroup,
    MenuItem,
    Modal,
    TableWithBrowserPagination,
    withDebounce,
} from 'react-rainbow-components';
import { FSCollection } from 'react-rainbow-firebase';
import {
    bindMethods,
    getRegularAppointments,
    getDisableAppointments,
} from '../../../../helpers';
import {
    SearchIcon,
    PencilIcon,
    TrashIcon,
    GoToIcon,
    PDFIcon,
} from '../../../../components/icons';
import StatusSelect from '../../../../components/status-select';
import TimeCell from '../../../../components/time-cell';
import PatientName from '../../../../components/patient-name';
import DriverCell from '../../../../components/driver-cell';
import { getCurrentUser } from '../../../../redux/services/firebase';
import AppointmentForm from '../appointment-form';
import RegularPatientsTable from './regular-patients-table';
import filter from './helpers/filter';
import { navigateTo } from '../../../../history';

const regularPatientsQuery = [
    {
        where: 'regularPatient',
        eq: true,
    },
    {
        where: 'enable',
        eq: true,
    },
];

const messages = defineMessages({
    searchPlaceholder: {
        id: 'find.appointment.placeholder',
        defaultValue: 'Find appointment',
    },
    formModal: {
        id: 'appointments.modal.new.appointment',
        defaultValue: 'New Appointments',
    },
    editFormModal: {
        id: 'appointments.modal.edit.appointment',
        defaultValue: 'Edit Appointments',
    },
    regularAppointmentsModal: {
        id: 'appointments.modal.generate.regular.appointments',
        defaultValue: 'Generate Regular Appointments',
    },
});

function navigateToAppoinment(event, { id }) {
    navigateTo(`/app/appointment/${id}`);
}

const DebouncedInput = withDebounce(Input);

class DailyContent extends Component {
    constructor(props) {
        super(props);
        bindMethods([
            'openRegularAppointmentModal',
            'closeRegularAppointmentModal',
            'openFormModal',
            'openEditFormModal',
            'openConfirmationModal',
            'openConfirmationModalFromRow',
            'handleSelection',
            'handleOnSort',
            'generateAppointments',
            'generateReport',
            'updateStatus',
            'handleSearch',
        ], this);
        this.state = {
            searchTerm: '',
            sortDirection: 'asc',
            selectedAppointments: [],
            isOpenRegularAppointmentModal: false,
        };
    }

    getAppointments() {
        const { data: appointments } = this.props;
        const { searchTerm, sortDirection } = this.state;
        if (sortDirection === 'asc') {
            return filter(searchTerm, appointments);
        }
        const newAppointments = [...appointments];
        return filter(searchTerm, newAppointments.reverse());
    }

    openFormModal() {
        const {
            showFormModal,
            intl,
            addDoc,
            currentDate,
        } = this.props;

        showFormModal({
            title: intl.formatMessage(messages.formModal),
            formComponent: AppointmentForm,
            formId: 'appointment-form',
            onSubmit: addDoc,
            initialValues: {
                date: new Date(currentDate),
                status: 'not confirmed',
            },
        });
    }

    openEditFormModal(event, { id, data }) {
        const {
            showFormModal,
            intl,
            updateDoc,
            currentDate,
        } = this.props;

        showFormModal({
            title: intl.formatMessage(messages.editFormModal),
            formComponent: AppointmentForm,
            formId: 'appointment-form',
            onSubmit: editedData => updateDoc(id, editedData),
            initialValues: {
                ...data,
                date: new Date(currentDate),
            },
        });
    }

    openConfirmationModal() {
        const { updateDocs, showConfirmationModal } = this.props;
        const { selectedAppointments } = this.state;
        showConfirmationModal({
            collectionName: 'appointments',
            itemsAmount: selectedAppointments.length,
            onAccept: () => updateDocs(getDisableAppointments(selectedAppointments)),
        });
    }

    openConfirmationModalFromRow(event, { id }) {
        const { updateDoc, showConfirmationModal } = this.props;
        showConfirmationModal({
            collectionName: 'appointments',
            itemsAmount: 1,
            onAccept: () => updateDoc(id, {
                userId: getCurrentUser().uid,
                enable: false,
            }),
        });
    }

    openRegularAppointmentModal() {
        this.setState({
            isOpenRegularAppointmentModal: true,
        });
    }

    closeRegularAppointmentModal() {
        this.setState({
            isOpenRegularAppointmentModal: false,
        });
    }

    handleSelection(selectedAppointments) {
        this.setState({
            selectedAppointments,
        });
    }

    handleSearch(event) {
        const searchTerm = event.target.value;
        this.setState({
            searchTerm,
        });
    }

    handleOnSort(event, field, nextSortDirection) {
        this.setState({ sortDirection: nextSortDirection });
    }

    generateAppointments(selectedPatients) {
        const { addDocs, currentDate } = this.props;
        addDocs(getRegularAppointments(selectedPatients, currentDate));
        this.setState({
            isOpenRegularAppointmentModal: false,
        });
    }

    generateReport() {
        const { generateReport, currentDate, intl } = this.props;
        const formattedDate = intl.formatDate(currentDate, {
            year: 'numeric',
            month: '2-digit',
            weekday: 'long',
            day: '2-digit',
        });
        generateReport(this.getAppointments(), formattedDate);
    }

    updateStatus(id, data) {
        const { updateDoc } = this.props;
        updateDoc(id, {
            ...data,
            userId: getCurrentUser().uid,
        });
    }

    render() {
        const {
            isLoading,
            currentDate,
            intl,
        } = this.props;

        const {
            isOpenRegularAppointmentModal,
            selectedAppointments,
            sortDirection,
        } = this.state;

        const appointments = this.getAppointments();
        const displayedAppointmentsAmount = appointments.length;
        const isDeleteButtonDisabled = selectedAppointments.length === 0;

        return (
            <section>
                <Card className="scheduler-daily-schedule_table-card">
                    <header className="scheduler-daily-schedule_table-header">
                        <div className="scheduler-daily-schedule_table-header-content">
                            <div>
                                <h1 className="scheduler-daily-schedule_table-title">
                                    <FormattedDate
                                        value={currentDate}
                                        year="numeric"
                                        month="2-digit"
                                        weekday="long"
                                        day="2-digit"
                                    />
                                </h1>
                                <h2 className="scheduler-daily-schedule_table-subtitle">
                                    <FormattedMessage id="appointments.daily.schedule.appointments" defaultMessage="Appointments" />
                                    {' • '}
                                    {displayedAppointmentsAmount}
                                </h2>
                            </div>
                            <div className="scheduler-daily-schedule_actions-container">
                                <DebouncedInput
                                    id="daily-content-table-input"
                                    onChange={this.handleSearch}
                                    label={<FormattedMessage id="appointments.daily.schedule.search" defaultMessage="Find appointment" />}
                                    hideLabel
                                    className="scheduler-daily-schedule_search-input"
                                    type="search"
                                    placeholder={intl.formatMessage(messages.searchPlaceholder)}
                                    icon={<SearchIcon />} />

                                <ButtonGroup>
                                    <Button
                                        title="New Appointment"
                                        label={<FormattedMessage id="new" defaultMessage="New" />}
                                        onClick={this.openFormModal} />
                                    <Button
                                        title="Regular Appointment"
                                        id="regular-appointment-button"
                                        label={<FormattedMessage id="regulars" defaultMessage="Regulars" />}
                                        onClick={this.openRegularAppointmentModal} />
                                    <Button
                                        title="Delete Appointment"
                                        label={<FormattedMessage id="delete" defaultMessage="Delete" />}
                                        disabled={isDeleteButtonDisabled}
                                        onClick={this.openConfirmationModal} />
                                </ButtonGroup>

                                <Button
                                    title="pdf"
                                    className="rainbow-m-left_small"
                                    onClick={this.generateReport}>

                                    <PDFIcon className="scheduler-daily-schedule_report-icon" />
                                    PDF
                                </Button>
                            </div>
                        </div>
                    </header>
                    <TableWithBrowserPagination
                        id="daily-table"
                        className="scheduler-daily-schedule_table"
                        data={appointments}
                        isLoading={isLoading}
                        pageSize={30}
                        onRowSelection={this.handleSelection}
                        keyField="id"
                        onSort={this.handleOnSort}
                        sortDirection={sortDirection}
                        sortedBy="data.time"
                        showCheckboxColumn>

                        <Column
                            header={<FormattedMessage id="appointments.daily.schedule.table.time" defaultMessage="Time" />}
                            field="data.time"
                            component={TimeCell}
                            sortable
                            width={100} />
                        <Column
                            header={<FormattedMessage id="appointments.daily.schedule.table.patient" defaultMessage="Patient" />}
                            field="data.patient"
                            component={PatientName} />
                        <Column
                            header={<FormattedMessage id="status" defaultMessage="Status" />}
                            field="data.status"
                            component={StatusSelect}
                            onEdit={this.updateStatus}
                            width={190} />
                        <Column
                            header={<FormattedMessage id="driver" defaultMessage="Driver" />}
                            field="data.patient.data.driver"
                            component={DriverCell}
                            width={100} />
                        <Column
                            header={<FormattedMessage id="address" defaultMessage="Address" />}
                            field="data.address" />
                        <Column
                            header={<FormattedMessage id="appointments.daily.schedule.table.comment" defaultMessage="Comment" />}
                            field="data.comments" />
                        <Column type="action">
                            <MenuItem
                                label="View"
                                icon={<GoToIcon className="scheduler-daily-schedule_table-action-icon" />}
                                onClick={navigateToAppoinment} />

                            <MenuItem
                                label={<FormattedMessage id="edit" defaultMessage="Edit" />}
                                icon={<PencilIcon className="scheduler-daily-schedule_table-action-icon" />}
                                onClick={this.openEditFormModal} />

                            <MenuItem
                                label={<FormattedMessage id="delete" defaultMessage="Delete" />}
                                icon={<TrashIcon className="scheduler-daily-schedule_table-action-icon" />}
                                onClick={this.openConfirmationModalFromRow} />

                        </Column>
                    </TableWithBrowserPagination>
                </Card>
                <Modal
                    className="scheduler-daily-schedule_regular-appointments-modal"
                    isOpen={isOpenRegularAppointmentModal}
                    title={intl.formatMessage(messages.regularAppointmentsModal)}
                    size="medium"
                    onRequestClose={this.closeRegularAppointmentModal}>

                    <FSCollection
                        component={RegularPatientsTable}
                        collectionRef="patients"
                        query={regularPatientsQuery}
                        onGenerateAppointments={this.generateAppointments}
                        onCloseModal={this.closeRegularAppointmentModal}
                        cacheStrategy="subscribeOnce"
                    />
                </Modal>
            </section>
        );
    }
}

DailyContent.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    addDoc: PropTypes.func.isRequired,
    addDocs: PropTypes.func.isRequired,
    updateDoc: PropTypes.func.isRequired,
    updateDocs: PropTypes.func.isRequired,
    currentDate: PropTypes.string,
    generateReport: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

DailyContent.defaultProps = {
    currentDate: undefined,
};

export default (injectIntl(DailyContent));
