import history from '../../../history';
import { navigate } from '../app';

export const LOAD_SELECTED_WEEK = 'LOAD_SELECTED_WEEK';

export default function showWeekAppointments(selectedWeek) {
    const url = `/app/schedule/weekly/${selectedWeek}`;
    const { pathname } = history.location;
    navigate(pathname, url);
    return (dispatch) => {
        dispatch({
            type: LOAD_SELECTED_WEEK,
            selectedWeek,
        });
    };
}
