import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    Column,
    MenuItem,
    ButtonGroup,
    Button,
    Input,
    TableWithBrowserPagination,
    withDebounce,
} from 'react-rainbow-components';
import {
    TrashIcon,
    PencilIcon,
    AddIcon,
    SearchIcon,
    PDFIcon,
    GoToIcon,
} from '../../../components/icons';
import {
    bindMethods,
    getDisablePatients,
    getFormattedPhoneNumber,
    filter,
    sort,
    parsePhoneNumbers,
} from '../../../helpers';
import PhoneCell from '../../../components/phone-cell';
import DriverCell from '../../../components/driver-cell';
import { showConfirmationModal } from '../../../redux/actions/confirmation-modal';
import { showFormModal } from '../../../redux/actions/form-modal';
import { generateReport } from '../../../redux/actions/patients';
import PatientForm from './patient-form';
import RegularCell from './regular-cell';
import { navigateTo } from '../../../history';
import NameColumn from './nameColumn';
import './styles.css';

const messages = defineMessages({
    searchPlaceholder: {
        id: 'patient.placeholder',
        defaultValue: 'Find patient',
    },
    formModal: {
        id: 'patients.modal.new.patient',
        defaultValue: 'New Patient',
    },
    editFormModal: {
        id: 'patients.modal.edit.patient',
        defaultValue: 'Edit Patient',
    },
});

function navigateToPatient(event, { id }) {
    navigateTo(`/app/patient/${id}`);
}

const DebouncedInput = withDebounce(Input);

class PatientsTable extends Component {
    constructor(props) {
        super(props);
        bindMethods([
            'openFormModal',
            'openEditFormModal',
            'openConfirmationModal',
            'openConfirmationModalFromRow',
            'handleSelection',
            'generateReport',
            'handleOnSort',
            'handleSearch',
        ], this);
        this.state = {
            patients: props.data,
            searchTerm: '',
            selectedPatients: [],
        };
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (data !== prevProps.data) {
            this.updatePatients();
        }
    }

    updatePatients() {
        const { data } = this.props;
        const { searchTerm, sortedBy, sortDirection } = this.state;
        this.setState({
            patients: sort(
                filter(searchTerm, data),
                { sortedBy, sortDirection },
            ),
        });
    }

    openFormModal() {
        const { showFormModal, intl, addDoc } = this.props;
        showFormModal({
            title: intl.formatMessage(messages.formModal),
            formComponent: PatientForm,
            formId: 'patient-form',
            onSubmit: addDoc,
            initialValues: {
                regularPatient: false,
            },
        });
    }

    openEditFormModal(event, { id, data }) {
        const { showFormModal, intl, updateDoc } = this.props;
        showFormModal({
            title: intl.formatMessage(messages.editFormModal),
            formComponent: PatientForm,
            formId: 'patient-form',
            onSubmit: editedData => updateDoc(id, editedData),
            initialValues: {
                ...data,
                phone: getFormattedPhoneNumber(data.phone),
                phoneNumbers: parsePhoneNumbers(data.phoneNumbers),
            },
        });
    }

    openConfirmationModal() {
        const { updateDocs, showConfirmationModal } = this.props;
        const { selectedPatients } = this.state;
        showConfirmationModal({
            collectionName: 'patients',
            itemsAmount: selectedPatients.length,
            onAccept: () => updateDocs(getDisablePatients(selectedPatients)),
        });
    }

    openConfirmationModalFromRow(event, { id }) {
        const { updateDoc, showConfirmationModal } = this.props;
        showConfirmationModal({
            collectionName: 'patients',
            itemsAmount: 1,
            onAccept: () => updateDoc(id, {
                enable: false,
            }),
        });
    }

    handleSelection(selectedPatients) {
        this.setState({
            selectedPatients,
        });
    }

    handleSearch(event) {
        const searchTerm = event.target.value;
        const { data } = this.props;
        this.setState({
            searchTerm,
            patients: filter(searchTerm, data),
        });
    }

    generateReport() {
        const { generateReport } = this.props;
        const { patients } = this.state;
        generateReport(patients);
    }

    handleOnSort(event, sortedBy, sortDirection) {
        const { patients } = this.state;
        this.setState({
            sortedBy,
            sortDirection,
            patients: sort(patients, { sortedBy, sortDirection }),
        });
    }

    render() {
        const {
            isLoading,
            intl,
        } = this.props;
        const {
            selectedPatients,
            sortedBy,
            sortDirection,
            patients,
        } = this.state;

        const displayedPatientsAmount = patients.length;
        const isDeleteButtonDisabled = selectedPatients.length === 0;

        return (
            <section className="scheduler-patients_container">
                <header className="scheduler-patients_table-header">
                    <div>
                        <h1 className="scheduler-patients_table-title">
                            <FormattedMessage id="patients" defaultMessage="Patients" />
                        </h1>
                        <h2 className="scheduler-patients_table-subtitle">
                            <FormattedMessage id="patients.all.patients" defaultMessage="All Patients" />
                            {' • '}
                            {displayedPatientsAmount}
                        </h2>
                    </div>
                    <div className="scheduler-patients_table-header-actions">
                        <DebouncedInput
                            id="patients-table-input"
                            onChange={this.handleSearch}
                            label="Find patient"
                            hideLabel
                            className="scheduler-patients_search-input"
                            type="search"
                            placeholder={intl.formatMessage(messages.searchPlaceholder)}
                            icon={<SearchIcon />}
                        />
                        <ButtonGroup>
                            <Button onClick={this.openFormModal}>
                                <AddIcon className="rainbow-m-right_x-small" />
                                <FormattedMessage id="add" defaultMessage="Add" />
                            </Button>
                            <Button
                                className="scheduler-patients_header-delete-button"
                                disabled={isDeleteButtonDisabled}
                                onClick={this.openConfirmationModal}>
                                <TrashIcon className="rainbow-m-right_x-small" />
                                <FormattedMessage id="delete" defaultMessage="Delete" />
                            </Button>
                        </ButtonGroup>
                        <Button
                            className="rainbow-m-left_small"
                            onClick={this.generateReport}>
                            <PDFIcon className="scheduler-patients_report-icon" />
                            PDF
                        </Button>
                    </div>
                </header>
                <TableWithBrowserPagination
                    id="patients-table"
                    className="scheduler-patients_table"
                    pageSize={40}
                    keyField="id"
                    sortDirection={sortDirection}
                    sortedBy={sortedBy}
                    onSort={this.handleOnSort}
                    data={patients}
                    isLoading={isLoading}
                    onRowSelection={this.handleSelection}
                    showCheckboxColumn>

                    <Column
                        header={<FormattedMessage id="patients.first.name" defaultMessage="First Name" />}
                        field="data.firstName"
                        sortable
                        component={NameColumn}
                    />
                    <Column
                        header={<FormattedMessage id="patients.last.name" defaultMessage="Last Name" />}
                        field="data.lastName"
                        sortable
                    />
                    <Column
                        header={<FormattedMessage id="patients.address" defaultMessage="Address" />}
                        field="data.address" />
                    <Column
                        header={<FormattedMessage id="patients.phone.number" defaultMessage="Phone Number" />}
                        field="data"
                        component={PhoneCell} />
                    <Column
                        header={<FormattedMessage id="patients.member.number" defaultMessage="Member #" />}
                        field="data.memberId" />
                    <Column
                        header={<FormattedMessage id="driver" defaultMessage="Driver" />}
                        field="data.driver"
                        component={DriverCell} />
                    <Column
                        header={<FormattedMessage id="patients.type" defaultMessage="Type" />}
                        field="data.regularPatient"
                        component={RegularCell} />
                    <Column type="action">
                        <MenuItem
                            label="View"
                            icon={<GoToIcon className="scheduler-daily-schedule_table-action-icon" />}
                            onClick={navigateToPatient} />
                        <MenuItem
                            label={<FormattedMessage id="edit" defaultMessage="Edit" />}
                            icon={<PencilIcon className="scheduler-patients_table-action-icon" />}
                            onClick={this.openEditFormModal}
                        />
                        <MenuItem
                            label={<FormattedMessage id="delete" defaultMessage="Delete" />}
                            icon={<TrashIcon className="scheduler-patients_table-action-icon" />}
                            onClick={this.openConfirmationModalFromRow}
                        />
                    </Column>
                </TableWithBrowserPagination>
            </section>
        );
    }
}

PatientsTable.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    addDoc: PropTypes.func.isRequired,
    updateDoc: PropTypes.func.isRequired,
    updateDocs: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    generateReport: PropTypes.func.isRequired,
};

function stateToProps() {
    return {};
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        showConfirmationModal,
        showFormModal,
        generateReport,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(injectIntl(PatientsTable));
