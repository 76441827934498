import React from 'react';
import PropTypes from 'prop-types';

export default function Sts(props) {
    const { className } = props;
    return (
        <svg className={className} fill="#A0A0BC" width="492px" height="492px" viewBox="0 0 491.975 491.975">
            <g id="XMLID_28_">
                <path id="XMLID_31_"
                    d="M225.315,96.963c26.748,0,48.479-21.706,48.479-48.481C273.794,21.699,252.063,0,225.315,0 c-26.779,0-48.492,21.699-48.492,48.482C176.823,75.257,198.536,96.963,225.315,96.963z" />
                <path id="XMLID_30_"
                    d="M300.233,371.688c-12.883,44.732-54.121,77.583-102.946,77.583c-59.126,0-107.209-48.085-107.209-107.193 c0-43.754,26.396-81.413,64.066-98.054V198.58c-61.69,18.581-106.764,75.847-106.764,143.498 c0,82.649,67.247,149.897,149.906,149.897c60.238,0,112.159-35.801,135.966-87.169l-16.926-33.255 C311.575,371.59,306.071,371.64,300.233,371.688z" />
                <path id="XMLID_29_"
                    d="M441.48,429.237l-64.939-127.672c-4.847-9.553-14.645-15.566-25.363-15.566h-83.173v-18.966h71.582 c7.148,0,13.156-3.736,17.037-9.118c2.522-3.506,4.316-7.579,4.316-12.236c0-11.789-9.549-21.351-21.353-21.351h-43.125h-28.457 V160c0-17.692-13.118-42.704-42.689-42.704c-23.584,0-42.703,19.122-42.703,42.704v139.372c0,24.058,19.503,43.561,43.562,43.561 h78.265h29.284l57.024,112.117c5.011,9.875,15.011,15.573,25.389,15.573c4.35,0,8.761-0.994,12.883-3.104 C443.054,460.401,448.628,443.251,441.48,429.237z" />
            </g>
        </svg>
    );
}

Sts.propTypes = {
    className: PropTypes.string,
};

Sts.defaultProps = {
    className: undefined,
};
