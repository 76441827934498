import React from 'react';
import PropTypes from 'prop-types';

export default function More(props) {
    const { className } = props;
    return (
        <svg className={className} fill="#01B6F5" width="4px" height="14px" viewBox="0 0 4 14">
            <g id="icon-more/blue" transform="translate(-5.000000, 0.000000)">
                <path d="M7.21,5.47580645 C8.05160417,5.47580645 8.7325,6.15745968 8.7325,7 C8.7325,7.84254032 8.05160417,8.52419355 7.21,8.52419355 C6.36839583,8.52419355 5.6875,7.84254032 5.6875,7 C5.6875,6.15745968 6.36839583,5.47580645 7.21,5.47580645 Z M5.6875,2.39919355 C5.6875,3.24173387 6.36839583,3.9233871 7.21,3.9233871 C8.05160417,3.9233871 8.7325,3.24173387 8.7325,2.39919355 C8.7325,1.55665323 8.05160417,0.875 7.21,0.875 C6.36839583,0.875 5.6875,1.55665323 5.6875,2.39919355 Z M5.6875,11.6008065 C5.6875,12.4433468 6.36839583,13.125 7.21,13.125 C8.05160417,13.125 8.7325,12.4433468 8.7325,11.6008065 C8.7325,10.7582661 8.05160417,10.0766129 7.21,10.0766129 C6.36839583,10.0766129 5.6875,10.7582661 5.6875,11.6008065 Z" />
            </g>
        </svg>
    );
}

More.propTypes = {
    className: PropTypes.string,
};

More.defaultProps = {
    className: undefined,
};
