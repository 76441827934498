import JsPDF from 'jspdf';
import 'jspdf-autotable';
import { get12HourTime } from '../../../helpers';
import { showErrorMessage } from '../app';

function generatePDF(body, patientName) {
    const doc = new JsPDF({
        orientation: 'landscape',
        format: 'legal',
    });
    const totalPagesExp = '{total_pages_count_string}';
    doc.text(`${patientName}`, 14, 22);
    doc.setFontSize(18);
    doc.setTextColor(100);

    doc.autoTable({
        head: [['Date', 'Time', 'Status', 'Drop Off Location', 'Comments']],
        body,
        rowPageBreak: 'avoid',
        startY: 30,
        didDrawPage(data) {
            let str = `Page ${doc.internal.getNumberOfPages()}`;
            if (typeof doc.putTotalPages === 'function') {
                str = `${str} of ${totalPagesExp}`;
            }
            doc.setFontSize(10);
            const { pageSize } = doc.internal;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
    });

    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
    }

    window.open(doc.output('bloburl'), '_blank');
}

function getBody(data, intl) {
    return data.map((item) => {
        if (item.data) {
            const {
                date,
                time,
                status,
                address: dropOffLocation,
                comments,
            } = item.data;

            return [
                intl.formatDate(date, {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                }),
                get12HourTime(time) || undefined,
                status,
                dropOffLocation,
                comments,
            ];
        }
        return [];
    });
}

export default function generatePatientAppointmentsReport(data, intl, patientName) {
    return (dispatch) => {
        if (data && data.length) {
            try {
                const body = getBody(data, intl);
                return generatePDF(body, patientName);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                return dispatch(showErrorMessage('There was a problem generating the pdf content. Please contact with support.'));
            }
        }
        return dispatch(showErrorMessage('There are not appointments to create the report.'));
    };
}
