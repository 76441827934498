import React from 'react';
import PropTypes from 'prop-types';
import {
    FormattedMessage,
    FormattedDate,
} from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FSDoc } from 'react-rainbow-firebase';
import { Spinner, HelpText, RainbowThemeContainer } from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { getCurrentUser } from '../../../../../redux/services/firebase';
import { showConfirmationModal } from '../../../../../redux/actions/confirmation-modal';
import { showFormModal } from '../../../../../redux/actions/form-modal';
import { navigateTo } from '../../../../../history';
import { getFullPatientName, parsePhoneNumbers, shouldShowLegacyPhone } from '../../../../../helpers';
import RecordCard from '../../../../../components/record-card';
import RecordColumnSection from '../../../../../components/record-column-section';
import Output from '../../../../../components/output';
import DateTime from '../../../../../components/date-time';
import RegularCell from '../../regular-cell';
import PatientForm from '../../patient-form';
import Timeline from './timeline';
import './styles.css';
import PhoneNumbers from '../../../../../components/phone-numbers';

function DateTimeOutput({ value }) {
    if (value) {
        return <DateTime value={value.toDate()} />;
    }
    return null;
}

DateTimeOutput.propTypes = {
    value: PropTypes.object,
};

DateTimeOutput.defaultProps = {
    value: undefined,
};

const theme = {
    rainbow: {
        palette: {
            warning: '#a77940',
        },
    },
};

function Details(props) {
    const {
        doc,
        isLoading,
        showConfirmationModal,
        showFormModal,
        updateDoc,
    } = props;

    if (doc && doc.data && doc.data.enable) {
        const {
            id,
            data: {
                firstName,
                lastName,
                phone,
                phoneNumbers,
                dob,
                sex,
                memberId,
                medicalInsurance,
                driver,
                address,
                regularPatient,
                createdAt,
                createdBy,
            },
        } = doc;
        const displayName = createdBy && createdBy.displayName;
        const email = createdBy && createdBy.email;
        const historyRef = `patients/${id}/history`;

        const openConfirmationModal = () => {
            showConfirmationModal({
                collectionName: 'patients',
                itemsAmount: 1,
                onAccept: () => {
                    updateDoc({
                        userId: getCurrentUser().uid,
                        enable: false,
                    });
                    navigateTo('/app/patients');
                },
            });
        };

        const openEditFormModal = () => {
            showFormModal({
                title: 'Edit Patient',
                formComponent: PatientForm,
                formId: 'patient-form',
                onSubmit: updateDoc,
                initialValues: {
                    ...doc.data,
                    phoneNumbers: parsePhoneNumbers(doc.data.phoneNumbers || undefined),
                },
            });
        };

        const getDob = () => {
            if (dob) {
                return <FormattedDate value={dob} />;
            }
            return <span className="scheduler-patient-details_output-undefined-value">Date of birth not defined</span>;
        };

        const showLegacyPhone = shouldShowLegacyPhone(phone, phoneNumbers);

        return (
            <article className="scheduler-patient-details_content">
                <RecordCard
                    className="rainbow-m-bottom_medium"
                    title="Patient Details"
                    onEdit={openEditFormModal}
                    onRemove={openConfirmationModal}
                >
                    <div className="rainbow-flex">
                        <RecordColumnSection>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Patient Id.">
                                {id}
                            </Output>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Name">
                                {getFullPatientName(firstName, lastName)}
                            </Output>
                            <RenderIf isTrue={showLegacyPhone}>
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label={(
                                        <RainbowThemeContainer theme={theme}>
                                            <div className="scheduler-patients_patient-detail-invalid-phone-label">
                                                <HelpText
                                                    className="rainbow-m-right_x-small"
                                                    variant="warning"
                                                    iconSize="small"
                                                    title={<FormattedMessage id="patients.phone.number.invalid.help.message.title" defaultMessage="The phone number need to be updated." />}
                                                    text={<FormattedMessage id="patients.phone.number.invalid.help.message" defaultMessage="We have detected that this phone number is invalid, you must edit the patient information to correctly enter the phone number(s)." />}
                                                />
                                                <FormattedMessage id="patients.phone.number.invalid" defaultMessage="Invalid Phone Number" />
                                            </div>
                                        </RainbowThemeContainer>
                                    )}>
                                    {phone}
                                </Output>
                            </RenderIf>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Phone Numbers">
                                <PhoneNumbers value={phoneNumbers} />
                            </Output>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Date of Birth">
                                {getDob()}
                            </Output>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Gender">
                                {sex}
                            </Output>
                            <Output
                                className="scheduler-patient-details_card-output-large"
                                label="Address">
                                {address}
                            </Output>
                        </RecordColumnSection>
                        <RecordColumnSection>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Member #">
                                {memberId}
                            </Output>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Medical Insurance">
                                {medicalInsurance}
                            </Output>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Driver">
                                {driver}
                            </Output>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Type">
                                <RegularCell value={regularPatient} />
                            </Output>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Created At">
                                <DateTimeOutput value={createdAt} />
                            </Output>
                            <Output
                                className="rainbow-m-bottom_medium"
                                label="Created By">
                                <RenderIf isTrue={!!displayName}>
                                    {`${displayName} • `}
                                </RenderIf>
                                {email}
                            </Output>
                        </RecordColumnSection>
                    </div>
                </RecordCard>
                <Timeline historyRef={historyRef} />
            </article>
        );
    }
    if (isLoading) {
        return (
            <div className="scheduler-patient-details_content">
                <Spinner size="large" />
            </div>
        );
    }
    return (
        <div className="scheduler-patient-details_content scheduler-appointment_not-found-container">
            <div className="scheduler-appointment_not-found-text">
                <h1 className="scheduler-appointment_not-found-title">It looks like you’re lost…</h1>
                <Link className="scheduler-appointment_not-found-link" to="/app/patients">Go to Patients Page</Link>
            </div>
        </div>
    );
}

Details.propTypes = {
    doc: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    updateDoc: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
};

Details.defaultProps = {
    doc: null,
};

function DetailsWrapper(props) {
    const {
        match: { params: { id } },
        showConfirmationModal,
        showFormModal,
    } = props;

    return (
        <FSDoc
            docRef={`patients/${id}`}
            component={Details}
            showConfirmationModal={showConfirmationModal}
            showFormModal={showFormModal} />
    );
}

DetailsWrapper.propTypes = {
    match: PropTypes.object.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
};

function stateToProps() {
    return {};
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        showConfirmationModal,
        showFormModal,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(DetailsWrapper);
