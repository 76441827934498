import { validateMultiPhoneNumbers } from '../../../../helpers';


function validate(values) {
    const {
        firstName,
        lastName,
        dob,
        address,
        phoneNumbers,
    } = values;
    const errors = {};
    if (!firstName) {
        errors.firstName = 'First Name is a required field';
    }
    if (!lastName) {
        errors.lastName = 'Last Name is a required field';
    }
    if (!dob) {
        errors.dob = 'DOB is a required field';
    }
    if (!address) {
        errors.address = 'Address is a required field';
    }
    const phoneNumbersError = validateMultiPhoneNumbers(phoneNumbers, { required: true });
    if (phoneNumbersError) {
        errors.phoneNumbers = phoneNumbersError;
    }

    return errors;
}

export default validate;
