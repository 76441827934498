import { FirebaseApp } from 'react-rainbow-firebase';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

FirebaseApp.initializeApp(config);

FirebaseApp.instance.firestore().settings({
    ignoreUndefinedProperties: true,
});

export default FirebaseApp.instance;
