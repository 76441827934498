import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

export default function Output(props) {
    const {
        label,
        children,
        className,
    } = props;

    return (
        <div className={className}>
            <h1 className="scheduler-output_label">
                {label}
            </h1>
            <div className="scheduler-output_description">
                {children || '-'}
            </div>
        </div>
    );
}

Output.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
};

Output.defaultProps = {
    label: null,
    children: null,
    className: undefined,
};
