/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    AvatarMenu,
    Avatar,
    MenuDivider,
    MenuItem,
} from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { logoutUser } from '../../redux/actions/authentication';
import { navigate } from '../../redux/actions/app';
import isAdmin from '../../helpers/is-admin';
import useUserClaims from '../../hooks/useUserClaims';
import LanguageSelector from '../language-selector';
import { CLIENT_LOCATION_NAME } from '../../constants';
import {
    PowerIcon,
    PersonIcon,
    LockBorderIcon,
} from '../icons';
import './styles.css';

function TopBar(props) {
    const {
        className,
        user,
        logoutUser,
        pathname,
        location,
        selectedDay,
        canSeeOnlyProspects,
    } = props;
    const {
        photoURL,
        displayName,
    } = user;
    const [claims] = useUserClaims();

    const getContainerClassNames = () => classnames('scheduler-top-bar_container', className);

    const getLinkClassName = path => classnames('scheduler-top-bar_link-text', {
        'scheduler-top-bar_link-text--active': pathname === path,
    });

    const navigateTo = (url) => {
        const currentPathname = location.pathname;
        navigate(currentPathname, url);
    };

    return (
        <section data-cy="top-bar" className={getContainerClassNames()}>
            <a
                className="scheduler-top-bar_logo-container"
                href="javascript:void(0);"
                data-cy="logo-link"
                onClick={() => navigateTo(`/app/schedule/daily/${selectedDay}`)}>
                <img src="/assets/logo.svg" alt="scheduler logo" className="scheduler-top-bar_logo" />
                <div className="scheduler-top-bar_logo-details">
                    <img src="/assets/logo-text.svg" alt="scheduler" />
                    <h1 className="scheduler-top-bar_location-name">
                        {CLIENT_LOCATION_NAME}
                    </h1>
                </div>
            </a>
            <div className="scheduler-top-bar_content">
                <RenderIf isTrue={!canSeeOnlyProspects}>
                    <a
                        className={getLinkClassName('schedule')}
                        href="javascript:void(0);"
                        data-cy="schedule-link"
                        onClick={() => navigateTo(`/app/schedule/daily/${selectedDay}`)}>
                        <FormattedMessage
                            id="appointments.schedule"
                            defaultMessage="Schedule" />
                    </a>
                    <a
                        className={getLinkClassName('patients')}
                        href="javascript:void(0);"
                        data-cy="patients-link"
                        onClick={() => navigateTo('/app/patients')}>
                        <FormattedMessage
                            id="patients"
                            defaultMessage="Patients" />
                    </a>
                </RenderIf>
                <a
                    className={getLinkClassName('prospects')}
                    href="javascript:void(0);"
                    onClick={() => navigateTo('/app/prospects')}>
                    <FormattedMessage
                        id="prospects"
                        defaultMessage="Prospects" />
                </a>
                <LanguageSelector />
                <AvatarMenu
                    src={photoURL}
                    icon={<PersonIcon className="scheduler-top-bar_avatar-icon" />}
                    assistiveText={displayName}
                    menuAlignment="right"
                    menuSize="small"
                    avatarSize="large"
                    className="scheduler-top-bar_avatar-menu"
                    title={displayName}
                >
                    <li className="scheduler-top-bar_avatar-menu_user-details">
                        <Avatar
                            src={photoURL}
                            icon={<PersonIcon className="scheduler-top-bar_avatar-icon" />}
                            assistiveText={displayName}
                            title={displayName}
                            size="medium" />
                        <div className="scheduler-top-bar_avatar-menu_user-info">
                            <p data-cy="user-name" className="scheduler-top-bar_avatar-menu_user-name">{displayName}</p>
                            <p data-cy="user-email" className="scheduler-top-bar_avatar-menu_user-email">{user.getEmail()}</p>
                        </div>
                    </li>
                    <MenuDivider variant="space" />
                    <MenuItem
                        onClick={() => navigateTo('/app/profile')}
                        label={<FormattedMessage id="authenticated.profile.info" defaultMessage="Profile Info" />}
                        icon={<PersonIcon className="scheduler-top-bar_icon" />}
                        iconPosition="left" />
                    <RenderIf isTrue={isAdmin(claims)}>
                        <MenuItem
                            onClick={() => navigateTo('/app/permissions')}
                            label={<FormattedMessage id="authenticated.users.and.permissions" defaultMessage="Members and Permissions" />}
                            icon={<LockBorderIcon className="scheduler-top-bar_icon" />}
                            iconPosition="left" />
                    </RenderIf>
                    <MenuItem
                        label={<FormattedMessage id="logout" defaultMessage="Logout" />}
                        onClick={logoutUser}
                        icon={<PowerIcon className="scheduler-top-bar_icon" />}
                        iconPosition="left" />
                </AvatarMenu>
            </div>
        </section>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
    logoutUser: PropTypes.func,
    pathname: PropTypes.string,
    location: PropTypes.object,
    selectedDay: PropTypes.string.isRequired,
    canSeeOnlyProspects: PropTypes.bool,
};

TopBar.defaultProps = {
    className: undefined,
    user: {},
    logoutUser: () => {},
    pathname: undefined,
    location: undefined,
    canSeeOnlyProspects: false,
};

function stateToProps(state) {
    const { authentication, dailyAppointments } = state;
    const { selectedDay } = dailyAppointments;
    return {
        user: authentication.get('user'),
        selectedDay,
    };
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        logoutUser,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(TopBar);
