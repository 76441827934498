import {
    get12HourTime,
    getFullPatientName,
    filterCollection,
} from '../../../../../helpers';

function mapValuesToString(data) {
    const {
        address, patient, status, time,
    } = data;
    let string = '';

    if (patient) {
        const { firstName, lastName } = patient.data;
        string = `${getFullPatientName(firstName, lastName)}`;
    }

    return `${string},${address},${status},${get12HourTime(time)}`;
}

export default function filter(query, collection) {
    return filterCollection(query, collection, mapValuesToString);
}
