import { listAllUsers } from '../../services/profile';
import { getCurrentUser } from '../../services/firebase';

export const PROFILE_LOAD_CURRENT_USER = 'PROFILE_LOAD_CURRENT_USER';
export const LOAD_ALL_MEMEBERS = 'LOAD_ALL_MEMEBERS';

export default function listAllMemebers() {
    return async (dispatch) => {
        dispatch({
            type: PROFILE_LOAD_CURRENT_USER,
            currentUser: getCurrentUser(),
        });
        const members = await listAllUsers();
        dispatch({
            type: LOAD_ALL_MEMEBERS,
            members,
        });
    };
}
