import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-rainbow-components';
import { StsIcon } from '../icons';
import './styles.css';

export default function DriverCell({ value }) {
    if (value === 'sts') {
        return (
            <Badge className="shedule-driver-cell_badge rainbow-m-right_xx-small" variant="lightest">
                <StsIcon className="shedule-driver-cell_icon-sts rainbow-m-right_xx-small" />
                {value}
            </Badge>
        );
    }
    return value;
}

DriverCell.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

DriverCell.defaultProps = {
    value: undefined,
};
