import { validateMultiPhoneNumbers } from '../../../../helpers';

function validate(values) {
    const {
        firstName,
        lastName,
        phoneNumbers,
    } = values;
    const errors = {};
    if (!firstName) {
        errors.firstName = 'First Name is a required field';
    }
    if (!lastName) {
        errors.lastName = 'Last Name is a required field';
    }
    const phoneNumbersError = validateMultiPhoneNumbers(phoneNumbers, { required: true });
    if (phoneNumbersError) {
        errors.phoneNumbers = phoneNumbersError;
    }
    return errors;
}

export default validate;
