import React from 'react';
import { FormattedMessage } from 'react-intl';
import showSuccessMessage from '../app/show-success-message';
import updateUserData from '../authentication/update-user-data';
import handleUpdateProfileError from './handle-update-profile-error';
import updateUserProfile from '../../services/firebase/update-profile';
import {
    PROFILE_START_LOADING_FORM,
    PROFILE_END_LOADING_FORM,
    HIDE_PROFILE_MODAL,
} from '.';

export default function updateProfileName({ displayName }) {
    return async (dispatch) => {
        dispatch({ type: PROFILE_START_LOADING_FORM });
        try {
            await updateUserProfile({ displayName });
            dispatch(updateUserData({ displayName }));
            dispatch({ type: PROFILE_END_LOADING_FORM });
            dispatch({ type: HIDE_PROFILE_MODAL });
            dispatch(showSuccessMessage(
                <FormattedMessage
                    id="profile.success.message"
                    defaultMesssage="Your profile information has been changed successfully." />,
            ));
        } catch (error) {
            dispatch(handleUpdateProfileError(error));
            dispatch({ type: PROFILE_END_LOADING_FORM });
        }
    };
}
