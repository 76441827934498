import React from 'react';
import PropTypes from 'prop-types';

export default function Pencil(props) {
    const { className } = props;
    return (
        <svg className={className} fill="#01B6F5" width="18px" height="18px" viewBox="0 0 18 18">
            <g id="profile-with-2-members" transform="translate(-1311.000000, -318.000000)">
                <g id="Group-18" transform="translate(736.000000, 212.000000)">
                    <g id="Group-17" transform="translate(40.000000, 82.000000)">
                        <g id="Group-11" transform="translate(524.000000, 13.000000)">
                            <g id="edit/blue" transform="translate(8.000000, 8.000000)">
                                <path d="M20.4057193,5.18592982 L18.7777895,3.55785965 C18.0339649,2.81403509 16.8237895,2.81407018 16.0799649,3.55785965 C15.3796491,4.25824561 4.68294737,14.9557544 3.96810526,15.6706316 C3.89196491,15.7467719 3.84094737,15.8479649 3.82277193,15.9469474 L3.00880702,20.3427368 C2.97726316,20.5131579 3.03157895,20.6882105 3.15414035,20.8107719 C3.27684211,20.9334737 3.45192982,20.9876842 3.62214035,20.9561404 L8.01754386,20.1420702 C8.11912281,20.1231228 8.21898246,20.071614 8.29385965,19.9967018 L20.4057193,7.88392982 C21.1512632,7.13838596 21.1513684,5.93157895 20.4057193,5.18592982 Z M4.18319298,19.781614 L4.67557895,17.1225263 L6.84210526,19.289193 L4.18319298,19.781614 Z M7.9217193,18.8801754 L5.0845614,16.0428421 L15.442807,5.68378947 L18.2799649,8.52115789 L7.9217193,18.8801754 Z M19.6614035,7.13961404 L19.0242456,7.77684211 L16.1870877,4.93947368 L16.8242456,4.30224561 C17.157614,3.96884211 17.7000351,3.96880702 18.0334737,4.30224561 L19.6614035,5.93031579 C19.995614,6.26452632 19.995614,6.80536842 19.6614035,7.13961404 Z" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

Pencil.propTypes = {
    className: PropTypes.string,
};

Pencil.defaultProps = {
    className: undefined,
};
