import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';

export default function PhoneNumbers({ value }) {
    if (!Array.isArray(value)) {
        return '-';
    }

    return value.map(item => (
        <p key={item.nationalFormat}>
            {item.nationalFormat}
            <RenderIf isTrue={!!item.label}>
                {` • ${item.label}`}
            </RenderIf>
        </p>
    ));
}

PhoneNumbers.propTypes = {
    value: PropTypes.arrayOf(PropTypes.object),
};
