import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-rainbow-components';
import { hideConfirmationModal } from '../../redux/actions/confirmation-modal';
import Message from './message';
import Footer from './footer';
import './styles.css';

function ConfirmationModal(props) {
    const {
        isOpen,
        onAccept,
        collectionName,
        itemsAmount,
        hideConfirmationModal,
        title,
        message,
        closeWhenAccept,
        isLoading,
    } = props;

    const handleAccept = (event) => {
        onAccept(event);
        if (closeWhenAccept) {
            hideConfirmationModal();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={hideConfirmationModal}
            footer={(
                <Footer
                    onAccept={handleAccept}
                    onCancel={hideConfirmationModal}
                    isLoading={isLoading}
                />
            )}
        >
            <Message
                title={title}
                message={message}
                collectionName={collectionName}
                itemsAmount={itemsAmount}
            />
        </Modal>
    );
}

ConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    collectionName: PropTypes.string.isRequired,
    itemsAmount: PropTypes.number.isRequired,
    hideConfirmationModal: PropTypes.func.isRequired,
    title: PropTypes.node,
    message: PropTypes.node,
    closeWhenAccept: PropTypes.bool,
    isLoading: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
    title: null,
    message: null,
    closeWhenAccept: true,
    isLoading: false,
};

function stateToProps(state) {
    return state.confirmationModal;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        hideConfirmationModal,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(ConfirmationModal);
