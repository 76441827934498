import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { FSDoc } from 'react-rainbow-firebase';
import {
    ButtonGroup,
    ButtonIcon,
    Card,
    Spinner,
} from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { navigateTo } from '../../../../history';
import {
    PencilIcon,
    TrashIcon,
} from '../../../../components/icons';
import { get12HourTime, getFullPatientName } from '../../../../helpers';
import { showConfirmationModal } from '../../../../redux/actions/confirmation-modal';
import { showFormModal } from '../../../../redux/actions/form-modal';
import { getCurrentUser } from '../../../../redux/services/firebase';
import Output from '../../../../components/output';
import Status from '../../../../components/status';
import DateTime from '../../../../components/date-time';
import BackButton from '../../../../components/back-button';
import AppointmentForm from '../appointment-form';
import Timeline from './timeline';
import './styles.css';

function DateTimeOutput({ value }) {
    if (value) {
        return <DateTime value={value.toDate()} />;
    }
    return null;
}

DateTimeOutput.propTypes = {
    value: PropTypes.object,
};

DateTimeOutput.defaultProps = {
    value: undefined,
};

function getPatientName(patient) {
    if (patient && patient.data) {
        const {
            firstName,
            lastName,
        } = patient.data;
        return getFullPatientName(firstName, lastName);
    }
    return null;
}

function getDriver(patient) {
    if (patient && patient.data && patient.data.driver) {
        const { driver } = patient.data;
        return driver;
    }
    return null;
}

function Appointment(props) {
    const {
        doc,
        isLoading,
        showConfirmationModal,
        showFormModal,
        updateDoc,
        historyRef,
    } = props;

    if (doc && doc.data.enable) {
        const {
            id,
            data: {
                date,
                createdAt,
                createdBy,
                address,
                comments,
                time,
                status,
                patient,
            },
        } = doc;
        const patientName = getPatientName(patient);
        const displayName = createdBy && createdBy.displayName;
        const email = createdBy && createdBy.email;
        const driver = getDriver(patient);
        const patientUrl = `/app/patient/${patient.id || ''}`;

        const openConfirmationModal = () => {
            showConfirmationModal({
                collectionName: 'appointments',
                itemsAmount: 1,
                onAccept: () => {
                    updateDoc({
                        userId: getCurrentUser().uid,
                        enable: false,
                    });
                    navigateTo('/app/schedule/daily');
                },
            });
        };

        const openEditFormModal = () => {
            showFormModal({
                title: 'Edit Appointment',
                formComponent: AppointmentForm,
                formId: 'appointment-form',
                onSubmit: updateDoc,
                initialValues: doc.data,
            });
        };

        return (
            <section className="scheduler-appointment_wrap-container">
                <BackButton />
                <article className="scheduler-appointment_content-container">
                    <Card
                        className="rainbow-m-bottom_medium"
                        title="Appointment Details"
                        actions={(
                            <ButtonGroup>
                                <ButtonIcon
                                    variant="border"
                                    icon={<PencilIcon />}
                                    onClick={openEditFormModal} />
                                <ButtonIcon
                                    variant="border"
                                    icon={<TrashIcon />}
                                    onClick={openConfirmationModal} />
                            </ButtonGroup>
                        )}>

                        <div className="scheduler-appointment_card-content">
                            <div className="scheduler-appointment_column">
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label="Appointment Id.">
                                    {id}
                                </Output>
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label="Patient">
                                    <Link className="scheduler-appointment_link" to={patientUrl}>
                                        {patientName}
                                    </Link>
                                </Output>
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label="Appointment Date">
                                    <FormattedDate
                                        value={date}
                                        timeZone="America/New_York" />
                                </Output>
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label="Driver">
                                    {driver}
                                </Output>
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label="Drop Off Location">
                                    {address}
                                </Output>
                            </div>

                            <div className="scheduler-appointment_column">
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label="Created At">
                                    <DateTimeOutput value={createdAt} />
                                </Output>
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label="Created By">
                                    <RenderIf isTrue={displayName}>
                                        {`${displayName} • `}
                                    </RenderIf>
                                    {email}
                                </Output>
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label="Appointment Time">
                                    {get12HourTime(time)}
                                </Output>
                                <Output
                                    className="rainbow-m-bottom_medium"
                                    label="Status">
                                    <Status value={status} />
                                </Output>

                            </div>
                        </div>
                        <Output
                            className="scheduler-appointment_card-comment"
                            label="Comments">
                            {comments}
                        </Output>
                    </Card>
                    <Timeline historyRef={historyRef} />
                </article>
            </section>
        );
    }
    if (isLoading) {
        return (
            <div className="scheduler-appointment_wrap-container">
                <Spinner size="large" />
            </div>
        );
    }
    return (
        <div className="scheduler-appointment_wrap-container scheduler-appointment_not-found-container">
            <BackButton className="scheduler-appointment_back-button" />
            <div className="scheduler-appointment_not-found-text">
                <h1 className="scheduler-appointment_not-found-title">It looks like you’re lost…</h1>
                <Link className="scheduler-appointment_not-found-link" to="/home">Go to Daily Scheduler</Link>
            </div>
        </div>
    );
}

Appointment.propTypes = {
    doc: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    updateDoc: PropTypes.func.isRequired,
    historyRef: PropTypes.string.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
};

Appointment.defaultProps = {
    doc: null,
};

function AppointmentWrapper(props) {
    const {
        match: { params: { id } },
        showConfirmationModal,
        showFormModal,
    } = props;

    return (
        <FSDoc
            docRef={`appointments/${id}`}
            component={Appointment}
            historyRef={`appointments/${id}/history`}
            showConfirmationModal={showConfirmationModal}
            showFormModal={showFormModal} />
    );
}

AppointmentWrapper.propTypes = {
    match: PropTypes.object.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    showFormModal: PropTypes.func.isRequired,
};

function stateToProps() {
    return {};
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        showConfirmationModal,
        showFormModal,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(AppointmentWrapper);
