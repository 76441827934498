import { LOAD_SELECTED_WEEK } from '../../actions/appointments/show-week-appointments';
import { getWeekUrl } from '../../../helpers';

const today = new Date();
const initialState = {
    selectedWeek: getWeekUrl(today),
};

function loadSelecedWeek(state, action) {
    const { selectedWeek } = action;
    return {
        selectedWeek,
    };
}

export default function weeklyAppointments(state = initialState, action) {
    switch (action.type) {
        case LOAD_SELECTED_WEEK:
            return loadSelecedWeek(state, action);
        default:
            return state;
    }
}
