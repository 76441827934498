import { useEffect, useState } from 'react';
import { getCurrentUser } from '../redux/services/firebase';

const useUserClaims = () => {
    const [claims, setClaims] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const currentUser = getCurrentUser();
            if (currentUser) {
                const idTokenResult = await currentUser.getIdTokenResult(true);
                setClaims(idTokenResult.claims);
                setIsLoading(false);
            }
        })();
    }, []);

    return [claims, isLoading];
};

export default useUserClaims;
