const filterByDate = (dateFilter, data) => {
    if (Array.isArray(dateFilter)) {
        return data.filter((item) => {
            const { createdAt } = item.data;
            if (createdAt && typeof createdAt.toDate === 'function') {
                const date = createdAt.toDate();
                return date >= dateFilter[0] && date <= dateFilter[1];
            }
            return false;
        });
    }
    return data;
};

export default filterByDate;
