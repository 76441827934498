import {
    SHOW_PROFILE_MODAL,
    HIDE_PROFILE_MODAL,
    PROFILE_START_LOADING_FORM,
    PROFILE_END_LOADING_FORM,
    PROFILE_LOAD_CURRENT_USER,
    LOAD_ALL_MEMEBERS,
    PROFILE_ADD_MEMBER,
    PROFILE_REMOVE_MEMBER,
    PROFILE_UPDATE_MEMBER,
} from '../../actions/profile';
import { USER_LOGOUT_DONE } from '../../actions/authentication';

const initialState = {
    modalProps: {
        isOpen: false,
        children: () => {},
    },
    formProps: {
        isLoading: false,
    },
    members: [],
};

function startLoading(state) {
    return {
        ...state,
        formProps: {
            ...state.formProps,
            isLoading: true,
        },
    };
}

function endLoading(state) {
    return {
        ...state,
        formProps: {
            ...state.formProps,
            isLoading: false,
        },
    };
}

function showProfileModal(state, action) {
    const {
        modalProps,
        formProps,
    } = action;
    return {
        ...state,
        modalProps: {
            ...modalProps,
            isOpen: true,
        },
        formProps: {
            ...state.formProps,
            ...formProps,
        },
    };
}

function hideProfileModal(state) {
    return {
        ...state,
        modalProps: {
            ...state.modalProps,
            isOpen: false,
        },
    };
}

function loadCurrentUser(state, { currentUser }) {
    return {
        ...state,
        members: [currentUser],
    };
}

function loadMembers(state, { members }) {
    const sortedMembers = [...members].sort((a, b) => {
        const isAdminA = a.customClaims && a.customClaims.admin;
        const isAdminB = b.customClaims && b.customClaims.admin;
        if (isAdminA && isAdminB) {
            return 0;
        }
        if (isAdminA) {
            return -1;
        }
        return 1;
    });
    return {
        ...state,
        members: sortedMembers,
    };
}

function addMember(state, { member }) {
    return {
        ...state,
        members: state.members.concat(member),
    };
}

function updateMember(state, { member }) {
    const memberIndex = state.members.findIndex(({ uid }) => uid === member.uid);
    const newMembers = [...state.members];
    newMembers[memberIndex] = member;
    return {
        ...state,
        members: newMembers,
    };
}

function removeMember(state, { uid }) {
    return {
        ...state,
        members: state.members.filter(user => user.uid !== uid),
    };
}

export default function profile(state = initialState, action) {
    switch (action.type) {
        case PROFILE_START_LOADING_FORM:
            return startLoading(state);

        case PROFILE_END_LOADING_FORM:
            return endLoading(state, action);

        case SHOW_PROFILE_MODAL:
            return showProfileModal(state, action);

        case HIDE_PROFILE_MODAL:
            return hideProfileModal(state);

        case PROFILE_LOAD_CURRENT_USER:
            return loadCurrentUser(state, action);

        case LOAD_ALL_MEMEBERS:
            return loadMembers(state, action);

        case PROFILE_ADD_MEMBER:
            return addMember(state, action);

        case PROFILE_UPDATE_MEMBER:
            return updateMember(state, action);

        case PROFILE_REMOVE_MEMBER:
            return removeMember(state, action);

        case USER_LOGOUT_DONE:
            return initialState;

        default:
            return state;
    }
}
