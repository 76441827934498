export default function validate(values) {
    const {
        displayName, email, password, roles,
    } = values;
    const errors = {};
    if (!displayName) {
        errors.displayName = 'Looks like you forget the user name.';
    }
    if (!email) {
        errors.email = 'Looks like you forget the user email.';
    }
    if (!password) {
        errors.password = 'Looks like you forget the password.';
    }
    if (!Array.isArray(roles) || roles.length === 0) {
        errors.roles = 'New user must have at least one role.';
    }
    return errors;
}
