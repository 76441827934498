import React from 'react';
import PropTypes from 'prop-types';
import {
    Tabset,
    Tab,
} from 'react-rainbow-components';
import {
    Route,
    Switch,
} from 'react-router-dom';
import BackButton from '../../../../components/back-button';
import { navigateTo } from '../../../../history';
import Appointments from './appointments';
import Details from './details';
import Notes from './notes';
import './styles.css';

function navigate(event, name) {
    return navigateTo(name);
}

export default function PatientDetails(props) {
    const {
        location: { pathname },
        match: { params: { id } },
    } = props;
    const detailsPathname = `/app/patient/${id}`;
    const appointmentsPathname = `/app/patient/${id}/appointments`;
    const notesPathname = `/app/patient/${id}/notes`;

    return (
        <article className="scheduler-patient-details_wrap-container">
            <BackButton className="scheduler-patient-details_back-button" onClick={() => navigateTo('/app/patients')} />
            <Tabset
                activeTabName={pathname}
                onSelect={navigate}
            >
                <Tab label="PATIENT DETAILS" name={detailsPathname} />
                <Tab label="APPOINTMENTS" name={appointmentsPathname} />
                <Tab label="NOTES" name={notesPathname} />
            </Tabset>
            <article className="scheduler-patient-details_content-container">
                <Switch>
                    <Route exact path="/app/patient/:id" component={Details} />
                    <Route path="/app/patient/:id/appointments" component={Appointments} />
                    <Route path="/app/patient/:id/notes" component={Notes} />
                </Switch>
            </article>
        </article>
    );
}

PatientDetails.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
};

PatientDetails.defaultProps = {
    location: {},
    match: {},
};
