import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-rainbow-components';
import { hideFormModal } from '../../redux/actions/form-modal';
import Footer from './footer';
import './styles.css';

function FormModal(props) {
    const {
        isOpen,
        title,
        onSubmit,
        formId,
        formComponent: Form,
        initialValues,
        hideFormModal,
    } = props;

    const handleSubmit = (data) => {
        onSubmit(data);
        hideFormModal();
    };

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            onRequestClose={hideFormModal}
            footer={<Footer onCancel={hideFormModal} formId={formId} />}
            className="scheduler-global-modal_container"
            size="medium"
        >
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
}

FormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formId: PropTypes.string.isRequired,
    formComponent: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]).isRequired,
    initialValues: PropTypes.object.isRequired,
    hideFormModal: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.formModal;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        hideFormModal,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(FormModal);
