import React from 'react';
import PropTypes from 'prop-types';
import {
    injectIntl,
    defineMessages,
} from 'react-intl';
import {
    AlertIcon,
    CheckmarkIcon,
    InprogressIcon,
    OwnCarIcon,
    NoShowIcon,
    StsIcon,
} from '../icons';
import './styles.css';

const messages = defineMessages({
    statusNotConfirmed: {
        id: 'status.not.confirmed',
        defaultValue: 'Not Confirmed',
    },
    statusConfirmed: {
        id: 'status.confirmed',
        defaultValue: 'Confirmed',
    },
    statusCanceled: {
        id: 'status.canceled',
        defaultValue: 'Canceled',
    },
    statusSts: {
        id: 'status.sts',
        defaultValue: 'Sts',
    },
    statusOwnCar: {
        id: 'status.own.car',
        defaultValue: 'Own Car',
    },
    statusNoShow: {
        id: 'status.no.show',
        defaultValue: 'No Show',
    },
    statusKeptApp: {
        id: 'status.no.kept.app',
        defaultValue: 'Kept App',
    },
});

const iconMap = {
    'not confirmed': <InprogressIcon className="shedule-status_icon-inprogress rainbow-m-right_xx-small" />,
    confirmed: <CheckmarkIcon className="rainbow-m-right_xx-small" />,
    canceled: <AlertIcon className="rainbow-m-right_x-small" />,
    'own car': <OwnCarIcon className="shedule-status_icon-own-car rainbow-m-right_x-small" />,
    'no show': <NoShowIcon className="shedule-status_icon-no-show rainbow-m-right_x-small" />,
    sts: <StsIcon className="shedule-status_icon-sts rainbow-m-right_xx-small" />,
};

function Status(props) {
    const {
        value,
        intl,
    } = props;

    const valueMap = {
        sts: intl.formatMessage(messages.statusSts),
        confirmed: intl.formatMessage(messages.statusConfirmed),
        canceled: intl.formatMessage(messages.statusCanceled),
        'not confirmed': intl.formatMessage(messages.statusNotConfirmed),
        'own car': intl.formatMessage(messages.statusOwnCar),
        'no show': intl.formatMessage(messages.statusNoShow),
        'kept app': intl.formatMessage(messages.statusKeptApp),
    };

    const icon = iconMap[value];
    const label = valueMap[value];

    return (
        <div title={label}>
            {icon}
            {label}
        </div>
    );
}

Status.propTypes = {
    value: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

Status.defaultProps = {
    value: undefined,
};

export default (injectIntl(Status));
